define("@trovedata/sunstone-ui-commons/adapters/data-set-data-field", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName) {
      var toReplace = this.pathForType(modelName);
      var url = this._buildURL(modelName, id);
      return url.replace(toReplace, 'datasetMap');
    }
  });
  _exports.default = _default;
});