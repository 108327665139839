define("@trovedata/sunstone-ui-commons/models/segment", ["exports", "@ember/object/computed", "@ember/array", "rsvp", "@ember/object", "jquery", "ember-cli-uuid", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _computed, _array, _rsvp, _object, _jquery, _emberCliUuid, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  var _default = _model.default.extend({
    code: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    name: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    description: (0, _model.attr)('string'),
    color: (0, _model.attr)('string'),
    profileId: (0, _model.attr)('number'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    startTime: (0, _model.attr)('basic-date'),
    completeTime: (0, _model.attr)('basic-date'),
    executionTime: (0, _model.attr)('number'),
    definition: (0, _attributes.fragment)('definition'),
    visible: true,
    transactionId: null,
    previewed: false,
    count: null,
    pillsChanged: false,
    clondedSegment: null,
    init: function init() {
      this._super.apply(this, arguments);
      (0, _object.set)(this, 'transactionId', (0, _emberCliUuid.uuid)());
    },
    dataFieldPills: (0, _computed.alias)('definition.dataFieldPills'),
    allPillsInvisible: (0, _object.computed)('definition.dataFieldPills.[]', function () {
      var test = (0, _object.get)(this, 'definition.dataFieldPills').every(function (pill) {
        return !(0, _object.get)(pill, 'visible');
      });
      return test;
    }),
    generateDefaults: function generateDefaults(defaults) {
      var _this = this;
      var promises = defaults.map(function (dataField) {
        return _this.createPill(dataField).then(function (dataFieldPill) {
          (0, _object.set)(dataFieldPill, 'visible', false);
          return dataFieldPill;
        });
      });
      return (0, _rsvp.allSettled)(promises).then(function (arr) {
        return (0, _array.A)(arr).getEach('value');
      });
    },
    generatePills: function generatePills() {
      var _this2 = this;
      return (0, _object.get)(this, 'definition.dataFieldPills').map(function (dataField) {
        return _this2.createPill(_object.default.create(dataField)).then(function (dataFieldPill) {
          return dataFieldPill;
        });
      });
    },
    removePill: function removePill(dataFieldPill) {
      (0, _object.get)(this, 'definition.dataFieldPills').removeObject(dataFieldPill);
    },
    createPill: function createPill(dataField) {
      var dataFieldId = (0, _object.get)(dataField, 'dataFieldId') ? (0, _object.get)(dataField, 'dataFieldId') : (0, _object.get)(dataField, 'id');
      var dataFieldPills = (0, _object.get)(this, 'definition.dataFieldPills');
      var newPill = {
        dataFieldId: dataFieldId,
        name: (0, _object.get)(dataField, 'name'),
        displayName: (0, _object.get)(dataField, 'displayName'),
        dataType: (0, _object.get)(dataField, 'dataType'),
        defaultThirdPartyField: (0, _object.get)(dataField, 'defaultThirdPartyField'),
        description: (0, _object.get)(dataField, 'description')
      };
      if ((0, _object.get)(dataField, 'indexerId') !== undefined) {
        (0, _object.set)(newPill, 'indexerId', (0, _object.get)(dataField, 'indexerId'));
      } else {
        var sortedArr = dataFieldPills.sortBy('indexerId');
        if (sortedArr.length) {
          var index = (0, _object.get)(sortedArr, 'lastObject.indexerId') + 1;
          (0, _object.set)(newPill, 'indexerId', index);
        } else {
          (0, _object.set)(newPill, 'indexerId', 1);
        }
      }
      (0, _object.get)(this, 'definition.dataFieldPills').addFragment(newPill);
      var pillFragment = (0, _object.get)(this, 'definition.dataFieldPills').findBy('indexerId', (0, _object.get)(newPill, 'indexerId'));
      return pillFragment.addFilter().then(function () {
        return pillFragment;
      });
    },
    getDataPillData: function getDataPillData() {
      var _this3 = this;
      var pills = (0, _object.get)(this, 'definition.dataFieldPills').map(function (pill) {
        if ((0, _object.get)(pill, 'filters.length')) {
          return pill;
        } else {
          var dataFieldId = (0, _object.get)(pill, 'dataFieldId');
          return _this3.store.query('data-field-statistic', {
            dataFieldId: dataFieldId
          }).then(function (statistics) {
            (0, _object.set)(pill, 'statistics', (0, _array.A)(statistics));
            var dataPillFilters = (0, _array.A)((0, _object.get)(_this3, 'segment.filters')).findBy('indexerId', JSON.parse(pill.indexerId)).operators;
            return pill.regenerateFilter(dataPillFilters).then(function () {
              return _this3.store.findRecord('dataField', dataFieldId).then(function (dataField) {
                (0, _object.set)(pill, 'dataField', dataField);
                return pill;
              });
            });
          });
        }
      });
      return (0, _rsvp.allSettled)(pills).then(function (arr) {
        return arr;
      });
    },
    generateQuery: function generateQuery() {
      var query = {
        dataFields: [],
        filters: []
      };
      var dataFieldPills = (0, _object.get)(this, 'definition.dataFieldPills');
      dataFieldPills.forEach(function (dataFieldPill) {
        // eslint-disable-next-line ember/no-jquery
        query.dataFields.push(_jquery.default.extend({
          indexerId: (0, _object.get)(dataFieldPill, 'indexerId'),
          visible: (0, _object.get)(dataFieldPill, 'visible')
        }, (0, _object.get)(dataFieldPill, 'dataField').getProperties(['id', 'name', 'displayName', 'dataType', 'defaultThirdPartyField', 'ontologyReference'])));
        query.filters.push({
          dataFieldId: (0, _object.get)(dataFieldPill, 'dataField.id'),
          indexerId: (0, _object.get)(dataFieldPill, 'indexerId'),
          dataType: (0, _object.get)(dataFieldPill, 'dataField.dataType'),
          operators: dataFieldPill.serializeFilters()
        });
      });
      return query;
    },
    generateDescription: function generateDescription() {
      var _this4 = this;
      return this.getDataPillData().then(function () {
        var query = _this4.generateQuery();
        (0, _object.set)(_this4, 'segment', query);
        return _this4;
      });
    }
  });
  _exports.default = _default;
});