define("@trovedata/sunstone-ui-commons/adapters/application", ["exports", "@ember/service", "@ember/object/computed", "@ember/string", "@ember-data/adapter/rest", "@ember-data/adapter/error", "@ember/object"], function (_exports, _service, _computed, _string, _rest, _error, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  var _default = _rest.default.extend({
    config: (0, _service.inject)(),
    session: (0, _service.inject)(),
    host: (0, _computed.readOnly)('config.trove.api.host'),
    namespace: (0, _computed.readOnly)('config.trove.api.namespace'),
    handleResponse: function handleResponse(status, headers, payload) {
      this._super.apply(this, arguments);
      if (this.isSuccess.apply(this, arguments)) {
        return payload;
      } else {
        return new _error.default(payload);
      }
    },
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload && _typeof(payload) === 'object') {
        return [{
          detail: 'Uncaught Exception',
          source: {
            pointer: '/data'
          }
        }];
      }
      return this._super(status, headers, payload);
    },
    isInvalid: function isInvalid(status) {
      return status >= 400 && status !== 401;
    },
    ajaxOptions: function ajaxOptions() {
      var options = this._super.apply(this, arguments);
      options.xhrFields = {
        withCredentials: true
      };
      if (options.data && options.type !== 'GET') {
        options.contentType = 'application/hal+json; charset=utf/8;';
      }
      return options;
    },
    headers: (0, _object.computed)('session.{data.authenticated.access_token,isAuthenticated}', function () {
      var headers = {
        'Accept': 'application/hal+json,application/json'
      };
      if (this.session.isAuthenticated) {
        headers['Authorization'] = "Bearer ".concat(this.session.data.authenticated.access_token);
      }
      return headers;
    }),
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },
    pathForType: function pathForType(type) {
      return (0, _string.camelize)(type);
    },
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      if (_query.endpoint) {
        _query = _query.params;
      }
      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
      }
      return this.ajax(url, 'GET', {
        data: _query
      });
    },
    urlForQueryRecord: function urlForQueryRecord() {
      return this.urlForQuery.apply(this, arguments);
    },
    urlForQuery: function urlForQuery(query, modelName) {
      var baseUrl = this._buildURL(modelName);
      if (query.endpoint) {
        var endpoint = "".concat(baseUrl, "/search/").concat(query.endpoint);
        delete query.endpoint;
        Object.keys(query.params ? query.params : {}).forEach(function (key) {
          query[key] = query.params[key];
        });
        delete query.params;
        return endpoint;
      } else {
        var queryKeys = Object.keys(query);
        var searchableQueryKeys = queryKeys.filter(function (key) {
          return ['page', 'size', 'projection'].indexOf(key) === -1;
        });
        return searchableQueryKeys.length === 1 ? "".concat(baseUrl, "/search/findBy").concat((0, _string.capitalize)((0, _string.camelize)(searchableQueryKeys[0]))) : baseUrl;
      }
    }
  });
  _exports.default = _default;
});