define("@trovedata/sunstone-ui-commons/utils/restheart-utils", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseFilter = parseFilter;
  _exports.parseSort = parseSort;
  function parseSort(sort) {
    var sortQuery = sort.reduce(function (query, def) {
      query[def.key] = def.dir === 'asc' ? 1 : -1;
      return query;
    }, {});
    return JSON.stringify(sortQuery);
  }
  function parseFilter(filter) {
    var filterQuery = {
      $and: filter.reduce(function (query, def) {
        var filter = {};
        filter[def.key] = {};
        if (_moment.default.isMoment(def.value)) {
          filter[def.key]["$".concat(def.type)] = {
            $date: def.value.valueOf()
          };
        } else if (def.type === 'regex') {
          filter[def.key]["$".concat(def.type)] = "^.*".concat(def.value, ".*$");
        } else {
          filter[def.key]["$".concat(def.type)] = def.value;
        }
        query.push(filter);
        return query;
      }, [])
    };
    return JSON.stringify(filterQuery);
  }
});