define("ember-paper/templates/components/paper-dialog", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "84AwnBjY",
    "block": "[[[40,[[[1,\"  \"],[8,[39,3],null,[[\"@locked-open\",\"@opaque\",\"@fixed\",\"@class\",\"@onClick\"],[[99,4,[\"@locked-open\"]],[99,5,[\"@opaque\"]],[52,[51,[33,7]],true],\"md-dialog-backdrop\",[28,[37,8],[[30,0],\"outsideClicked\"],null]]],null],[1,\"\\n  \"],[8,[39,9],null,[[\"@class\",\"@outsideClicked\"],[[28,[37,10],[[33,11]],null],[28,[37,8],[[30,0],\"outsideClicked\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,12],null,[[\"@class\",\"@origin\",\"@defaultedParent\",\"@defaultedOpenFrom\",\"@defaultedCloseTo\",\"@fullscreen\",\"@focusOnOpen\"],[[28,[37,10],[[33,13]],null],[99,14,[\"@origin\"]],[99,15,[\"@defaultedParent\"]],[99,16,[\"@defaultedOpenFrom\"]],[99,17,[\"@defaultedCloseTo\"]],[99,18,[\"@fullscreen\"]],[99,19,[\"@focusOnOpen\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[33,2]],null],null]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"destinationEl\",\"paper-backdrop\",\"isLockedOpen\",\"opaque\",\"unless\",\"parent\",\"action\",\"paper-dialog-container\",\"readonly\",\"dialogContainerClass\",\"paper-dialog-inner\",\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"focusOnOpen\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-dialog.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});