define("@trovedata/sunstone-ui-commons/models/sunstone-report-session-parameter", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    parameterValue: (0, _model.attr)('string'),
    reportParameter: (0, _model.belongsTo)('sunstone-report-parameter', {
      async: false
    }),
    value: (0, _computed.alias)('parameterValue'),
    parameter: (0, _computed.alias)('reportParameter')
  });
  _exports.default = _default;
});