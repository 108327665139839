define("ember-svg-jar/inlined/network-computer-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .002h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M8 9.002h3v1H9v2h6v-2h-2v-1h3a1 1 0 001-1v-6a1 1 0 00-1-1H8a1 1 0 00-1 1v6a1 1 0 001 1zm1-6h6v3H9v-3zM10 13.002H2a1 1 0 00-1 1v6a1 1 0 001 1h3v1H3v2h6v-2H7v-1h3a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 5H3v-3h6v3zM22 13.002h-8a1 1 0 00-1 1v6a1 1 0 001 1h3v1h-2v2h6v-2h-2v-1h3a1 1 0 001-1v-6a1 1 0 00-1-1zm-1 5h-6v-3h6v3z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});