define("ember-paper/components/paper-menu/content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4bcrgoPE",
    "block": "[[[41,[30,1,[\"isOpen\"]],[[[8,[39,1],null,[[\"@destinationElement\",\"@renderInPlace\"],[[30,0,[\"destinationElement\"]],[30,2]]],[[\"default\"],[[[[1,\"    \"],[8,[39,2],[[24,0,\"md-menu-backdrop md-click-catcher\"]],[[\"@onClick\"],[[28,[37,3],[[30,0],[30,1,[\"actions\",\"close\"]]],null]]],null],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[8,[39,4],[[16,0,[29,[\"md-open-menu-container md-whiteframe-z2 md-clickable \",[52,[30,0,[\"isActive\"]],\"md-active\"]]]],[17,3],[4,[38,5],[[30,0,[\"animateIn\"]]],null],[4,[38,6],[[30,0,[\"animateOut\"]]],null]],[[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@height\",\"@otherStyles\",\"@animationEnabled\"],[[30,1],[30,4],[30,2],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,0,[\"customStyles\"]],false]],[[\"default\"],[[[[1,\"\\n\"],[1,\"  \"],[11,\"md-menu-content\"],[16,\"width\",[30,13]],[16,0,[52,[30,14],\"md-dense\"]],[4,[38,5],[[30,0,[\"focusItem\"]]],null],[4,[38,7],[\"keydown\",[30,0,[\"handleKeyDown\"]]],null],[12],[1,\"\\n    \"],[18,15,[[28,[37,9],null,[[\"menu-item\"],[[50,\"paper-menu/item\",0,null,[[\"dropdown\",\"parentComponent\"],[[30,1],[30,0]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@dropdown\",\"@renderInPlace\",\"&attrs\",\"@hPosition\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@height\",\"@width\",\"@dense\",\"&default\"],false,[\"if\",\"maybe-in-element\",\"paper-backdrop\",\"action\",\"basic-dropdown-content\",\"did-insert\",\"will-destroy\",\"on\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/components/paper-menu/content/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});