define("@trovedata/sunstone-ui-commons/mixins/ember-data-hash-route", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    resetController: function resetController() {
      var modelName = this.resettableModel;
      var model = (0, _object.get)(this, "controller.model.".concat(modelName));
      if (!(0, _object.get)(model, 'isDeleted')) {
        model.rollbackAttributes();
      }
    },
    willTransitionConfirm: function willTransitionConfirm() {
      return true;
    },
    actions: {
      willTransition: function willTransition(transition) {
        var modelName = this.resettableModel;
        var model = (0, _object.get)(this.modelFor(this.routeName), modelName);
        if ((0, _object.get)(model, 'hasDirtyAttributes') && !this.willTransitionConfirm(transition)) {
          transition.abort();
        }
      }
    }
  });
  _exports.default = _default;
});