define('@trovedata/paper-time-inputs/components/paper-datetime-input/component', ['exports', '@trovedata/paper-time-inputs/components/paper-datetime-input/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    datetime: Ember.computed.alias('value'),
    format: 'L LT',
    position: 'target bottom',

    actions: {
      selectDate: function selectDate(_ref) {
        var moment = _ref.moment;

        var datetime = Ember.get(this, 'datetime');

        if (datetime) {
          datetime = datetime.clone();

          datetime.set({
            month: moment.month(),
            date: moment.date(),
            year: moment.year()
          });
        } else {
          datetime = moment;
        }

        Ember.get(this, 'onChange')(datetime);
      }
    }
  });
});