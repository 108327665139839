define("ember-paper/components/paper-tabs", ["exports", "@ember/service", "@ember/object/computed", "@ember/object", "@ember/component", "@ember/string", "@ember/runloop", "ember-paper/templates/components/paper-tabs", "ember-composability-tools", "ember-paper/mixins/color-mixin", "ember-paper/utils/invoke-action"], function (_exports, _service, _computed, _object, _component, _string, _runloop, _paperTabs, _emberComposabilityTools, _colorMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_emberComposabilityTools.ParentMixin, _colorMixin.default, {
    layout: _paperTabs.default,
    tagName: 'md-tabs',
    classNames: ['md-no-tab-content', 'md-default-theme'],
    attributeBindings: ['borderBottom:md-border-bottom'],
    constants: (0, _service.inject)(),
    selected: 0,
    // select first tab by default

    noInkBar: false,
    noInk: false,
    ariaLabel: null,
    stretch: 'sm',
    movingRight: true,
    inkBar: (0, _object.computed)('noInkBar', '_selectedTab.{width,left}', 'wrapperWidth', function () {
      if (this.noInkBar) {
        return null;
      }
      var selectedTab = this._selectedTab;
      if (!selectedTab || selectedTab.get('left') === undefined) {
        return null;
      }
      return {
        left: selectedTab.get('left'),
        right: this.wrapperWidth - selectedTab.get('left') - selectedTab.get('width')
      };
    }),
    paginationStyle: (0, _object.computed)('currentOffset', function () {
      return (0, _string.htmlSafe)("transform: translate3d(-".concat(this.currentOffset, "px, 0px, 0px);"));
    }),
    shouldPaginate: true,
    shouldCenter: (0, _object.computed)('shouldPaginate', 'center', function () {
      return !this.shouldPaginate && this.center;
    }),
    shouldStretch: (0, _object.computed)('shouldPaginate', 'currentStretch', function () {
      return !this.shouldPaginate && this.currentStretch;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      this.updateCanvasWidth = function () {
        (0, _runloop.join)(function () {
          _this.updateDimensions();
          _this.updateStretchTabs();
        });
      };
      window.addEventListener('resize', this.updateCanvasWidth);
      window.addEventListener('orientationchange', this.updateCanvasWidth);
      (0, _runloop.scheduleOnce)('afterRender', this, this.fixOffsetIfNeeded);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      // this makes sure that the tabs react to stretch and center changes
      // this method is also called whenever one of the tab is re-rendered (content changes)
      this.updateSelectedTab();
      this.updateCanvasWidth();
    },
    /**
     * Updates the currently selected tab only once all the <paper-tab> has rendered.
     *
     * If we were to use a computed property the observer would get triggered once per
     * nested <paper-tab> because we pass the 'selected' property to them that will
     * invalidate their 'isSelected' property.
     */
    updateSelectedTab: function updateSelectedTab() {
      var selectedTab = this.childComponents.findBy('isSelected');
      var previousSelectedTab = this._selectedTab;
      if (selectedTab === previousSelectedTab) {
        return;
      }
      this.set('movingRight', !selectedTab || !previousSelectedTab || previousSelectedTab.get('left') < selectedTab.get('left'));
      this.set('_selectedTab', selectedTab);
      (0, _runloop.scheduleOnce)('afterRender', this, this.fixOffsetIfNeeded);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      window.removeEventListener('resize', this.updateCanvasWidth);
      window.removeEventListener('orientationchange', this.updateCanvasWidth);
    },
    registerChild: function registerChild(childComponent) {
      this._super.apply(this, arguments);
      // automatically set value if not manually set
      if (childComponent.get('value') === undefined) {
        var length = this.childComponents.get('length');
        childComponent.set('value', length - 1);
      }
    },
    fixOffsetIfNeeded: function fixOffsetIfNeeded() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      var canvasWidth = this.canvasWidth;
      var currentOffset = this.currentOffset;
      var tabLeftOffset = this.get('_selectedTab.left');
      var tabRightOffset = tabLeftOffset + this.get('_selectedTab.width');
      var newOffset;
      if (canvasWidth < this.get('_selectedTab.width')) {
        // align with selectedTab if canvas smaller than selected tab
        newOffset = tabLeftOffset;
      } else if (tabRightOffset - currentOffset > canvasWidth) {
        // ensure selectedTab is not partially hidden on the right side
        newOffset = tabRightOffset - canvasWidth;
      } else if (tabLeftOffset < currentOffset) {
        // ensure selectedTab is not partially hidden on the left side
        newOffset = tabLeftOffset;
      } else {
        newOffset = currentOffset;
      }
      if (newOffset === currentOffset) {
        return;
      }
      this.set('currentOffset', newOffset);
    },
    updateDimensions: function updateDimensions() {
      var canvasWidth = this.element.querySelector('md-tabs-canvas').offsetWidth;
      var wrapperWidth = this.element.querySelector('md-pagination-wrapper').offsetWidth;
      this.childComponents.invoke('updateDimensions');
      this.set('canvasWidth', canvasWidth);
      this.set('wrapperWidth', wrapperWidth);
      this.set('shouldPaginate', wrapperWidth > canvasWidth);
    },
    updateStretchTabs: function updateStretchTabs() {
      var stretch = this.stretch;
      var currentStretch;

      // if `true` or `false` is specified, always/never "stretch tabs"
      // otherwise proceed with normal matchMedia test
      if (typeof stretch === 'boolean') {
        currentStretch = stretch;
      } else {
        var mediaQuery = this.constants.MEDIA[stretch] || stretch;
        currentStretch = window.matchMedia(mediaQuery).matches;
      }
      this.set('currentStretch', currentStretch);
    },
    currentOffset: 0,
    canPageBack: (0, _computed.gt)('currentOffset', 0),
    canPageForward: (0, _object.computed)('wrapperWidth', 'currentOffset', 'canvasWidth', function () {
      return this.wrapperWidth - this.currentOffset > this.canvasWidth;
    }),
    actions: {
      previousPage: function previousPage() {
        var _this2 = this;
        var tab = this.childComponents.find(function (t) {
          // ensure we are no stuck because of a tab with a width > canvasWidth
          return t.get('left') + t.get('width') >= _this2.currentOffset;
        });
        if (tab) {
          var left = Math.max(0, tab.get('left') - this.canvasWidth);
          this.set('currentOffset', left);
        }
      },
      nextPage: function nextPage() {
        var _this3 = this;
        var tab = this.childComponents.find(function (t) {
          // ensure tab's offset is greater than current
          // otherwise if the tab's width is greater than canvas we cannot paginate through it
          return t.get('left') > _this3.currentOffset
          // paginate until the first partially hidden tab
          && t.get('left') + t.get('width') - _this3.currentOffset > _this3.canvasWidth;
        });
        if (tab) {
          this.set('currentOffset', tab.get('left'));
        }
      },
      onChange: function onChange(selected) {
        // support non DDAU scenario
        if (this.onChange) {
          (0, _invokeAction.invokeAction)(this, 'onChange', selected.get('value'));
        } else {
          this.set('selected', selected.get('value'));
        }
      }
    }
  });
  _exports.default = _default;
});