define("@trovedata/sunstone-ui-commons/helpers/display-time", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _helper.default.extend({
    compute: function compute(_ref, _ref2) {
      var _ref3 = _slicedToArray(_ref, 1),
        seconds = _ref3[0];
      var _ref2$day = _ref2.day,
        day = _ref2$day === void 0 ? false : _ref2$day,
        _ref2$hour = _ref2.hour,
        hour = _ref2$hour === void 0 ? false : _ref2$hour,
        _ref2$minute = _ref2.minute,
        minute = _ref2$minute === void 0 ? false : _ref2$minute;
      if (!seconds) {
        return undefined;
      }
      var response = '~';
      var allFalse = !day && !hour && !minute;
      // calculate (and subtract) whole days
      if (allFalse || day) {
        var days = Math.floor(seconds / 86400);
        seconds -= days * 86400;
        if (days === 1) {
          response = "".concat(response, " ").concat(days, " day");
        } else if (days <= 1 && day && !hour && !minute) {
          response = "< 1 day";
        } else {
          response = days ? "".concat(response, " ").concat(days, " days") : response;
        }
      }

      // calculate (and subtract) whole hours
      if (allFalse || hour) {
        var hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        if (hours === 1) {
          response = "".concat(response, " ").concat(hours, " hour");
        } else if (hours <= 1 && !day && hour && !minute) {
          response = "< 1 hour";
        } else {
          response = hours ? "".concat(response, " ").concat(hours, " hours") : response;
        }
      }

      // calculate (and subtract) whole minutes
      if (allFalse || minute) {
        var minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;
        if (seconds !== 0 || minutes !== 0) {
          response = minutes > 1 ? "".concat(response, " ").concat(minutes, " minutes") : "".concat(response, " 1 minute");
        }
      }
      return response === '~' ? '~ 1 minute' : response;
    }
  });
  _exports.default = _default;
});