define('@trovedata/paper-time-inputs/components/paper-date-nav/component', ['exports', 'ember-power-calendar/components/power-calendar/nav', '@trovedata/paper-time-inputs/components/paper-date-nav/template'], function (exports, _nav, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _nav.default.extend({
    layout: _template.default,
    tagName: 'nav',
    classNames: ['ember-power-calendar-nav']
  });
});