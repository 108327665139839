define("ember-svg-jar/inlined/cloud-upload-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M15 2.001a6.96 6.96 0 00-6.438 4.254A4.915 4.915 0 007 6.001c-2.757 0-5 2.243-5 5a5.007 5.007 0 004 4.898v-2.083a2.994 2.994 0 01-2-2.815c0-1.654 1.346-3 3-3 .57 0 1.131.166 1.618.48a1.003 1.003 0 001.517-.613A4.971 4.971 0 0115 4.001c2.757 0 5 2.243 5 5 0 1.627-.793 3.061-2 3.975v2.34c2.361-1.126 4-3.529 4-6.314 0-3.86-3.141-7.001-7-7.001z\"/><path d=\"M16.707 14.294L12 9.587l-4.707 4.707 1.414 1.414L11 13.415v8.586h2v-8.586l2.293 2.293z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});