define("ember-svg-jar/inlined/logo-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Canvas\" fill=\"none\"><g id=\"Trove Logo\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path id=\"Fill 1\" d=\"M0 0v3.599h5.64v12.746h4.216V3.599h5.28V0H0z\" transform=\"translate(0 1.127)\" fill=\"#fff\"/><g id=\"Group 12\"><path id=\"Fill 2\" d=\"M4.215 7.326h.409c1.376 0 2.926-.26 2.926-2.038C7.55 3.511 6 3.25 4.624 3.25h-.41v4.076zm9.275 9.018H8.24l-3.98-6.287h-.043v6.287H0V0h6.304c3.205 0 5.638 1.538 5.638 5.029 0 2.254-1.25 4.205-3.55 4.617l5.098 6.698z\" transform=\"translate(21.324 1.127)\" fill=\"#fff\"/><path id=\"Fill 4\" d=\"M4.39 8.43c0 2.819 2.067 4.878 4.584 4.878 2.517 0 4.582-2.06 4.582-4.877 0-2.254-2.065-4.314-4.582-4.314s-4.583 2.06-4.583 4.314zm13.555-.02c0 5.29-3.766 9.019-8.971 9.019C3.767 17.429 0 13.7 0 8.411 0 3.468 4.304 0 8.974 0c4.668 0 8.97 3.468 8.97 8.41z\" transform=\"translate(39.441 .586)\" fill=\"#fff\"/><path id=\"Fill 6\" d=\"M4.217 3.598v2.731h9.062v3.598H4.216v2.818h9.32v3.6H0V0h13.537v3.598h-9.32z\" transform=\"translate(84.324 1.127)\" fill=\"#fff\"/><path id=\"Fill 8\" d=\"M10.032 0H5.028L0 10.867l2.508 5.475L10.032 0z\" transform=\"translate(70.07 1.13)\" fill=\"#fff\"/><path id=\"Fill 10\" d=\"M12.521 16.342l-2.508-5.474L5.022 0H0l7.5 16.342h5.021z\" transform=\"translate(60.059 1.13)\" fill=\"#8CC63F\"/></g></g></g>",
    "attrs": {
      "width": "98",
      "height": "19",
      "viewBox": "0 0 98 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});