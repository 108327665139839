define("ember-svg-jar/inlined/cross-over-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .006h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M15 4h3.586L2.293 20.293l1.414 1.414L20 5.414V9h2V2h-7z\"/><path d=\"M20 18.586l-5.293-5.293-1.414 1.414L18.586 20H15v2h7v-7h-2z\"/><path transform=\"rotate(-134.999 6.5 6.5)\" d=\"M1.55 5.5h9.899v2H1.55z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24.001",
      "viewBox": "0 0 24 24.001"
    }
  };
  _exports.default = _default;
});