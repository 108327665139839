define("ember-paper/components/paper-menu/item/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XJeXSnW5",
    "block": "[[[11,\"md-menu-item\"],[17,1],[16,\"disabled\",[30,2]],[4,[38,0],[\"mouseenter\",[30,0,[\"handleMouseEnter\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRenderButton\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@onClick\",\"@href\",\"@target\",\"@disabled\"],[[30,0,[\"handleClick\"]],[30,3],[30,4],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,5,null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@disabled\",\"@href\",\"@target\",\"&default\"],false,[\"on\",\"if\",\"paper-button\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-menu/item/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});