define("ember-svg-jar/inlined/cloud-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .004h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M3.794 17.793l1.407-1.407A2.99 2.99 0 014 14c0-1.654 1.346-3 3-3 .57 0 1.13.166 1.617.481a1 1 0 001.518-.614 4.96 4.96 0 014.413-3.829l1.876-1.875A6.821 6.821 0 0015 5a6.963 6.963 0 00-6.438 4.254A4.915 4.915 0 007 9c-2.757 0-5 2.243-5 5 0 1.53.705 2.881 1.794 3.793zM21.207 3.207l-18 18 1.414 1.414L8.242 19H15c3.859 0 7-3.14 7-7 0-1.868-.74-3.564-1.938-4.82l2.559-2.559-1.414-1.414zM15 17h-4.758l8.401-8.401A4.965 4.965 0 0120 12c0 2.757-2.243 5-5 5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});