define('ember-config-service/services/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var configService = Ember.ObjectProxy.extend({
    init: function init() {
      Ember.set(this, 'content', Ember.getOwner(this).factoryFor('config:environment').class);
    }
  });

  configService.reopenClass({
    isServiceFactory: true
  });

  exports.default = configService;
});