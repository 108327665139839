define("@trovedata/sunstone-ui-commons/components/sunstone-map-geojson/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BiVMgENy",
    "block": "[[[41,[30,0,[\"enableSearch\"]],[[[1,\"  \"],[10,\"input\"],[14,0,\"controls\"],[14,1,\"map-search-input\"],[14,\"placeholder\",\"Search city, zip code, etc.\"],[14,4,\"text\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-map-geojson/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});