define("@trovedata/sunstone-ui-commons/components/alias-prop/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tey+9oQG",
    "block": "[[[18,1,[[33,1]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"prop\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/alias-prop/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});