define("@trovedata/sunstone-ui-commons/components/sunstone-table-header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j5Inpjlx",
    "block": "[[[11,\"tr\"],[4,[38,0],[\"Control\",[30,0,[\"keydownHandler\"]]],[[\"event\"],[\"keydown\"]]],[4,[38,0],[\"Control\",[30,0,[\"keyupHandler\"]]],[[\"event\"],[\"keyup\"]]],[4,[38,0],[\"Meta\",[30,0,[\"keydownHandler\"]]],[[\"event\"],[\"keydown\"]]],[4,[38,0],[\"Meta\",[30,0,[\"keyupHandler\"]]],[[\"event\"],[\"keyup\"]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3]],null]],null],null,[[[1,\"    \"],[11,\"th\"],[16,0,[30,1,[\"thClasses\"]]],[4,[38,4],[[30,0],\"resort\",[30,1,[\"id\"]],[30,1,[\"sortable\"]],[30,1,[\"_id\"]]],[[\"allowedKeys\"],[\"ctrl,meta\"]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"column-content\"],[12],[1,\"\\n\"],[6,[39,5],[[28,[37,6],[[28,[37,6],[[33,7],[30,1,[\"_id\"]]],null],\"order\"],null]],null,[[\"default\"],[[[[6,[39,5],[[28,[37,6],[[28,[37,6],[[33,7],[30,1,[\"_id\"]]],null],\"direction\"],null]],null,[[\"default\"],[[[[41,[48,[30,5]],[[[1,\"              \"],[18,5,[[30,1],[30,2],[30,4],[30,3]]],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,1,[\"title\"]]],[1,\"\\n\\n\"],[41,[28,[37,11],[[28,[37,12],[[30,1,[\"sortable\"]],false],null],[28,[37,6],[[33,7],[30,1,[\"_id\"]]],null]],null],[[[1,\"                \"],[1,[28,[35,13],[[52,[28,[37,14],[[30,4],\"asc\"],null],\"triangle-top\",\"triangle-bottom\"]],null]],[1,\"\\n\"]],[]],null]],[]]]],[4]]]]]],[3]]]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1,2]],null],[13],[1,\"\\n\"]],[\"column\",\"index\",\"sortOrder\",\"sortDirection\",\"&default\"],false,[\"on-key\",\"each\",\"-track-array\",\"_columns\",\"action\",\"alias-prop\",\"get\",\"sortByMap\",\"if\",\"has-block\",\"yield\",\"and\",\"not-eq\",\"bs-glyph\",\"eq\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-table-header/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});