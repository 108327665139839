define("ember-paper/components/paper-sidenav", ["exports", "@ember/object/computed", "@ember/component", "ember-paper/templates/components/paper-sidenav", "ember-paper/utils/invoke-action"], function (_exports, _computed, _component, _paperSidenav, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _paperSidenav.default,
    tagName: '',
    name: 'default',
    position: 'left',
    lockedOpen: 'gt-sm',
    open: false,
    closed: (0, _computed.not)('open'),
    closeOnClick: true,
    actions: {
      onToggle: function onToggle() {
        _invokeAction.invokeAction.apply(void 0, [this, 'onToggle'].concat(Array.prototype.slice.call(arguments)));
      },
      onBackdropTap: function onBackdropTap() {
        (0, _invokeAction.invokeAction)(this, 'onToggle', false);
      }
    }
  });
  _exports.default = _default;
});