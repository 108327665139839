define("@trovedata/sunstone-ui-commons/components/trove-spinner/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "f/R8XSB5",
    "block": "[[[10,\"circle\"],[14,0,\"spinner-back\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"40\"],[14,\"fill\",\"none\"],[15,\"stroke-width\",[29,[[36,0]]]],[14,\"stroke-linecap\",\"round\"],[12],[13],[1,\"\\n\"],[10,\"circle\"],[14,0,\"spinner-fill\"],[14,\"cx\",\"50\"],[14,\"cy\",\"50\"],[14,\"r\",\"40\"],[14,\"fill\",\"none\"],[15,\"stroke-width\",[29,[[36,0]]]],[14,\"stroke-linecap\",\"round\"],[12],[1,\"\\n  \"],[10,\"animate\"],[14,\"attributeName\",\"stroke-dashoffset\"],[14,\"dur\",\"2s\"],[14,\"repeatCount\",\"indefinite\"],[14,\"from\",\"0\"],[14,\"to\",\"502\"],[12],[13],[1,\"\\n  \"],[10,\"animate\"],[14,\"attributeName\",\"stroke-dasharray\"],[14,\"dur\",\"2s\"],[14,\"repeatCount\",\"indefinite\"],[14,\"values\",\"150.6 100.4;1 250;150.6 100.4\"],[12],[13],[1,\"   \"],[13],[1,\"\\n\"],[10,\"text\"],[15,\"x\",[29,[[36,1]]]],[14,\"y\",\"57\"],[14,\"font-size\",\"24\"],[12],[1,[34,2]],[13],[1,\"\\n\"]],[],false,[\"strokeWidth\",\"centerOffset\",\"displayText\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/trove-spinner/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});