define("@trovedata/sunstone-ui-commons/models/report-entry", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    reportName: (0, _model.attr)('string'),
    availWhenComplete: (0, _model.attr)('boolean'),
    reportId: (0, _model.attr)('number'),
    modelReportMap: (0, _model.belongsTo)('model-report-map', {
      async: true
    })
  });
  _exports.default = _default;
});