define("ember-paper/templates/components/paper-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bIesN6GB",
    "block": "[[[41,[33,1],[[[40,[[[1,\"    \"],[10,0],[14,0,\"md-panel-outer-wrapper md-panel-is-showing\"],[15,5,[36,5]],[12],[1,\"\\n      \"],[8,[39,6],[[17,1]],[[\"@class\",\"@position\",\"@anchorElement\",\"@hide\"],[[99,7,[\"@class\"]],[99,8,[\"@position\"]],[99,9,[\"@anchorElement\"]],[99,10,[\"@hide\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[33,4]],null],null]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"renderTooltip\",\"in-element\",\"-in-el-null\",\"destinationEl\",\"containerStyle\",\"paper-tooltip-inner\",\"class\",\"position\",\"anchorElement\",\"hideTooltip\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-tooltip.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});