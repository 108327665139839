define("@trovedata/sunstone-ui-commons/components/paper-breadcrumb/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6FOmmMI8",
    "block": "[[[10,\"paper-breadcrumb-inner\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-breadcrumb/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});