define("@trovedata/sunstone-ui-commons/components/sunstone-results-table-header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hwbKDRGY",
    "block": "[[[41,[48,[30,2]],[[[1,\"  \"],[18,2,[[33,3],[33,4],[33,5]]],[1,\"\\n\"]],[]],[[[41,[33,5],[[[44,[[33,3]],[[[41,[30,1],[[[1,\"      \"],[19,[33,5],[1]],[1,\"\\n\"]],[]],null]],[1]]]],[]],[[[1,\"    \"],[10,\"strong\"],[12],[1,[34,3]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n\"],[41,[33,4],[[[1,\"      \"],[19,[33,4],[]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"content\",\"&default\"],true,[\"if\",\"has-block\",\"yield\",\"title\",\"headerPartial\",\"titlePartial\",\"let\",\"partial\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-results-table-header/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});