define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-actions-trigger/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2saUMfzm",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"icon\",\"toggleActions\",\"showActions\"],[[50,\"card-list/card-list-item/card-list-item-actions-trigger/card-list-item-actions-trigger-icon\",0,null,[[\"showActions\",\"toggleActions\"],[[33,5],[33,6]]]],[33,6],[33,5]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,\"card-list/card-list-item/card-list-item-actions-trigger/card-list-item-actions-trigger-icon\",null,[[\"showActions\",\"toggleActions\"],[[33,5],[33,6]]],null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"showActions\",\"toggleActions\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-actions-trigger/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});