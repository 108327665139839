define("ember-svg-jar/inlined/sign-new-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M12 0C5.384 0 0 5.383 0 12s5.384 12 12 12c6.617 0 12-5.383 12-12S18.617 0 12 0zm0 22C6.486 22 2 17.514 2 12S6.486 2 12 2c5.515 0 10 4.486 10 10s-4.485 10-10 10z\"/><path d=\"M8.666 11.335v1.333c0 1.103.898 2 2 2h2v-1.333h-2a.668.668 0 01-.666-.667h2v-1.333h-2c0-.368.299-.667.666-.667h2V9.335h-2c-1.102 0-2 .896-2 2zM6.666 11.801L5.221 9.633A.665.665 0 004 10v4.668h1.334v-2.465l1.445 2.168A.667.667 0 008 14V9.335H6.666v2.466zM18.629 9.335l-.639 2.601-.656-2.601h-1.372l-.638 2.601-.658-2.601h-1.332l1.309 5.333h1.371L16 14.615l.657-2.603.652 2.656H18.681l-.015-.053L20 9.335z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});