define("ember-svg-jar/inlined/expand-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M14.707 10.707L20 5.414V8h2V2h-6v2h2.586l-5.293 5.293zM8 20H5.414l5.293-5.293-1.414-1.414L4 18.586V16H2v6h6zM4 5.414l5.293 5.293 1.414-1.414L5.414 4H8V2H2v6h2zM16 20v2h6v-6h-2v2.586l-5.293-5.293-1.414 1.414L18.586 20z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});