define("ember-paper/components/paper-select/ebd-trigger/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LIOQLWXK",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShowLabel\"]],[[[1,\"  \"],[10,\"label\"],[15,\"for\",[29,[\"ember-basic-dropdown-trigger-\",[30,1,[\"uniqueId\"]]]]],[15,0,[52,[30,2],\"md-required\"]],[12],[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,1],[[17,4],[16,1,[29,[\"ember-basic-dropdown-trigger-\",[30,1,[\"uniqueId\"]]]]],[16,\"disabled\",[30,5]],[16,\"required\",[30,2]]],[[\"@htmlTag\",\"@eventType\",\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\"],[\"md-select\",\"click\",[30,1],[30,6],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n  \"],[18,9,null],[1,\"\\n\"]],[]]]]]],[\"@dropdown\",\"@required\",\"@label\",\"&attrs\",\"@disabled\",\"@hPosition\",\"@renderInPlace\",\"@vPosition\",\"&default\"],false,[\"if\",\"basic-dropdown-trigger\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-select/ebd-trigger/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});