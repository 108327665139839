define("ember-paper/templates/components/paper-card-title", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZK3rbIqE",
    "block": "[[[18,1,[[28,[37,1],null,[[\"text\",\"media\"],[[50,\"paper-card-title-text\",0,null,null],[50,\"paper-card-title-media\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-card-title.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});