define("ember-paper/components/paper-card-media", ["exports", "@ember/component", "ember-paper/templates/components/paper-card-media"], function (_exports, _component, _paperCardMedia) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperCardMedia
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _paperCardMedia.default,
    tagName: '',
    size: 'md'
  });
  _exports.default = _default;
});