define("@trovedata/sunstone-ui-commons/components/sunstone-results-table-footer/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s0/SAG2Y",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],null,[[\"currentPage\",\"pageSize\",\"totalResults\",\"onPageChange\"],[[33,3],[33,4],[33,1],[33,5]]]]],[1,\"\\n\"]],[]],[[[1,\"   \\n\"]],[]]]],[],false,[\"if\",\"totalResults\",\"sunstone-pagination\",\"currentPage\",\"pageSize\",\"onPageChange\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-results-table-footer/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});