define("ember-svg-jar/inlined/building-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0-.002h24v24.001H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M6 12.999v-2H4v2H3c-.552 0-1 .449-1 1v7a1 1 0 001 1h3a1 1 0 001-1v-7a1 1 0 00-1-1zm-1 7H4v-5h1v5zM21 12.999h-1v-2h-2v2c-.552 0-1 .449-1 1v7a1 1 0 001 1h3a1 1 0 001-1v-7a1 1 0 00-1-1zm-1 7h-1v-5h1v5zM15 3.999h-1v-2h-2v2H9c-.552 0-1 .449-1 1v16a1 1 0 001 1h6a1 1 0 001-1v-16a1 1 0 00-1-1zm-1 16h-4v-14h4v14z\"/><path d=\"M11 7.999h2v2h-2zM11 11.999h2v2h-2zM11 15.999h2v2h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});