define("ember-svg-jar/inlined/demographics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#231f20\"><path d=\"M33.1 36c0-4.6-3.2-8.4-7.5-9.3.8-1.3 1.2-2.8 1.2-4.4 0-4.8-3.9-8.6-8.6-8.6-4.8 0-8.6 3.9-8.6 8.6 0 1.6.4 3 1.1 4.3-4.7.6-8.4 4.6-8.4 9.5v15.6c0 3.3 1.7 6.4 4.5 8.1v17.6c0 5.3 4.3 9.6 9.6 9.6H19c5.3 0 9.6-4.3 9.6-9.6V59.7c2.8-1.7 4.5-4.8 4.5-8.1V36zM18.3 18.2c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4c-.1-2.2 1.7-4 4-4zm9.2 33.3c0 1.7-1 3.1-2.6 3.7l-1.9.7v21.4c0 2.2-1.8 3.9-3.9 3.9h-2.6c-2.2 0-3.9-1.8-3.9-3.9V55.9l-1.9-.7c-1.6-.6-2.6-2-2.6-3.7V36c0-2.2 1.8-3.9 3.9-3.9h11.6c2.2 0 3.9 1.8 3.9 3.9v15.5zM48.7 86.9h2.6c5.3 0 9.6-4.3 9.6-9.6V59.7c2.7-1.7 4.5-4.8 4.5-8.1V36c0-4.7-3.4-8.6-7.9-9.4.7-1.3 1.2-2.8 1.2-4.3 0-4.8-3.9-8.6-8.6-8.6s-8.6 3.9-8.6 8.6c0 1.6.4 3.1 1.2 4.3-4.5.8-7.9 4.7-7.9 9.4v15.6c0 3.3 1.7 6.4 4.5 8.1v17.6c-.2 5.3 4.1 9.6 9.4 9.6zM50 18.2c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zM40.3 36c0-2.2 1.8-3.9 3.9-3.9h11.6c2.2 0 3.9 1.8 3.9 3.9v15.6c0 1.7-1 3.1-2.6 3.7l-1.9.7v21.4c0 2.2-1.8 3.9-3.9 3.9h-2.6c-2.2 0-3.9-1.8-3.9-3.9V55.9l-1.9-.7c-1.6-.6-2.6-2-2.6-3.7V36zM89.7 26.5c.7-1.3 1.2-2.8 1.2-4.3 0-4.8-3.9-8.6-8.6-8.6-4.8 0-8.6 3.9-8.6 8.6 0 1.6.4 3.1 1.2 4.3-4.5.8-7.9 4.7-7.9 9.4v15.6c0 3.3 1.7 6.4 4.5 8.1v17.6c0 5.3 4.3 9.6 9.6 9.6h2.6c5.3 0 9.6-4.3 9.6-9.6V59.7c2.7-1.7 4.5-4.8 4.5-8.1V36c-.2-4.8-3.6-8.7-8.1-9.5zm-7.5-8.3c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm9.7 33.3c0 1.7-1 3.1-2.6 3.7l-1.9.7v21.4c0 2.2-1.8 3.9-3.9 3.9h-2.6c-2.2 0-3.9-1.8-3.9-3.9V55.9l-1.9-.7c-1.6-.6-2.6-2-2.6-3.7V36c0-2.2 1.8-3.9 3.9-3.9H88c2.2 0 3.9 1.8 3.9 3.9v15.5z\"/><path d=\"M66.5 76.9c-1.5 0-2.7 1.2-2.7 2.7v10.1s0 .1-.1.1H36.3s-.1 0-.1-.1V79.6c0-1.5-1.2-2.7-2.7-2.7s-2.7 1.2-2.7 2.7v10.1c0 3 2.4 5.4 5.4 5.4h27.5c3 0 5.4-2.4 5.4-5.4V79.6c.1-1.5-1.1-2.7-2.6-2.7zM33.5 23.1c1.5 0 2.7-1.2 2.7-2.7V10.3s0-.1.1-.1h27.5s.1 0 .1.1v10.1c0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7V10.3c0-3-2.4-5.4-5.4-5.4H36.3c-3 0-5.4 2.4-5.4 5.4v10.1c0 1.5 1.2 2.7 2.6 2.7z\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 100 100",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});