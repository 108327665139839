define("ember-paper/utils/promise-proxies", ["exports", "rsvp", "@ember/array/proxy", "@ember/object/proxy", "@ember/object/promise-proxy-mixin"], function (_exports, _rsvp, _proxy, _proxy2, _promiseProxyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PromiseObject = _exports.PromiseArray = void 0;
  _exports.promiseArray = promiseArray;
  _exports.promiseObject = promiseObject;
  /**
   * @module ember-paper
   */

  // See http://emberjs.com/api/data/classes/DS.PromiseArray.html
  var PromiseArray = _proxy.default.extend(_promiseProxyMixin.default);
  // See http://emberjs.com/api/data/classes/DS.PromiseObject.html
  _exports.PromiseArray = PromiseArray;
  var PromiseObject = _proxy2.default.extend(_promiseProxyMixin.default);
  _exports.PromiseObject = PromiseObject;
  function promiseObject(promise, label) {
    return PromiseObject.create({
      promise: _rsvp.Promise.resolve(promise, label)
    });
  }
  function promiseArray(promise, label) {
    return PromiseArray.create({
      promise: _rsvp.Promise.resolve(promise, label)
    });
  }
});