define("ember-svg-jar/inlined/st-menu-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M33 64.2c-8.375 0-16.246-3.26-22.17-9.18-5.923-5.92-9.18-13.795-9.18-22.167 0-8.375 3.26-16.246 9.18-22.17C16.75 4.76 24.626 1.5 33 1.5c8.375 0 16.246 3.26 22.17 9.184 5.923 5.923 9.18 13.794 9.18 22.17 0 8.375-3.26 16.245-9.18 22.165C49.25 60.94 41.374 64.2 33 64.2zm0-59.4C17.533 4.8 4.95 17.383 4.95 32.85S17.533 60.9 33 60.9s28.05-12.583 28.05-28.05S48.467 4.8 33 4.8z\" fill=\"#4AAECC\"/><path d=\"M49.5 34.8h-33a1.65 1.65 0 010-3.3h33a1.65 1.65 0 010 3.3z\" fill=\"#4AAECC\"/>",
    "attrs": {
      "width": "66",
      "height": "66",
      "viewBox": "0 0 66 66",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});