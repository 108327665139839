define("ember-paper/components/paper-tooltip-inner", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-paper/templates/components/paper-tooltip-inner", "ember-css-transitions/utils/transition-utils", "ember-paper/utils/calculate-tooltip-position"], function (_exports, _component, _object, _runloop, _paperTooltipInner, _transitionUtils, _calculateTooltipPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _component.default.extend({
    layout: _paperTooltipInner.default,
    tagName: '',
    positionClass: (0, _object.computed)('position', function () {
      return "md-origin-".concat(this.position);
    }),
    setupTooltip: function setupTooltip(element, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        position = _ref2[0],
        anchorElement = _ref2[1];
      _runloop.run.schedule('afterRender', function () {
        var pos = (0, _calculateTooltipPosition.default)(element, anchorElement, position);
        element.style.top = "".concat(pos.top, "px");
        element.style.left = "".concat(pos.left, "px");
        element.classList.add('md-show-add');
        (0, _transitionUtils.nextTick)().then(_transitionUtils.nextTick).then(_transitionUtils.nextTick).then(_transitionUtils.nextTick).then(function () {
          element.classList.add('md-show');
        });
      });
    }
  });
  _exports.default = _default;
});