define("@trovedata/sunstone-ui-commons/utils/component-utils", ["exports", "@ember/application", "@ember/template"], function (_exports, _application, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calcColorVar = calcColorVar;
  _exports.calcStyles = calcStyles;
  _exports.default = initComponent;
  _exports.initGlimmerComponent = initGlimmerComponent;
  function initComponent(scope, name) {
    var props = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var owner = (0, _application.getOwner)(scope);
    // @ts-ignore
    var factory = owner.factoryFor("component:".concat(name));
    var comp = factory.create(props);
    comp.didReceiveAttrs();
    return comp;
  }
  function initGlimmerComponent(scope, name) {
    var named = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var owner = (0, _application.getOwner)(scope);
    // @ts-ignore
    var componentManager = owner.lookup('component-manager:glimmer');
    // @ts-ignore
    var _owner$factoryFor = owner.factoryFor("component:".concat(name)),
      componentClass = _owner$factoryFor.class;
    return componentManager.createComponent(componentClass, {
      named: named
    });
  }
  function calcColorVar(className, value) {
    if (typeof value === 'boolean' && value) {
      return className;
    }
    if (typeof value === 'number' && value) {
      return "".concat(className, " color-").concat(value);
    }
    return undefined;
  }
  function calcStyles() {
    var stylesConf = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var styles = '';
    if (stylesConf.opacity !== undefined) {
      styles = "".concat(styles, "--opacity: ").concat(stylesConf.opacity, ";");
    }
    return (0, _template.htmlSafe)(styles);
  }
});