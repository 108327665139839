define("ember-composability-tools/templates/render-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cMiXtd0z",
    "block": "[[[41,[33,1],[[[40,[[[18,1,[[33,6]]]],[]],\"%cursor:0%\",[28,[37,3],[[33,4]],null],null]],[]],null]],[\"&default\"],false,[\"if\",\"shouldRender\",\"in-element\",\"-clear-element\",\"destinationElement\",\"yield\",\"yieldHash\"]]",
    "moduleName": "ember-composability-tools/templates/render-block.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});