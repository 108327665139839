define("ember-paper/components/paper-backdrop", ["exports", "@ember/component", "ember-paper/templates/components/paper-backdrop"], function (_exports, _component, _paperBackdrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperBackdrop
   * @extends Ember.Component
   * @uses TransitionMixin
   */
  var _default = _component.default.extend({
    layout: _paperBackdrop.default,
    tagName: '',
    // addDestroyedElementClone(original, clone) {
    //   if (original.parentNode) {
    //     original.parentNode.appendChild(clone);
    //   }
    // },
    sendClickAction: function sendClickAction(onClick, e) {
      e.preventDefault();
      if (onClick) {
        onClick(e);
      }
    }
  });
  _exports.default = _default;
});