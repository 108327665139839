define("ember-svg-jar/inlined/user-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M20.709.3a.999.999 0 00-1.414 0l-7 7a1.005 1.005 0 00-.263.465l-1.001 4a1.003 1.003 0 001.213 1.213l4-1.001a.997.997 0 00.465-.263l7-7a.999.999 0 000-1.414l-3-3zm-5.218 9.803l-2.115.53.53-2.114 3.097-3.098 1.586 1.586-3.098 3.096zm4.511-4.51l-1.586-1.586 1.586-1.586 1.586 1.586-1.586 1.586zM16.002 24.007h-16v-1c0-3.533 3.289-6 8-6s8 2.467 8 6v1zm-13.841-2h11.684c-.599-1.808-2.834-3-5.842-3s-5.244 1.192-5.842 3zM6.002 12.007a2 2 0 012-2v-2c-2.205 0-4 1.795-4 4s1.795 4 4 4v-2c-1.103 0-2-.898-2-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});