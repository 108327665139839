define("@trovedata/sunstone-ui-commons/models/data-field-statistic-value", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    value: (0, _model.attr)('string'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    validations: {
      name: {
        presence: true
      },
      value: {
        presence: true
      }
    }
  });
  _exports.default = _default;
});