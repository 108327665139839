define("ember-paper/components/paper-checkbox", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "@ember/component", "@ember/debug", "ember-paper/templates/components/paper-checkbox", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/proxiable-mixin", "ember-paper/utils/invoke-action"], function (_exports, _service, _object, _computed, _component, _debug, _paperCheckbox, _focusableMixin, _colorMixin, _proxiableMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperCheckbox
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ColorMixin
   * @uses ProxiableMixin
   */
  var _default = _component.default.extend(_focusableMixin.default, _colorMixin.default, _proxiableMixin.default, {
    layout: _paperCheckbox.default,
    tagName: 'md-checkbox',
    classNames: ['md-checkbox', 'md-default-theme'],
    classNameBindings: ['isChecked:md-checked', 'indeterminate:md-indeterminate'],
    attributeBindings: ['role:role', 'ariaLabel:aria-label', 'ariaChecked:aria-checked', 'labelId:aria-labelledby'],
    /* FocusableMixin Overrides */
    focusOnlyOnKey: true,
    constants: (0, _service.inject)(),
    value: false,
    role: 'checkbox',
    notIndeterminate: (0, _computed.not)('indeterminate'),
    isChecked: (0, _computed.and)('notIndeterminate', 'value'),
    ariaChecked: (0, _object.computed)('isChecked', 'indeterminate', function () {
      if (this.indeterminate) {
        return 'mixed';
      }
      return this.isChecked ? 'true' : 'false';
    }),
    labelId: (0, _object.computed)('elementId', function () {
      return "".concat(this.elementId, "-label");
    }),
    init: function init() {
      this._super.apply(this, arguments);
      (false && !(this.onChange !== undefined) && (0, _debug.assert)('{{paper-checkbox}} requires an `onChange` action or null for no action.', this.onChange !== undefined));
    },
    click: function click() {
      if (!this.disabled) {
        (0, _invokeAction.invokeAction)(this, 'onChange', !this.value);
      }
      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.bubbles;
    },
    keyPress: function keyPress(ev) {
      if (ev.which === this.get('constants.KEYCODE.SPACE') || ev.which === this.get('constants.KEYCODE.ENTER')) {
        ev.preventDefault();
        this.click();
      }
    },
    processProxy: function processProxy() {
      (0, _invokeAction.invokeAction)(this, 'onChange', !this.value);
    }
  });
  _exports.default = _default;
});