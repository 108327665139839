define("ember-svg-jar/inlined/data-upload-7", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .004h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M7.293 11.294l1.414 1.414L11 10.415v6.586h2v-6.586l2.293 2.293 1.414-1.414L12 6.587zM3 5.001v14c0 1.102.896 2 2 2h1v-2H5v-14h1v-2H5a2 2 0 00-2 2zM19 3.001h-1v2h1v14h-1v2h1c1.104 0 2-.898 2-2v-14a2 2 0 00-2-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});