define("@trovedata/sunstone-ui-commons/components/trove-map-markers/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rQSiqBXW",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/trove-map-markers/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});