define("@trovedata/sunstone-ui-commons/components/sunstone-map/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eG3Co4mG",
    "block": "[[[10,0],[14,1,\"map\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"google\"]],[[[41,[33,1],[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"mapType\"],[[50,[33,1],0,null,[[\"map\"],[[28,[37,5],[[33,6]],null]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"geoJson\",\"polyline\",\"markers\",\"marker\"],[[50,\"sunstone-map-geojson\",0,null,[[\"map\"],[[28,[37,5],[[33,6]],null]]]],[50,\"trove-map-polylines\",0,null,[[\"map\"],[[28,[37,5],[[33,6]],null]]]],[50,\"trove-map-markers\",0,null,[[\"map\"],[[28,[37,5],[[33,6]],null]]]],[50,\"trove-map-marker\",0,null,[[\"map\"],[[28,[37,5],[[33,6]],null]]]]]]]]],[1,\"\\n\"]],[]]]],[]],null]],[\"&default\"],false,[\"if\",\"mapType\",\"yield\",\"hash\",\"component\",\"mut\",\"map\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-map/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});