define("@trovedata/sunstone-ui-commons/components/icon-message/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mZmsW1/K",
    "block": "[[[41,[48,[30,6]],[[[1,\"  \"],[18,6,[[28,[37,3],null,[[\"icon\",\"message\",\"class\"],[[50,\"trove-icon\",0,[[33,5]],[[\"size\"],[[33,6]]]],[33,7],\"layout-margin\"]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,8],[[24,0,\"layout-margin\"]],[[\"@icon\",\"@size\"],[[99,5,[\"@icon\"]],[99,6,[\"@size\"]]]],null],[1,\"\\n  \"],[10,1],[14,\"data-test-error-message\",\"\"],[15,0,[29,[[36,9]]]],[12],[1,[34,7]],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,1],[15,0,[29,[[36,10]]]],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,11],[[30,2],[30,3]],null],[[[1,\"    \"],[8,[39,12],null,[[\"@onClick\",\"@href\",\"@raised\"],[[30,2],[30,3],true]],[[\"default\"],[[[[1,\" \\n      \"],[8,[39,8],null,[[\"@icon\"],[[52,[30,4],[30,4],\"add-circle-1\"]]],null],[1,\"\\n      \"],[1,[52,[30,5],[30,5],\"Create\"]],[1,\"  \\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]]],[\"@message2\",\"@clickAction\",\"@clickHref\",\"@clickIcon\",\"@clickActionText\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"icon\",\"size\",\"message\",\"trove-icon\",\"fontClass\",\"fontClass2\",\"or\",\"trove-button\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/icon-message/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});