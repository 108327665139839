define("ember-svg-jar/inlined/organization-flowchart-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M22 11h-4c0-1.654-1.346-3-3-3h-3c-.551 0-1-.449-1-1V6h5a1 1 0 001-1V1a1 1 0 00-1-1H4a1 1 0 00-1 1v4a1 1 0 001 1h5v1c0 1.654 1.346 3 3 3h3c.551 0 1 .449 1 1h-6a1 1 0 00-1 1v4a1 1 0 001 1h7v2c0 .551-.449 1-1 1h-1v-1a1 1 0 00-1-1H2a1 1 0 00-1 1v4a1 1 0 001 1h12a1 1 0 001-1v-1h1c1.654 0 3-1.346 3-3v-2h3a1 1 0 001-1v-4a1 1 0 00-1-1zM5 2h10v2H5V2zM3 22v-2h9v2H3zm18-7H11v-2h10v2z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});