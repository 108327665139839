define("ember-svg-jar/inlined/location-pin-add-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M21 18h-3v-3h-2v3h-3v2h3v3h2v-3h3zM8 1C4.14 1 1 4.14 1 8c0 3.885 4.489 9.608 7 12.537 2.513-2.932 7-8.655 7-12.537 0-3.86-3.14-7-7-7zm0 16.425C6.125 15.061 3 10.582 3 8c0-2.757 2.243-5 5-5s5 2.243 5 5c0 2.578-3.125 7.06-5 9.425z\"/><path d=\"M8 5C6.346 5 5 6.346 5 8s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3zm0 4a1.001 1.001 0 010-2 1.001 1.001 0 010 2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});