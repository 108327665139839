define('@trovedata/paper-time-inputs/components/paper-date/component', ['exports', 'ember-power-calendar/components/power-calendar', 'moment', '@trovedata/paper-time-inputs/components/paper-date/template'], function (exports, _powerCalendar, _moment, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _powerCalendar.default.extend({
    layout: _template.default,
    classNames: ['paper-date'],

    navComponent: 'paper-date-nav',
    daysComponent: 'paper-date-days',

    years: Ember.computed('currentCenter', function () {
      var bufferLength = 4;
      var center = Ember.get(this, 'currentCenter');
      var year = center.year();
      var years = [];

      for (var i = year - bufferLength; i <= year + bufferLength; i++) {
        years.push(i);
      }

      return years;
    }),

    months: Ember.computed(function () {
      return _moment.default.monthsShort();
    }),

    actions: {
      showYears: function showYears() {
        Ember.set(this, 'showYears', true);
      },
      showMonths: function showMonths() {
        Ember.set(this, 'showMonths', true);
      },
      setCenterYear: function setCenterYear(year) {
        var center = Ember.get(this, 'currentCenter').clone();

        center.year(year);

        Ember.set(this, 'showYears', false);
        Ember.get(this, 'onCenterChange')({ moment: center });
      },
      setCenterMonth: function setCenterMonth(month) {
        var center = Ember.get(this, 'currentCenter').clone();

        center.month(month);

        Ember.set(this, 'showMonths', false);
        Ember.get(this, 'onCenterChange')({ moment: center });
      }
    }
  });
});