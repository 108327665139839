define("@trovedata/sunstone-ui-commons/models/model-instance-resource", ["exports", "@ember/object/computed", "@ember/object", "@ember-data/model"], function (_exports, _computed, _object, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var model = _model.default.extend({
    name: (0, _model.attr)('string'),
    modelName: (0, _model.attr)('string'),
    modelSessionId: (0, _model.attr)('number'),
    sessionStatus: (0, _model.attr)('string'),
    startTime: (0, _model.attr)('utc-timestamp'),
    endTime: (0, _model.attr)('utc-timestamp'),
    status: (0, _computed.alias)('sessionStatus'),
    isDone: (0, _object.computed)('constructor.DONE_STATES', 'status', function () {
      return this.constructor.DONE_STATES.indexOf(this.status) >= 0;
    }),
    isRunning: (0, _object.computed)('constructor.RUNNING_STATES', 'status', function () {
      return this.constructor.RUNNING_STATES.indexOf(this.status) >= 0;
    }),
    isPaused: (0, _object.computed)('constructor.PAUSED_STATES', 'status', function () {
      return this.constructor.PAUSED_STATES.indexOf(this.status) >= 0;
    }),
    hasFailed: (0, _object.computed)('constructor.FAILED_STATES', 'status', function () {
      return this.constructor.FAILED_STATES.indexOf(this.status) >= 0;
    }),
    wasSuccessful: (0, _object.computed)('constructor.SUCCESSFUL_STATES', 'status', function () {
      return this.constructor.SUCCESSFUL_STATES.indexOf(this.status) >= 0;
    }),
    isInactive: _object.computed.equal('status', 'INACTIVE'),
    notRunning: (0, _computed.not)('isRunning')
  });
  model.reopenClass({
    RUNNING_STATES: ['READY', 'RUNNING', 'STOPPING'],
    DONE_STATES: ['KILLED', 'STOPPED', 'FAILED', 'FINISHED'],
    PAUSED_STATES: ['PAUSED'],
    FAILED_STATES: ['FAILED', 'KILLED'],
    SUCCESSFUL_STATES: ['FINISHED']
  });
  var _default = model;
  _exports.default = _default;
});