define("@trovedata/sunstone-ui-commons/transforms/long", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/use-ember-data-rfc-395-imports */
  var _default = _emberData.default.NumberTransform.extend();
  _exports.default = _default;
});