define("ember-paper/templates/components/paper-speed-dial", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FZhAAEY5",
    "block": "[[[18,1,[[28,[37,1],null,[[\"trigger\",\"actions\"],[[50,\"paper-speed-dial-trigger\",0,null,[[\"speedDial\"],[[30,0]]]],[50,\"paper-speed-dial-actions\",0,null,[[\"speedDial\"],[[30,0]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-speed-dial.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});