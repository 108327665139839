define("ember-svg-jar/inlined/Analytics-White", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g><path class=\"st0\" d=\"M59.3 0H8.1C3.7 0 0 3.7 0 8.1v51.2c0 4.5 3.7 8.1 8.1 8.1h51.2c4.5 0 8.1-3.7 8.1-8.1V8.1c0-4.4-3.6-8.1-8.1-8.1zm2.2 59.3c0 1.2-1 2.2-2.2 2.2H8.1c-1.2 0-2.2-1-2.2-2.2V8.1C6 6.9 6.9 6 8.1 6h51.2c1.2 0 2.2 1 2.2 2.2v51.1zm0 0M59.3 76.6H8.1c-4.5 0-8.1 3.7-8.1 8.1v51.2c0 4.5 3.7 8.1 8.1 8.1h51.2c4.5 0 8.1-3.7 8.1-8.1V84.7c0-4.5-3.6-8.1-8.1-8.1zm2.2 59.3c0 1.2-1 2.2-2.2 2.2H8.1c-1.2 0-2.2-1-2.2-2.2V84.7c0-1.2 1-2.2 2.2-2.2h51.2c1.2 0 2.2 1 2.2 2.2v51.2zm0 0M135.9 0H84.7c-4.5 0-8.1 3.7-8.1 8.1v51.2c0 4.5 3.7 8.1 8.1 8.1h51.2c4.5 0 8.1-3.7 8.1-8.1V8.1c0-4.4-3.7-8.1-8.1-8.1zm2.1 59.3c0 1.2-1 2.2-2.2 2.2H84.7c-1.2 0-2.2-1-2.2-2.2V8.1c0-1.2 1-2.2 2.2-2.2h51.2c1.2 0 2.2 1 2.2 2.2v51.2zm0 0M135.9 76.6H84.7c-4.5 0-8.1 3.7-8.1 8.1v51.2c0 4.5 3.7 8.1 8.1 8.1h51.2c4.5 0 8.1-3.7 8.1-8.1V84.7c0-4.5-3.7-8.1-8.1-8.1zm2.1 59.3c0 1.2-1 2.2-2.2 2.2H84.7c-1.2 0-2.2-1-2.2-2.2V84.7c0-1.2 1-2.2 2.2-2.2h51.2c1.2 0 2.2 1 2.2 2.2v51.2zm0 0\"/><path class=\"st0\" d=\"M51.6 30.7H36.7V15.9c0-1.6-1.3-3-3-3-1.6 0-3 1.3-3 3v14.9H15.9c-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3h14.9v14.9c0 1.6 1.3 3 3 3 1.6 0 3-1.3 3-3v-15h14.9c1.6 0 3-1.3 3-3-.2-1.6-1.5-3-3.1-3zm0 0M125 19c-1.2-1.2-3-1.2-4.2 0l-10.5 10.5L99.8 19c-1.2-1.2-3-1.2-4.2 0-1.2 1.2-1.2 3 0 4.2l10.5 10.5-10.5 10.5c-1.2 1.2-1.2 3 0 4.2.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9l10.5-10.5 10.5 10.5c.6.6 1.3.9 2.1.9s1.5-.3 2.1-.9c1.2-1.2 1.2-3 0-4.2l-10.5-10.5L125 23.2c1.2-1.2 1.2-3 0-4.2zm0 0M128.1 107.3H92.4c-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3h35.7c1.6 0 3-1.3 3-3s-1.3-3-3-3zm0 0M50 99.4H17.5c-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3H50c1.6 0 3-1.3 3-3s-1.4-3-3-3zm0 0M50 115.2H17.5c-1.6 0-3 1.3-3 3 0 1.6 1.3 3 3 3H50c1.6 0 3-1.3 3-3 0-1.6-1.4-3-3-3zm0 0\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});