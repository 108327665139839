define("ember-paper/components/paper-toast", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "@ember/object/internals", "@ember/application", "ember-paper/templates/components/paper-toast", "ember-paper/utils/invoke-action"], function (_exports, _service, _computed, _component, _object, _runloop, _internals, _application, _paperToast, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * @class PaperToast
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _paperToast.default,
    tagName: '',
    escapeToClose: false,
    swipeToClose: true,
    capsule: false,
    duration: 3000,
    position: 'bottom left',
    left: (0, _object.computed)('position', function () {
      var _this$position$split = this.position.split(' '),
        _this$position$split2 = _slicedToArray(_this$position$split, 2),
        x = _this$position$split2[1];
      return x === 'left';
    }),
    top: (0, _object.computed)('position', function () {
      var _this$position$split3 = this.position.split(' '),
        _this$position$split4 = _slicedToArray(_this$position$split3, 1),
        y = _this$position$split4[0];
      return y === 'top';
    }),
    // Calculate a default that is always valid for the parent of the backdrop.
    wormholeSelector: '#paper-toast-fab-wormhole',
    defaultedParent: (0, _computed.or)('parent', 'wormholeSelector'),
    // Calculate the id of the wormhole destination, setting it if need be. The
    // id is that of the 'parent', if provided, or 'paper-wormhole' if not.
    destinationId: (0, _object.computed)('defaultedParent', function () {
      var config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config.environment === 'test' && !this.parent) {
        return '#ember-testing';
      }
      var parent = this.defaultedParent;
      var parentEle = typeof parent === 'string' ? document.querySelector(parent) : parent;

      // If the parent isn't found, assume that it is an id, but that the DOM doesn't
      // exist yet. This only happens during integration tests or if entire application
      // route is a dialog.
      if (typeof parent === 'string' && parent.charAt(0) === '#') {
        return "#".concat(parent.substring(1));
      } else {
        var id = parentEle.id;
        if (!id) {
          id = "".concat(this.uniqueId, "-parent");
          parentEle.id = id;
        }
        return "#".concat(id);
      }
    }),
    // Find the element referenced by destinationId
    destinationEl: (0, _object.computed)('destinationId', function () {
      return document.querySelector(this.destinationId);
    }),
    constants: (0, _service.inject)(),
    _destroyMessage: function _destroyMessage() {
      if (!this.isDestroyed) {
        (0, _invokeAction.invokeAction)(this, 'onClose');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.uniqueId = (0, _internals.guidFor)(this);
    },
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      document.querySelector(this.destinationId).classList.add('md-toast-animating');
    },
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.duration !== false) {
        _runloop.run.later(this, '_destroyMessage', this.duration);
      }
      if (this.escapeToClose) {
        // Adding Listener to body tag, FIXME
        this._escapeToClose = _runloop.run.bind(this, function (e) {
          if (e.keyCode === _this.get('constants.KEYCODE.ESCAPE') && _this.onClose) {
            _this._destroyMessage();
          }
        });
        document.body.addEventListener('keydown', this._escapeToClose);
      }
      var y = this.top ? 'top' : 'bottom';
      document.querySelector(this.destinationId).classList.add("md-toast-open-".concat(y));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.escapeToClose) {
        document.body.removeEventListener('keydown', this._escapeToClose);
        this._escapeToClose = null;
      }
      var y = this.top ? 'top' : 'bottom';
      document.querySelector(this.destinationId).classList.remove("md-toast-open-".concat(y), 'md-toast-animating');
    }
  });
  _exports.default = _default;
});