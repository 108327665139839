define("@trovedata/sunstone-ui-commons/components/sunstone-pagination/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "F3GrYHob",
    "block": "[[[8,[39,0],null,[[\"@totalItems\",\"@currentIndex\",\"@onIndexChange\"],[[99,1,[\"@totalItems\"]],[28,[37,2],[[33,3]],null],[28,[37,4],[[30,0],\"pageChange\"],null]]],null],[1,\"\\n\"],[6,[39,5],null,[[\"size\",\"condensed\",\"class\"],[14,true,\"layout-margin-left pagination-info\"]],[[\"default\"],[[[[1,\"   \"],[1,[34,6]],[1,\" - \"],[1,[34,7]],[1,\" of \"],[1,[28,[35,8],[[33,9]],[[\"shorten\"],[false]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"pagination-nav\",\"totalPages\",\"dec\",\"currentPage\",\"action\",\"trove-label\",\"currentStartItem\",\"currentEndItem\",\"number\",\"totalResults\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-pagination/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});