define("ember-svg-jar/inlined/cursor-move-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><circle cx=\"12\" cy=\"12\" r=\"2\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M18 16.415L16.586 15l3-3-3-3L18 7.586 22.414 12z\"/></g><g><path fill=\"#757575\" d=\"M6 16.415L1.586 12 6 7.586 7.414 9l-3 3 3 3z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});