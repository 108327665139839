define("@trovedata/sunstone-ui-commons/highcharts-themes/sunstone-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      title: {
        text: null
      }
    },
    yAxis: {
      title: {
        text: null
      }
    }
  };
  _exports.default = _default;
});