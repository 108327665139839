define("@trovedata/sunstone-ui-commons/serializers/enum", ["exports", "@ember/object", "@trovedata/sunstone-ui-commons/serializers/application"], function (_exports, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    normalizeResponse: function normalizeResponse(store, typeClass, payload, id, requestType) {
      if (requestType === 'queryRecord') {
        payload = payload._embedded.enum[0];
      }
      return this._super(store, typeClass, payload, id, requestType);
    },
    serialize: function serialize(snapshot) {
      var _this = this;
      var json = {
        id: snapshot.id
      };
      snapshot.eachAttribute(function (key) {
        (0, _object.set)(json, key, snapshot.attr(key));
      });
      snapshot.eachRelationship(function (key, relationship) {
        if (relationship.kind === 'hasMany') {
          var enumValues = snapshot.hasMany(key);
          if (enumValues) {
            enumValues = enumValues.map(function (item) {
              var adapter = _this.store.adapterFor(item.modelName);
              return adapter.buildURL(item.modelName, item.id);
            });
            (0, _object.set)(json, 'enumValues', enumValues);
          }
        }
      });
      return json;
    }
  });
  _exports.default = _default;
});