define("ember-svg-jar/inlined/logo_v", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\" transform=\"matrix(1.33333 0 0 -1.33333 0 314.96)\"><path d=\"M90.453 236.237H68.35l-22.21-47.643 11.08-24z\" fill=\"#231F20\"/><path d=\"M57.22 164.595l-11.08 24-22.048 47.643H1.912l33.125-71.643z\" fill=\"#8cc63f\"/></g>",
    "attrs": {
      "height": "100",
      "width": "124",
      "viewBox": "0 0 124 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});