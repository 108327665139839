define("@trovedata/sunstone-ui-commons/components/login-form/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ya+ikbAh",
    "block": "[[[10,0],[14,0,\"login\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel panel-default\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,0,\"img-responsive\"],[15,\"src\",[28,[37,0],[[30,0,[\"logoPath\"]]],null]],[12],[13],[1,\"\\n        \"],[10,\"hr\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[35,1],null,[[\"value\",\"type\",\"class\",\"placeholder\"],[[33,2],\"text\",\"username form-control\",\"Username\"]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n          \"],[1,[28,[35,1],null,[[\"value\",\"type\",\"class\",\"placeholder\"],[[33,3],\"password\",\"password form-control\",\"Password\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"panel-footer\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"class\",\"defaultText\",\"pendingText\",\"onButtonClick\"],[\"btn btn-success btn-sm\",\"Login\",\"Logging in..\",[28,[37,5],[[30,0],[33,6],[33,2],[33,3]],null]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"root-url\",\"input\",\"username\",\"password\",\"async-button\",\"action\",\"onLogin\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/login-form/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});