define("@trovedata/sunstone-ui-commons/components/paper-select-options/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Ia4WMxUe",
    "block": "[[[41,[33,1,[\"loading\"]],[[[41,[33,2],[[[1,\"   \"],[1,[34,3]],[1,\"\\n\"]],[]],null]],[]],null],[42,[28,[37,5],[[28,[37,5],[[33,6]],null]],null],null,[[[41,[30,1,[\"groupName\"]],[[[1,\"    \"],[10,\"md-optgroup\"],[14,0,\"ember-power-select-group\"],[15,\"aria-disabled\",[28,[37,7],[[30,1,[\"disabled\"]]],null]],[14,\"role\",\"option\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"ember-power-select-group-name\"],[12],[1,[30,1,[\"groupName\"]]],[13],[1,\"\\n\"],[46,[33,9],null,[[\"options\",\"select\",\"groupIndex\",\"optionsComponent\",\"menuParent\",\"role\",\"class\"],[[28,[37,10],[[30,1,[\"options\"]]],null],[28,[37,10],[[33,1]],null],[28,[37,11],[[33,12],[30,2],\".\"],null],[28,[37,10],[[33,9]],null],[33,13],\"group\",\"ember-power-select-options\"]],[[\"default\"],[[[[1,\"        \"],[18,4,[[30,3],[33,1]]],[1,\"\\n\"]],[3]]]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[6,[39,15],null,[[\"parentComponent\",\"class\",\"aria-selected\",\"selected\",\"aria-disabled\",\"aria-current\",\"data-option-index\",\"role\"],[[33,13],\"ember-power-select-option\",[28,[37,16],[[30,1],[33,1,[\"selected\"]]],null],[52,[28,[37,16],[[30,1],[33,1,[\"selected\"]]],null],\"selected\",[27]],[28,[37,7],[[30,1,[\"disabled\"]]],null],[28,[37,17],[[30,1],[33,1,[\"highlighted\"]]],null],[28,[37,11],[[33,12],[30,2]],null],\"option\"]],[[\"default\"],[[[[1,\"      \"],[18,4,[[30,1],[33,1]]],[1,\"\\n\"]],[]]]]]],[]]]],[1,2]],null],[46,[33,18],null,[[\"select\",\"extra\"],[[28,[37,10],[[33,19]],null],[28,[37,10],[[33,20]],null]]],null],[1,\"\\n\"]],[\"opt\",\"index\",\"option\",\"&default\"],false,[\"if\",\"select\",\"loadingMessage\",\"paper-progress-circular\",\"each\",\"-track-array\",\"options\",\"ember-power-select-true-string-if-present\",\"component\",\"optionsComponent\",\"readonly\",\"concat\",\"groupIndex\",\"menuParent\",\"yield\",\"paper-option\",\"ember-power-select-is-selected\",\"eq\",\"afterOptionsComponent\",\"publicAPI\",\"extra\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-select-options/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});