define("ember-svg-jar/inlined/file-statistic-view-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M5 8.004v7H4v2h5v-5H8v3H7v-7z\"/><path d=\"M2 2.002h10v4h4v3.007h2V4.587L13.414.002H2c-1.103 0-2 .896-2 2v18c0 1.103.897 2 2 2h8v-2H2v-18z\"/><path d=\"M20.167 18.754c.524-.79.833-1.735.833-2.752 0-2.757-2.243-5-5-5s-5 2.243-5 5c0 2.756 2.243 5 5 5 1.018 0 1.963-.31 2.753-.834L22 23.416l1.414-1.414-3.247-3.248zM16 19.002c-1.654 0-3-1.346-3-3 0-1.655 1.346-3 3-3s3 1.345 3 3c0 1.654-1.346 3-3 3z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});