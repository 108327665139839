define("@trovedata/sunstone-ui-commons/components/sunstone-table/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HY6MV1Vu",
    "block": "[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[28,[37,3],null,[[\"header\",\"body\"],[[50,\"sunstone-table-header\",0,null,[[\"columns\",\"sortBy\",\"sortColumn\"],[[33,5],[33,6],[33,7]]]],[50,\"sunstone-table-body\",0,null,[[\"detailRow\",\"columns\",\"rows\",\"rowsInViewport\",\"selectedRow\",\"rowClick\",\"tableActions\"],[[33,8],[33,5],[33,9],[33,10],[28,[37,11],[[33,12]],null],[33,13],[33,14]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,15],null,[[\"@columns\",\"@sortBy\",\"@sortColumn\"],[[99,5,[\"@columns\"]],[99,6,[\"@sortBy\"]],[99,7,[\"@sortColumn\"]]]],null],[1,\"\\n  \"],[8,[39,16],null,[[\"@detailRow\",\"@columns\",\"@rows\",\"@rowsInViewport\",\"@selectedRow\",\"@rowClick\",\"@tableActions\"],[[99,8,[\"@detailRow\"]],[99,5,[\"@columns\"]],[99,9,[\"@rows\"]],[99,10,[\"@rowsInViewport\"]],[28,[37,11],[[33,12]],null],[99,13,[\"@rowClick\"]],[99,14,[\"@tableActions\"]]]],null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"columns\",\"sortBy\",\"columnSort\",\"detailRow\",\"rows\",\"rowsInViewport\",\"mut\",\"selectedRow\",\"rowClick\",\"tableActions\",\"sunstone-table-header\",\"sunstone-table-body\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-table/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});