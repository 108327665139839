define("@trovedata/sunstone-ui-commons/serializers/hal-serializer", ["exports", "ember", "ember-data"], function (_exports, _ember, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  // Reserved keys, per the HAL spec
  var halReservedKeys = ['_embedded', '_links'],
    reservedKeys = halReservedKeys.concat(['meta']),
    keys = Object.keys;
  var
  /*SINGLE_PAYLOAD_REQUEST_TYPES = [
  'findRecord',
  'findBelongsTo',
  'queryRecord',
  'createRecord',
  'deleteRecord',
  'updateRecord'
  ],*/
  COLLECTION_PAYLOAD_REQUEST_TYPES = ['findHasMany', 'findMany', 'query', 'findAll'];

  /**
   * @see ember-data/system/coerce-id
   * @param id
   * @returns {*}
   */
  function coerceId(id) {
    return id == null || id === '' ? null : id + '';
  }
  function halToJSONAPILink(link) {
    var converted,
      linkKeys = keys(link);
    if (linkKeys.length === 1) {
      converted = link.href;
    } else {
      converted = {
        href: link.href,
        meta: {}
      };
      linkKeys.forEach(function (key) {
        if (key !== 'href') {
          converted.meta[key] = link[key];
        }
      });
    }
    return converted;
  }
  function arrayFlatten(array) {
    var flattened = [];
    return flattened.concat.apply(flattened, array);
  }
  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForRelationship: function keyForRelationship(relationshipKey /*, relationshipMeta */) {
      return relationshipKey;
    },
    keyForAttribute: function keyForAttribute(attributeName /*, attributeMeta */) {
      return attributeName;
    },
    keyForLink: function keyForLink(relationshipKey /*, relationshipMeta */) {
      return relationshipKey;
    },
    isSinglePayload: function isSinglePayload(payload, requestType) {
      return COLLECTION_PAYLOAD_REQUEST_TYPES.indexOf(requestType) === -1;
    },
    extractLink: function extractLink(link) {
      return link.href;
    },
    /**
     * Use ember-data 1.13.5+ extractId method
     * @param modelClass
     * @param resourceHash
     * @returns {*}
     */
    extractId: function extractId(modelClass, resourceHash) {
      var primaryKey = this.get('primaryKey');
      var id = resourceHash[primaryKey];
      return coerceId(id);
    },
    extractMeta: function extractMeta(store, requestType, payload, primaryModelClass) {
      var meta = payload.meta || {},
        isSingle = this.isSinglePayload(payload, requestType);
      if (!isSingle) {
        keys(payload).forEach(function (key) {
          if (reservedKeys.indexOf(key) > -1) {
            return;
          }
          meta[key] = payload[key];
          delete payload[key];
        });
        if (payload._links) {
          meta.links = this.extractLinks(primaryModelClass, payload);
        }
      }
      return meta;
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;
      var isSingle = this.isSinglePayload(payload, requestType),
        documentHash = {},
        meta = this.extractMeta(store, requestType, payload, primaryModelClass),
        included = [];
      if (meta) {
        documentHash.meta = meta;
      }
      if (isSingle) {
        documentHash.data = this.normalize(primaryModelClass, payload, included);
      } else {
        documentHash.data = [];
        payload._embedded = payload._embedded || {};
        var normalizedEmbedded = Object.keys(payload._embedded).map(function (embeddedKey) {
          return payload._embedded[embeddedKey].map(function (embeddedPayload) {
            return _this.normalize(primaryModelClass, embeddedPayload, included);
          });
        });
        documentHash.data = arrayFlatten(normalizedEmbedded);
      }
      documentHash.included = included;
      return documentHash;
    },
    normalize: function normalize(primaryModelClass, payload, included) {
      var data;
      if (payload) {
        var attributes = this.extractAttributes(primaryModelClass, payload),
          relationships = this.extractRelationships(primaryModelClass, payload, included);
        data = {
          id: this.extractId(primaryModelClass, payload),
          type: primaryModelClass.modelName
        };
        if (Object.keys(attributes).length > 0) {
          data.attributes = attributes;
        }
        if (Object.keys(relationships).length > 0) {
          data.relationships = relationships;
        }
        if (data.attributes) {
          this.applyTransforms(primaryModelClass, data.attributes);
        }
      }
      return data;
    },
    extractLinks: function extractLinks(primaryModelClass, payload) {
      var links;
      if (payload._links) {
        links = {};
        Object.keys(payload._links).forEach(function (link) {
          links[link] = halToJSONAPILink(payload._links[link]);
        });
      }
      return links;
    },
    extractAttributes: function extractAttributes(primaryModelClass, payload) {
      var _this2 = this;
      var payloadKey,
        attributes = {};
      primaryModelClass.eachAttribute(function (attributeName, attributeMeta) {
        payloadKey = _this2.keyForAttribute(attributeName, attributeMeta);
        if (!payload.hasOwnProperty(payloadKey)) {
          return;
        }
        attributes[attributeName] = payload[payloadKey];
        delete payload[payloadKey];
      });
      if (payload._links) {
        attributes.links = this.extractLinks(primaryModelClass, payload);
      }
      return attributes;
    },
    extractRelationship: function extractRelationship(relationshipModelClass, payload, included) {
      if (_ember.default.isNone(payload)) {
        return undefined;
      }
      var relationshipModelName = relationshipModelClass.modelName,
        relationship;
      if (_ember.default.typeOf(payload) === 'object') {
        relationship = {
          id: coerceId(this.extractId({}, payload))
        };
        if (relationshipModelName) {
          relationship.type = this.modelNameFromPayloadKey(relationshipModelName);
          included.push(this.normalize(relationshipModelClass, payload, included));
        }
      } else {
        relationship = {
          id: coerceId(payload),
          type: relationshipModelName
        };
      }
      return relationship;
    },
    extractRelationships: function extractRelationships(primaryModelClass, payload, included) {
      var _this3 = this;
      var relationships = {},
        embedded = payload._embedded,
        links = payload._links;
      if (embedded || links) {
        primaryModelClass.eachRelationship(function (key, relationshipMeta) {
          var relationship,
            relationshipKey = _this3.keyForRelationship(key, relationshipMeta),
            linkKey = _this3.keyForLink(key, relationshipMeta);
          if (embedded && embedded.hasOwnProperty(relationshipKey)) {
            var data,
              relationModelClass = _this3.store.modelFor(relationshipMeta.type);
            if (relationshipMeta.kind === 'belongsTo') {
              data = _this3.extractRelationship(relationModelClass, embedded[relationshipKey], included);
            } else if (relationshipMeta.kind === 'hasMany') {
              data = embedded[relationshipKey].map(function (item) {
                return _this3.extractRelationship(relationModelClass, item, included);
              });
            }
            relationship = {
              data: data
            };
          }
          if (links && links.hasOwnProperty(linkKey)) {
            relationship = relationship || {};
            var link = links[linkKey],
              useRelated = !relationship.data;
            relationship.links = _defineProperty({}, useRelated ? 'related' : 'self', _this3.extractLink(link));
          }
          if (relationship) {
            relationships[key] = relationship;
          }
        }, this);
      }
      return relationships;
    }
  });
  _exports.default = _default;
});