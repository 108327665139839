define("@trovedata/sunstone-ui-commons/utils/color-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.interpolateColor = interpolateColor;
  function interpolateColor(color1, color2, factor) {
    var _color1$match, _color2$match;
    var color1Arr = (_color1$match = color1.match(/\d+/g)) === null || _color1$match === void 0 ? void 0 : _color1$match.map(Number);
    var color2Arr = (_color2$match = color2.match(/\d+/g)) === null || _color2$match === void 0 ? void 0 : _color2$match.map(Number);
    if (arguments.length < 3) {
      factor = 0.5;
    }
    var result = color1Arr === null || color1Arr === void 0 ? void 0 : color1Arr.slice();
    if (result && color1Arr && color2Arr) {
      for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2Arr[i] - color1Arr[i]));
      }
    }
    return result ? "rgb(".concat(result.join(','), ")") : undefined;
  }
  ;
});