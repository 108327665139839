define("@trovedata/sunstone-ui-commons/components/sunstone-table/component", ["exports", "@ember/runloop", "@ember/array", "@ember/component", "@ember/object", "jquery", "@trovedata/sunstone-ui-commons/components/sunstone-table/template"], function (_exports, _runloop, _array, _component, _object, _jquery, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-observers */
  /* eslint-disable ember/no-jquery */
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'table',
    classNameBindings: ['isScrollable:table-scrollable', 'tableClassNames'],
    classNames: ['sunstone-table', 'table'],
    tableClassNames: '',
    columns: (0, _array.A)(),
    rows: (0, _array.A)(),
    rowsInViewport: 10,
    _rowHeight: 0,
    init: function init() {
      this._super.apply(this, arguments);
      this._boundResizeColumns = this._resizeColumns.bind(this);
      this.resize = this.resize.bind(this);
    },
    resize: function resize() {
      var windowWidth = this.windowWidth;
      var windowHeight = this.windowHeight;
      var currentWidth = window.innerWidth;
      var currentHeight = window.innerHeight;
      this.set('columnWidths', undefined);
      if ("".concat(windowWidth) !== "".concat(currentWidth) || "".concat(windowHeight) !== "".concat(currentHeight)) {
        this._boundResizeColumns();
        this.set('windowWidth', currentWidth);
        this.set('windowHeight', currentHeight);
      }
    },
    columnObserver: (0, _object.observer)('columns.[]', function () {
      this._boundResizeColumns();
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(window).on('resize', this.resize);
      this._resizeColumns();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      (0, _jquery.default)(window).off('resize', this.resize);
    },
    _resizeColumns: function _resizeColumns() {
      var _this = this;
      if (this.resizable) {
        var scheduled = function scheduled() {
          var saveResize = function saveResize() {
            var $columns = _this.$('tr > th');
            var columnWidths = (0, _array.A)();
            $columns.each(function (i, column) {
              columnWidths.push(_this.$(column).css('width'));
            });
            (0, _object.set)(_this, 'columnWidths', columnWidths);
          };
          _this.set('saveResize', saveResize.bind(_this));
          _this.$().colResizable({
            onResize: function onResize() {
              _this.saveResize();
              _this._boundResizeColumns();
            }
          });
        };
        (0, _runloop.scheduleOnce)('afterRender', scheduled);
      }
      var nextScheduled = function nextScheduled() {
        if (_this.resizable) {
          _this.$().colResizable({
            disable: true
          });
        }
        var columns = _this.columns;
        var columnWidths = _this.columnWidths;
        if (columnWidths) {
          _this.$('tr th').each(function (i, column) {
            (0, _jquery.default)(column).css({
              width: columnWidths.objectAt(i)
            });
          });
          _this.$('tr td').each(function (i, column) {
            var value = columnWidths.objectAt(i % columns.length);
            (0, _jquery.default)(column).css({
              maxWidth: value,
              minWidth: value,
              width: value
            });
          });
        } else {
          var _this$element;
          _this.$('th, td').css({
            width: 'initial',
            minWidth: 'initial',
            maxWidth: 'initial'
          });
          var leftoverWidth = (_this$element = _this.element) === null || _this$element === void 0 ? void 0 : _this$element.clientWidth;
          var fixedWidthColumns = [];
          var dynamicWidthColumns = [];
          var columnWidth;
          columns.forEach(function (col, i) {
            if ((0, _object.get)(col, 'fixedWidth')) {
              fixedWidthColumns.push(i);
            } else {
              dynamicWidthColumns.push(i);
            }
          });
          fixedWidthColumns.forEach(function (i) {
            var maxWidth = 0;
            var $cells = _this.$("thead th:nth-child(".concat(i + 1, "), tbody td:nth-child(").concat(i + 1, ")"));
            $cells.each(function (i, cell) {
              var $cell = (0, _jquery.default)(cell);
              $cell.css('display', 'inline-block');
              $cell.css('width', 'initial');
              var width = (0, _jquery.default)(cell).outerWidth();
              $cell.css('display', '');
              maxWidth = width > maxWidth ? width : maxWidth;
            });
            $cells.css({
              width: maxWidth
            });
            leftoverWidth -= maxWidth;
          });
          columnWidth = leftoverWidth / dynamicWidthColumns.length;
          dynamicWidthColumns.forEach(function (i) {
            _this.$("thead th:nth-child(".concat(i + 1, "), tbody td:nth-child(").concat(i + 1, ")")).css({
              width: columnWidth
            });
          });
        }
        // if (!this.configured) {
        if (_this.resizable) {
          _this.$().colResizable({
            onResize: function onResize() {
              _this.saveResize();
              _this._boundResizeColumns();
            }
          });
        }
      };
      (0, _runloop.scheduleOnce)('afterRender', nextScheduled);
    }
  });
  _exports.default = _default;
});