define("ember-css-transitions/utils/transition-utils", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.computeTimeout = computeTimeout;
  _exports.nextTick = nextTick;
  _exports.sleep = sleep;
  /**
   * Function that returns a promise that resolves after after DOM changes
   * have been flushed and after a browser repaint.
   *
   * @function nextTick
   * @export nextTick
   * @return {Promise} the promise
   */
  function nextTick() {
    return new _rsvp.Promise(function (resolve) {
      window.requestAnimationFrame(function () {
        return resolve();
      });
    });
  }

  /**
   * Function that returns a promise that resolves after `ms` milliseconds.
   *
   * @function sleep
   * @export sleep
   * @param {number} ms number of milliseconds after which the promise will resolve
   * @return {Promise} the promise that will resolve after `ms` milliseconds
   */
  function sleep(ms) {
    return new _rsvp.Promise(function (resolve) {
      (0, _runloop.later)(function () {
        return resolve();
      }, ms);
    });
  }

  /**
   * Computes the time a css animation will take.
   * Uses `getComputedStyle` to get durations and delays.
   *
   * @function computeTimeout
   * @export computeTimeout
   * @param {Element} element element used calculate the animation duration based on `getComputedStyle`
   * @return {number} the calculated animation duration + delay
   */
  function computeTimeout(element) {
    var _window$getComputedSt = window.getComputedStyle(element),
      transitionDuration = _window$getComputedSt.transitionDuration,
      transitionDelay = _window$getComputedSt.transitionDelay,
      animationDuration = _window$getComputedSt.animationDuration,
      animationDelay = _window$getComputedSt.animationDelay,
      animationIterationCount = _window$getComputedSt.animationIterationCount;

    // `getComputedStyle` returns durations and delays in the Xs format.
    // Conveniently if `parseFloat` encounters a character other than a sign (+ or -),
    // numeral (0-9), a decimal point, or an exponent, it returns the value up to that point
    // and ignores that character and all succeeding characters.

    var maxDelay = Math.max(parseFloat(animationDelay), parseFloat(transitionDelay));
    var maxDuration = Math.max(parseFloat(animationDuration) * parseFloat(animationIterationCount), parseFloat(transitionDuration));
    return (maxDelay + maxDuration) * 1000;
  }
});