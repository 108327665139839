define("ember-svg-jar/inlined/building-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M.001.002h24v24h-24z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M21 1.999H3c-.552 0-1 .449-1 1v18a1 1 0 001 1h18a1 1 0 001-1v-18a1 1 0 00-1-1zm-10 18v-2h2v2h-2zm9 0h-5v-3c0-.551-.448-1-1-1h-4c-.552 0-1 .449-1 1v3H4v-16h16v16z\"/><path d=\"M9 6.999h2v2H9zM13 6.999h2v2h-2zM17 6.999h2v2h-2zM9 10.999h2v2H9zM5 6.999h2v2H5zM5 10.999h2v2H5zM13 10.999h2v2h-2zM17 10.999h2v2h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});