define("ember-svg-jar/inlined/cloudy-thunder-storm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M15 15h-3v-4l-4 5h3v4z\"/><path d=\"M15 3a6.961 6.961 0 00-6.437 4.254A4.959 4.959 0 007 7c-2.757 0-5 2.243-5 5 0 1.785.962 3.446 2.511 4.337l.997-1.734A3.012 3.012 0 014 12c0-1.654 1.346-3 3-3 .569 0 1.129.167 1.619.482a.998.998 0 001.516-.614A4.975 4.975 0 0115 5c2.757 0 5 2.243 5 5a5.02 5.02 0 01-2.505 4.334l1 1.732A7.025 7.025 0 0022 10c0-3.859-3.14-7-7-7z\"/><path transform=\"rotate(-153.419 6 19)\" d=\"M5.25 16.764h1.5v4.472h-1.5z\"/><path transform=\"rotate(-153.419 16 19)\" d=\"M15.25 16.764h1.5v4.472h-1.5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});