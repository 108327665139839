define("@trovedata/sunstone-ui-commons/models/model-session-statistic", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    modelSessionId: (0, _model.belongsTo)('model-session', {
      inverse: null
    }),
    dataFieldId: (0, _model.belongsTo)('data-field', {
      inverse: null
    }),
    dataFieldStatistic: (0, _model.belongsTo)('data-field-statistic', {
      inverse: null
    }),
    modelSession: (0, _computed.alias)('modelSessionId'),
    dataField: (0, _computed.alias)('dataFieldId')
  });
  _exports.default = _default;
});