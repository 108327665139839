define("ember-svg-jar/inlined/st-minor-increase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g filter=\"url(#filter0_d_264_2850)\"><rect x=\"4\" width=\"32\" height=\"32\" rx=\"16\" fill=\"#F05D2A\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.586 6.586a2 2 0 012.828 0l6 6a2 2 0 11-2.828 2.828L22 12.828V23a2 2 0 11-4 0V12.828l-2.586 2.586a2 2 0 11-2.828-2.828l6-6z\" fill=\"#2C3134\"/></g><defs><filter id=\"filter0_d_264_2850\" x=\"0\" y=\"0\" width=\"40\" height=\"40\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/><feOffset dy=\"4\"/><feGaussianBlur stdDeviation=\"2\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_264_2850\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_264_2850\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});