define("ember-svg-jar/inlined/data-download-6", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .004h24v24H0z\"/></g><g id=\"Filled_Icons\"><g fill=\"#757575\"><path d=\"M16 11.001v4h3l-7 7-7-7h3v-4zM8 6.001h8v4H8zM8 2.001h8v3H8z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});