define("ember-svg-jar/inlined/data-download-8", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .005h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M17.707 10.708l-1.414-1.414L13 12.587V2.001h-2v10.586L7.707 9.294l-1.414 1.414L12 16.415zM18 18.001v2H6v-2H4v2c0 1.102.896 2 2 2h12c1.104 0 2-.898 2-2v-2h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});