define("@trovedata/sunstone-ui-commons/components/paper-breadcrumb/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/paper-breadcrumb/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin"], function (_exports, _component, _template, _troveColorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_troveColorMixin.default, {
    layout: _template.default,
    tagName: 'paper-breadcrumb',
    classNames: ['paper-breadcrumb'],
    classNameBindings: ['active:md-active', 'hoverable:hoverable'],
    attributeBindings: ['href', 'target'],
    init: function init() {
      this._super.apply(this, arguments);
      if (this.onClick) {
        this.set('hoverable', true);
      }
      if (this.href) {
        this.setProperties({
          hoverable: true,
          tagName: 'a'
        });
      }
    },
    click: function click(event) {
      if (this.onClick) {
        this.onClick(event);
      }

      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.bubbles;
    }
  });
  _exports.default = _default;
});