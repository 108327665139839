define("@trovedata/sunstone-ui-commons/models/segment-set", ["exports", "@ember/object/computed", "@ember/template", "@ember/utils", "@ember/object", "@ember/service", "@ember-data/model"], function (_exports, _computed, _template, _utils, _object, _service, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  var _default = _model.default.extend({
    customerData: (0, _service.inject)(),
    currentVersion: (0, _computed.alias)('customerData.currentVersion'),
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    profileId: (0, _model.attr)('number'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    defaultSegmentSet: (0, _model.attr)('boolean'),
    executed: (0, _model.attr)('boolean'),
    executionDuration: (0, _model.attr)('number'),
    percentComplete: (0, _model.attr)('number'),
    completeTime: (0, _model.attr)('basic-date'),
    reusable: (0, _model.attr)('boolean'),
    isReuseable: (0, _computed.alias)('reusable'),
    isSystemWidget: (0, _model.attr)('boolean'),
    matrix: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    segmentsSize: (0, _model.attr)('number'),
    status: (0, _model.attr)('string'),
    dataVersionId: (0, _model.attr)('number'),
    isOutDated: (0, _object.computed)('dataVersionId', 'currentVersion', function () {
      return this.dataVersionId !== this.currentVersion;
    }),
    displayStatus: (0, _object.computed)('status', 'isOutDated', 'executed', function () {
      if (this.isOutDated) {
        return 'OUT-DATED';
      } else if (this.executed) {
        return this.status;
      } else {
        return null;
      }
    }),
    // eslint-disable-next-line ember/require-return-from-computed
    statusColor: (0, _object.computed)('status', 'isOutDated', 'executed', function () {
      if (this.isOutDated) {
        return 'alert';
      } else if (this.executed) {
        if (this.status === 'COMPLETE') {
          return 'accent';
        }
        if (this.status === 'ERROR') {
          return 'warn';
        }
        if (this.status === 'PENDING') {
          return 'info';
        }
      } else {
        return null;
      }
    }),
    statusBorder: (0, _object.computed)('statusColor', function () {
      return (0, _template.htmlSafe)("segment-status-border-".concat(this.statusColor));
    }),
    segments: (0, _model.hasMany)('segment'),
    checkSegments: (0, _object.computed)('segments.@each.name', function () {
      var haveNames = !this.segments.any(function (segment) {
        return (0, _utils.isEmpty)((0, _object.get)(segment, 'name'));
      });
      var namesUnique = this.segments.uniqBy('name').length === (0, _object.get)(this, 'segments.length');
      return haveNames && namesUnique;
    })
  });
  _exports.default = _default;
});