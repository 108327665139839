define("@trovedata/sunstone-ui-commons/helpers/duration", ["exports", "@ember/component/helper", "moment"], function (_exports, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var order = ['d', 'h', 'm', 's'];
  var rules = [86400000, 3600000, 60000, 1000];
  var duration = function duration(_ref, _ref2) {
    var _this = this;
    var _ref3 = _slicedToArray(_ref, 2),
      startTime = _ref3[0],
      endTime = _ref3[1];
    var _ref2$interval = _ref2.interval,
      interval = _ref2$interval === void 0 ? true : _ref2$interval;
    var difference = 0;
    var result = '';
    if (!startTime) {
      return null;
    }
    if (!_moment.default.isMoment(startTime)) {
      startTime = (0, _moment.default)(startTime);
    }
    if (endTime) {
      if (!_moment.default.isMoment(endTime)) {
        endTime = (0, _moment.default)(endTime);
      }
      difference = endTime.diff(startTime);
    } else {
      difference = (0, _moment.default)().diff(startTime);
    }
    if (difference < 1000) {
      return '0s';
    }
    order.forEach(function (key, index) {
      var denominator = rules[index];
      var value = Math.floor(difference / denominator);
      difference = difference - value * denominator;
      if (value) {
        result = "".concat(result, " ").concat(value).concat(key);
      }
    });
    if (!endTime && interval) {
      setTimeout(function () {
        _this.recompute();
      }, parseInt(1000, 10));
    }
    return result;
  };
  var _default = _helper.default.extend({
    compute: duration
  });
  _exports.default = _default;
});