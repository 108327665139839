define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "commons": {
      "analyticsTitle": "Analytics Runtime",
      "catalogTitle": "Data Catalog",
      "connectionsTitle": "Data Connections",
      "darkTheme": "Dark",
      "framesTitle": "Data Frames",
      "lastLogin": "Last Login",
      "lightTheme": "Light",
      "logout": "Logout",
      "troveDataTitle": "TROVE Data"
    }
  }]];
  _exports.default = _default;
});