define("@trovedata/sunstone-ui-commons/helpers/lowercase", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lowercase = lowercase;
  function lowercase(params /*, hash*/) {
    return String(params[0]).toLowerCase();
  }
  var _default = (0, _helper.helper)(lowercase);
  _exports.default = _default;
});