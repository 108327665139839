define("@trovedata/sunstone-ui-commons/models/property-set", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateBy: (0, _model.attr)('number'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    dataSource: (0, _model.attr)('string'),
    propsetType: (0, _model.attr)('string'),
    orgId: (0, _model.attr)('number'),
    propertySetValues: (0, _model.hasMany)('property-set-value')
  });
  _exports.default = _default;
});