define("@trovedata/sunstone-ui-commons/adapters/app", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      type = 'application';
      return this._super(type);
    }
  });
  _exports.default = _default;
});