define("@trovedata/sunstone-ui-commons/components/paper-select-multiple-trigger/component", ["exports", "@ember/object/computed", "@ember/object", "@ember/array", "@ember/component", "@trovedata/sunstone-ui-commons/components/paper-select-multiple-trigger/template"], function (_exports, _computed, _object, _array, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    selectedItems: (0, _computed.alias)('select.selected'),
    selectedItemsText: (0, _object.computed)('selectedItems.[]', function () {
      var displayText = null;
      var selectedItems = (0, _array.A)((0, _object.get)(this, 'selectedItems'));
      if ((0, _object.get)(selectedItems, 'length')) {
        var _get, _get2;
        var searchField = (0, _object.get)(this, 'searchField');
        var value = searchField && (_get = (0, _object.get)(selectedItems, "firstObject")) !== null && _get !== void 0 && _get[searchField] ? (_get2 = (0, _object.get)(selectedItems, "firstObject")) === null || _get2 === void 0 ? void 0 : _get2[searchField] : (0, _object.get)(selectedItems, 'firstObject');
        displayText = (0, _object.get)(selectedItems, 'length') === 1 ? value : "".concat((0, _object.get)(selectedItems, 'length'), " items selected");
      }
      return displayText;
    })
  });
  _exports.default = _default;
});