define('@trovedata/paper-time-inputs/components/paper-time/component', ['exports', '@trovedata/paper-time-inputs/components/paper-time/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    datetime: null,
    use24Hour: false,

    hour: Ember.computed('datetime', function () {
      var datetime = Ember.get(this, 'datetime');

      return datetime ? datetime.hour() : null;
    }),

    displayHour: Ember.computed('hour', 'use24Hour', function () {
      var hour = Ember.get(this, 'hour');
      var use24Hour = Ember.get(this, 'use24Hour');

      if (!hour) {
        hour = 12;
      }

      if (use24Hour) {
        return hour;
      } else {
        var displayHour = hour % 12;

        displayHour = displayHour > 0 ? displayHour : 12;

        return displayHour < 10 ? '0' + displayHour : displayHour;
      }
    }),

    minute: Ember.computed('datetime', function () {
      var datetime = Ember.get(this, 'datetime');

      return datetime ? datetime.minute() : null;
    }),

    displayMinute: Ember.computed('minute', function () {
      var minute = Ember.get(this, 'minute');

      if (!minute) {
        minute = 0;
      }

      return minute < 10 ? '0' + minute : minute;
    }),

    meridiem: Ember.computed('hour', function () {
      return Ember.get(this, 'hour') < 12 ? 'AM' : 'PM';
    }),

    hours: Ember.computed(function () {
      var hours = [];

      for (var i = 1; i <= 12; i++) {
        hours.push(i);
      }

      return hours;
    }),

    minutes: ['0', '15', '30', '45'],

    actions: {
      setHour: function setHour(direction) {
        var hour = parseInt(Ember.get(this, 'hour'));
        var datetime = Ember.get(this, 'datetime').clone();

        datetime.hour(hour + direction);

        Ember.get(this, 'onChange')(datetime);
      },
      setMinute: function setMinute(direction) {
        var minute = Ember.get(this, 'minute');
        var datetime = Ember.get(this, 'datetime').clone();

        datetime.minute(minute + direction);

        Ember.get(this, 'onChange')(datetime);
      },
      cycleMeridiem: function cycleMeridiem() {
        var currentMeridiem = Ember.get(this, 'meridiem');
        var datetime = Ember.get(this, 'datetime').clone();
        var method = currentMeridiem === 'AM' ? 'add' : 'subtract';

        datetime[method](12, 'hours');

        Ember.get(this, 'onChange')(datetime);
      },
      showHours: function showHours() {
        Ember.set(this, 'showHours', true);
      },
      showMinutes: function showMinutes() {
        Ember.set(this, 'showMinutes', true);
      },
      selectHour: function selectHour(hour) {
        Ember.set(this, 'showHours', false);

        var datetime = Ember.get(this, 'datetime').clone();

        datetime.hour(hour);

        Ember.get(this, 'onChange')(datetime);
      },
      selectMinute: function selectMinute(minute) {
        Ember.set(this, 'showMinutes', false);

        var datetime = Ember.get(this, 'datetime').clone();

        datetime.minute(minute);

        Ember.get(this, 'onChange')(datetime);
      }
    }
  });
});