define("ember-svg-jar/utils/make-svg", ["exports", "@ember/utils", "@ember/template"], function (_exports, _utils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createAccessibilityElements = createAccessibilityElements;
  _exports.createAriaLabel = createAriaLabel;
  _exports.default = makeSvg;
  _exports.formatAttrs = formatAttrs;
  _exports.inlineSvgFor = inlineSvgFor;
  _exports.sanitizeAttrs = sanitizeAttrs;
  _exports.symbolUseFor = symbolUseFor;
  var accessibilityElements = ['title', 'desc'];
  var ESC = {
    '"': '&quot;',
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;'
  };
  function matcher(char) {
    return ESC[char];
  }
  function escapeText(text) {
    if (typeof text !== 'string') {
      return '';
    }
    if (text.indexOf('>') > -1 || text.indexOf('<') > -1 || text.indexOf('&') > -1 || text.indexOf('"') > -1) {
      return text.replace(/[&"<>]/g, matcher);
    }
    return text;
  }
  function sanitizeAttrs(attrs) {
    var attrsCopy = Object.assign({}, attrs);
    Object.keys(attrsCopy).forEach(function (key) {
      attrsCopy[key] = escapeText(attrsCopy[key]);
    });
    return attrsCopy;
  }
  function createAccessibilityElements(attrs) {
    var sanitizedAttrs = sanitizeAttrs(attrs);
    var title = sanitizedAttrs.title,
      desc = sanitizedAttrs.desc;
    if (!title && !desc) {
      return '';
    }
    return accessibilityElements.reduce(function (elements, tag) {
      if (sanitizedAttrs[tag]) {
        return elements.concat("<".concat(tag, " id=\"").concat(tag, "\">").concat(sanitizedAttrs[tag], "</").concat(tag, ">"));
      }
      return elements;
    }, '');
  }
  function createAriaLabel(attrs) {
    var title = attrs.title,
      desc = attrs.desc;
    if (!title && !desc) {
      return '';
    }
    return "aria-labelledby=\"".concat(accessibilityElements.filter(function (tag) {
      return attrs[tag];
    }).join(' '), "\"");
  }
  function formatAttrs(attrs) {
    return Object.keys(attrs).filter(function (attr) {
      return !accessibilityElements.includes(attr);
    }).map(function (key) {
      return !(0, _utils.isNone)(attrs[key]) && "".concat(key, "=\"").concat(attrs[key], "\"");
    }).filter(function (attr) {
      return attr;
    }).join(' ');
  }
  function symbolUseFor(assetId) {
    var attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return "<svg ".concat(formatAttrs(attrs)).concat(createAriaLabel(attrs), "><use xlink:href=\"").concat(assetId, "\" />").concat(createAccessibilityElements(attrs), "</svg>");
  }
  function inlineSvgFor(assetId, getInlineAsset) {
    var attrs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var asset = getInlineAsset(assetId);
    if (!asset) {
      // eslint-disable-next-line no-console
      console.warn("ember-svg-jar: Missing inline SVG for ".concat(assetId));
      return;
    }
    var svgAttrs = asset.attrs ? Object.assign({}, asset.attrs, attrs) : attrs;
    var size = attrs.size;
    if (size) {
      svgAttrs.width = parseFloat(svgAttrs.width) * size || svgAttrs.width;
      svgAttrs.height = parseFloat(svgAttrs.height) * size || svgAttrs.height;
      delete svgAttrs.size;
    }
    return "<svg ".concat(formatAttrs(svgAttrs)).concat(createAriaLabel(attrs), ">").concat(createAccessibilityElements(attrs)).concat(asset.content, "</svg>");
  }
  function makeSvg(assetId) {
    var attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var getInlineAsset = arguments.length > 2 ? arguments[2] : undefined;
    if (!assetId) {
      // eslint-disable-next-line no-console
      console.warn('ember-svg-jar: asset name should not be undefined or null');
      return;
    }
    var isSymbol = assetId.lastIndexOf('#', 0) === 0;
    var svg = isSymbol ? symbolUseFor(assetId, attrs) : inlineSvgFor(assetId, getInlineAsset, attrs);
    return (0, _template.htmlSafe)(svg);
  }
});