define("@trovedata/sunstone-ui-commons/models/sunstone-report", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    fileName: (0, _model.attr)('string'),
    destinationDir: (0, _model.attr)('string', {
      defaultValue: ''
    }),
    reportByteArray: (0, _model.attr)('string'),
    parameters: (0, _model.hasMany)('sunstone-report-parameter', {
      async: false
    }),
    commands: (0, _model.hasMany)('sunstone-report-command', {
      async: false
    }),
    bytes: (0, _computed.alias)('reportByteArray')
  });
  _exports.default = _default;
});