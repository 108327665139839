define("ember-svg-jar/inlined/business-graph-line-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Filled_Icons\"><g fill=\"#757575\"><path d=\"M5 3H3v17a1 1 0 001 1h17v-2H5V3z\"/><path d=\"M19 9v4c0 1.102-.896 2-2 2s-2-.898-2-2V9c0-2.205-1.795-4-4-4S7 6.795 7 9v4h2V9c0-1.102.896-2 2-2s2 .898 2 2v4c0 2.207 1.795 4 4 4s4-1.793 4-4V9h-2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});