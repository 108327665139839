define("@trovedata/sunstone-ui-commons/adapters/enum", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord() {
      return this._buildURL('enum');
    },
    urlForUpdateRecord: function urlForUpdateRecord(id) {
      return "".concat(this._buildURL('enum'), "/").concat(id);
    }
  });
  _exports.default = _default;
});