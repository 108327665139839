define("@trovedata/sunstone-ui-commons/adapters/fuse-job", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      type = 'modelInstance';
      return this._super(type);
    },
    namespace: 'trove-analytic-service'
  });
  _exports.default = _default;
});