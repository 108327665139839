define("@trovedata/sunstone-ui-commons/models/boundry-filters", ["exports", "@ember-data/model", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes"], function (_exports, _model, _fragment, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fragment.default.extend({
    state: (0, _model.attr)('number'),
    countyFilters: (0, _attributes.array)('string'),
    zipFilters: (0, _attributes.array)('string')
  });
  _exports.default = _default;
});