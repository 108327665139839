define("@trovedata/sunstone-ui-commons/models/datasource-mapping", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    weight: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    mappingType: (0, _model.attr)('string', {
      defaultValue: 'KEY'
    }),
    field1: (0, _model.belongsTo)('data-field', {
      async: true
    }),
    field2: (0, _model.belongsTo)('data-field', {
      async: true
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    validations: {
      weight: {
        presence: true,
        numericality: true
      },
      mappingType: {
        presence: true
      },
      field1: true,
      field2: true
    }
  });
  _exports.default = _default;
});