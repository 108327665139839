define("ember-paper/components/paper-card-actions", ["exports", "@ember/component", "ember-paper/templates/components/paper-card-actions"], function (_exports, _component, _paperCardActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperCardActions
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    layout: _paperCardActions.default,
    tagName: 'md-card-actions',
    classNameBindings: ['defaultClasses'],
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // if the consumer didn't set layout classes, we should set them
      // to the default (layout = row, layout align = end center)
      var providedClasses = this['class'];
      if (!providedClasses || providedClasses.indexOf('layout-') === -1) {
        this.set('defaultClasses', 'layout-row layout-align-end-center');
      }
    }
  });
  _exports.default = _default;
});