define("ember-paper/templates/components/paper-radio-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZVSEfmyU",
    "block": "[[[10,0],[14,0,\"md-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-off\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"md-on\"],[12],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@center\",\"@fitRipple\"],[true,true]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"  \"],[10,0],[14,0,\"md-label\"],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"md-label\"],[12],[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[34,4]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"paper-ripple\",\"if\",\"has-block\",\"yield\",\"label\"]]",
    "moduleName": "ember-paper/templates/components/paper-radio-base.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});