define("@trovedata/sunstone-ui-commons/components/sunstone-results-table-header/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/sunstone-results-table-header/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'header'
  });
  _exports.default = _default;
});