define("ember-svg-jar/inlined/server-block-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M10 17.974A21.28 21.28 0 019 18c-4.547 0-7-1.42-7-2v-1.4c1.736.922 4.374 1.4 7 1.4.334 0 .668-.008 1-.023v-2.003A21.28 21.28 0 019 14c-4.547 0-7-1.42-7-2v-1.4c1.736.922 4.374 1.4 7 1.4.334 0 .668-.008 1-.023V9.974A21.28 21.28 0 019 10c-4.547 0-7-1.42-7-2V6.6C3.736 7.522 6.374 8 9 8s5.264-.478 7-1.4V8l-.001.004L18 8.002V4c0-2.626-4.527-4-9-4S0 1.374 0 4v12c0 2.626 4.527 4 9 4 .334 0 .668-.01 1-.025v-2.001zM9 2c4.547 0 7 1.42 7 2s-2.453 2-7 2-7-1.42-7-2 2.453-2 7-2z\"/><path d=\"M18 12c-3.309 0-6 2.691-6 6 0 3.31 2.691 6 6 6s6-2.69 6-6c0-3.309-2.691-6-6-6zm0 2c.739 0 1.424.215 2.02.566l-5.453 5.452A3.94 3.94 0 0114 18c0-2.205 1.795-4 4-4zm0 8a3.965 3.965 0 01-2.02-.566l5.453-5.453A3.95 3.95 0 0122 18c0 2.205-1.795 4-4 4z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});