define("@trovedata/sunstone-ui-commons/models/sunstone-user-setting", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    value: (0, _model.attr)('string'),
    sunstoneSetting: (0, _model.belongsTo)('sunstone-setting', {
      inverse: null
    }),
    userId: (0, _model.attr)('number')
  });
  _exports.default = _default;
});