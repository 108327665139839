define('@trovedata/paper-time-inputs/components/paper-date-input/component', ['exports', '@trovedata/paper-time-inputs/components/paper-date-input/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    date: Ember.computed.alias('value'),
    format: 'L',
    position: 'target bottom',

    actions: {
      selectDate: function selectDate(_ref) {
        var moment = _ref.moment;

        var date = Ember.get(this, 'date');

        if (date) {
          date = date.clone();

          date.set({
            month: moment.month(),
            date: moment.date(),
            year: moment.year()
          });
        } else {
          date = moment;
        }

        Ember.get(this, 'onChange')(date);
      }
    }
  });
});