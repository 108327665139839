define("ember-svg-jar/inlined/file-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M16.414 1H9c-1.103 0-2 .898-2 2v14c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V5.586L16.414 1zm2.587 16H9V3h6v4h4l.001 10z\"/><path d=\"M5 5H3v16c0 1.103.897 2 2 2h12v-2H5V5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});