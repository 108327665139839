define("ember-paper/services/paper-sidenav", ["exports", "@ember/service", "@ember/debug"], function (_exports, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperSidenav
   * @extends Ember.Service
   */
  var _default = _service.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this._sidenavs = {};
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      delete this._sidenavs;
    },
    register: function register(name, sidenav) {
      if (!this._sidenavs[name]) {
        this._sidenavs[name] = [];
      }
      this._sidenavs[name].push({
        name: name,
        sidenav: sidenav
      });
    },
    unregister: function unregister(name, sidenav) {
      (false && !(this._sidenavs[name] && this._sidenavs[name].length) && (0, _debug.assert)("You tried to unregister a sidenav named '".concat(name, "' but no such sidenav is registered"), this._sidenavs[name] && this._sidenavs[name].length));
      var sidenavs = this._sidenavs[name] || [];
      this._sidenavs[name] = sidenavs.filter(function (s) {
        return s.sidenav !== sidenav;
      });
    },
    open: function open() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
      (false && !(this._sidenavs[name] && this._sidenavs[name].length) && (0, _debug.assert)("You tried to open a sidenav named '".concat(name, "' but no such sidenav is registered"), this._sidenavs[name] && this._sidenavs[name].length));
      var sidenavs = this._sidenavs[name] || [];
      sidenavs.forEach(function (s) {
        return s.sidenav.open();
      });
    },
    close: function close() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
      (false && !(this._sidenavs[name] && this._sidenavs[name].length) && (0, _debug.assert)("You tried to close a sidenav named '".concat(name, "' but no such sidenav is registered"), this._sidenavs[name] && this._sidenavs[name].length));
      var sidenavs = this._sidenavs[name] || [];
      sidenavs.forEach(function (s) {
        return s.sidenav.close();
      });
    },
    toggle: function toggle() {
      var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
      (false && !(this._sidenavs[name] && this._sidenavs[name].length) && (0, _debug.assert)("You tried to toggle a sidenav named '".concat(name, "' but no such sidenav is registered"), this._sidenavs[name] && this._sidenavs[name].length));
      var sidenavs = this._sidenavs[name] || [];
      sidenavs.forEach(function (s) {
        return s.sidenav.toggle();
      });
    }
  });
  _exports.default = _default;
});