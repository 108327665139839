define("@trovedata/sunstone-ui-commons/utils/contrast-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = contrastColor;
  function contrastColor(rgb) {
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, '');
    var r = parseInt(rgb.split(',')[0], 10),
      g = parseInt(rgb.split(',')[1], 10),
      b = parseInt(rgb.split(',')[2], 10);
    // a: number;

    // if (rgb.split(',')[3] !== null) {
    //   a = parseInt(rgb.split(',')[3], 10);
    // }

    var contrast = (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;
    return contrast >= 128 ? '#000000' : '#ffffff';
  }
});