define("@trovedata/sunstone-ui-commons/utils/element-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollToBottom = scrollToBottom;
  function scrollToBottom(selector) {
    var smooth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var element = document.querySelector(selector);
    if (element) {
      var style = element.getAttribute('style');
      style = style ? style : '';
      if (smooth) {
        element.setAttribute('style', "scroll-behavior: smooth;".concat(style));
      }
      element.scrollTop = element.scrollHeight;
      if (smooth) {
        element.setAttribute('style', style);
      }
    }
  }
});