define("@trovedata/sunstone-ui-commons/components/paper-select-multiple-trigger/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mZq7B9K2",
    "block": "[[[10,\"md-select-value\"],[15,0,[29,[\"md-select-value \",[52,[30,0,[\"isPlaceholder\"]],\"md-select-placeholder\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"selected\"]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[1,[30,0,[\"selectedItemsText\"]]],[13],[1,\"\\n\\n\"]],[]],[[[41,[30,2],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-placeholder\"],[12],[1,[30,2]],[13],[1,\"\\n\"]],[]],[[[41,[30,3,[\"label\"]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-placeholder\"],[12],[1,[30,3,[\"label\"]]],[13],[1,\"\\n  \"]],[]],null]],[]]]],[]]],[13]],[\"@select\",\"@placeholder\",\"@extra\"],false,[\"if\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-select-multiple-trigger/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});