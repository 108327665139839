define("ember-svg-jar/inlined/navigation-left-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M22.707 12.294l-6-6A1 1 0 0016 6.001h-4v-3a1.002 1.002 0 00-1.707-.707l-9 9a.999.999 0 000 1.414l9 9a.996.996 0 001.09.217 1 1 0 00.617-.924v-3h10a1 1 0 001-1v-4a1 1 0 00-.293-.707zM17 9.416l2.586 2.586H17V9.416zm-6 6.585a1 1 0 00-1 1v1.586l-6.586-6.586L10 5.416v1.586a1 1 0 001 1h4v5a1 1 0 001 1h5v2H11z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});