function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
define("@trovedata/sunstone-ui-commons/components/sunstone-table-header/component", ["exports", "@ember/component", "@ember/object", "@ember/array", "@trovedata/sunstone-ui-commons/components/sunstone-table-header/template"], function (_exports, _component, _object, _array, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _component.default.extend((_obj = {
    layout: _template.default,
    classNames: 'sunstone-table-header',
    tagName: 'thead',
    columns: (0, _array.A)(),
    controlDown: false,
    _columns: (0, _object.computed)('columns.[]', function () {
      return this.columns.map(function (item) {
        var keyParts = (0, _array.A)((0, _object.get)(item, 'id').split('.'));
        var _id;
        if (keyParts.length > 1) {
          _id = keyParts.reduce(function (prev, item, index) {
            var commaKey = '';
            if (index === 0) {
              commaKey = "".concat(item);
            } else {
              commaKey = "".concat(prev, ",").concat(item);
            }
            return commaKey;
          }, '');
        } else {
          _id = keyParts[0];
        }
        return _objectSpread(_objectSpread({}, item), {}, {
          _id: _id
        });
      });
    }),
    sortByMap: (0, _object.computed)('_columns', 'sortBy.[]', function () {
      var _this = this;
      var sortByConst = this.sortBy;
      var sortBy = (0, _array.isArray)(sortByConst) ? sortByConst : (0, _array.A)([sortByConst]);
      var sortMap = {};
      sortBy.forEach(function (item, index) {
        var sortByParts = item && item.split(':') || [];
        var sortByDirection = 'asc';
        if (sortByParts.length >= 2) {
          sortByDirection = sortByParts[1];
        }
        var column = (0, _array.A)(_this._columns).findBy('id', sortByParts[0]);
        if (column) {
          sortMap["".concat((0, _object.get)(column, '_id'))] = {
            direction: sortByDirection,
            order: index + 1
          };
        }
      });
      return sortMap;
    }),
    keydownHandler: function keydownHandler() {
      if (this.controlDown === false) (0, _object.set)(this, 'controlDown', true);
    },
    keyupHandler: function keyupHandler() {
      if (this.controlDown === true) (0, _object.set)(this, 'controlDown', false);
    },
    resort: function resort(column, sortable, commaColumn) {
      if (sortable === true) {
        var controlDown = this.controlDown;
        var currentSortMap = this.sortByMap;
        var sortDirectionCycle = ['asc', 'desc'];
        var sortByConst = this.sortBy;
        var sortBy = (0, _array.isArray)(sortByConst) ? sortByConst : (0, _array.A)([sortByConst]);
        var newSortDirection = sortDirectionCycle[0];
        if ((0, _object.get)(currentSortMap, "".concat(commaColumn))) {
          var cycledIndex = (sortDirectionCycle.indexOf((0, _object.get)(currentSortMap, "".concat(commaColumn, ".direction"))) + 1) % sortDirectionCycle.length;
          newSortDirection = sortDirectionCycle[cycledIndex];
          if (controlDown) {
            sortBy.replace((0, _object.get)(currentSortMap, "".concat(commaColumn, ".order")) - 1, 1, ["".concat(column, ":").concat(newSortDirection)]);
          }
        } else {
          if (controlDown) {
            sortBy.pushObject("".concat(column, ":").concat(newSortDirection));
          }
        }
        if (controlDown) {
          this.sortColumn(sortBy);
        } else {
          this.sortColumn("".concat(column, ":").concat(newSortDirection));
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "keydownHandler", [_object.action], Object.getOwnPropertyDescriptor(_obj, "keydownHandler"), _obj), _applyDecoratedDescriptor(_obj, "keyupHandler", [_object.action], Object.getOwnPropertyDescriptor(_obj, "keyupHandler"), _obj), _applyDecoratedDescriptor(_obj, "resort", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resort"), _obj)), _obj));
  _exports.default = _default;
});