define("ember-svg-jar/inlined/server-information-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .004h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path fill=\"#757575\" d=\"M3 3h5v2H3z\"/></g><g><path fill=\"#757575\" d=\"M3 9h5v2H3z\"/></g><g><path fill=\"#757575\" d=\"M3 15h5v2H3z\"/></g><g><circle cx=\"13\" cy=\"4\" r=\"1\" fill=\"#757575\"/></g><g><circle cx=\"16\" cy=\"4\" r=\"1\" fill=\"#757575\"/></g><path d=\"M8 18H2v-4h6v-2H2V8h18V2a2 2 0 00-2-2H2a2 2 0 00-2 2v16c0 1.103.896 2 2 2h6v-2zM2 2h16l-.001 4H2V2z\" fill=\"#757575\"/><g><g><path d=\"M17 24c-3.859 0-7-3.14-7-7 0-3.859 3.141-7 7-7s7 3.141 7 7c0 3.86-3.141 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z\" fill=\"#757575\"/></g><g><g><g><path fill=\"#757575\" d=\"M16 17h2v4h-2z\"/></g></g></g><g><circle cx=\"17\" cy=\"15\" r=\"1\" fill=\"#757575\"/></g></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});