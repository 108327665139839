define("ember-svg-jar/inlined/interface-alert-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M21 22H3a1.003 1.003 0 01-.895-1.448l9-18c.339-.677 1.45-.677 1.789 0l9 18A1.003 1.003 0 0121 22zM4.618 20h14.764L12 5.237 4.618 20z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M11 10h2v6h-2z\"/></g><g><circle cx=\"12\" cy=\"18\" r=\"1.25\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});