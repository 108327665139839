define("ember-paper/components/paper-switch", ["exports", "@ember/service", "@ember/component", "@ember/debug", "@ember/object", "@ember/runloop", "@ember/string", "ember-paper/templates/components/paper-switch", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/proxiable-mixin", "ember-paper/utils/invoke-action"], function (_exports, _service, _component, _debug, _object, _runloop, _string, _paperSwitch, _focusableMixin, _colorMixin, _proxiableMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /* global Hammer */
  /**
   * @class PaperSwitch
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ColorMixin
   * @uses ProxiableMixin
   */
  var _default = _component.default.extend(_focusableMixin.default, _colorMixin.default, _proxiableMixin.default, {
    layout: _paperSwitch.default,
    tagName: 'md-switch',
    classNames: ['paper-switch', 'md-default-theme'],
    classNameBindings: ['value:md-checked', 'dragging:md-dragging'],
    toggle: true,
    constants: (0, _service.inject)(),
    value: false,
    disabled: false,
    dragging: false,
    thumbContainerStyle: (0, _object.computed)('dragging', 'dragAmount', function () {
      if (!this.dragging) {
        return (0, _string.htmlSafe)('');
      }
      var translate = Math.max(0, Math.min(100, this.dragAmount * 100));
      var transformProp = "translate3d(".concat(translate, "%, 0, 0)");
      return (0, _string.htmlSafe)("transform: ".concat(transformProp, ";-webkit-transform: ").concat(transformProp));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      // Only setup if the switch is not disabled
      if (!this.disabled) {
        this._setupSwitch();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      (false && !(this.onChange !== undefined) && (0, _debug.assert)('{{paper-switch}} requires an `onChange` action or null for no action.', this.onChange !== undefined));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._teardownSwitch();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (!this.disabled && !this._switchContainerHammer) {
        this._setupSwitch();
      } else if (!this.disabled && this._switchContainerHammer) {
        this._switchContainerHammer.set({
          enable: true
        });
      } else if (this.disabled && this._switchContainerHammer) {
        this._switchContainerHammer.set({
          enable: false
        });
      }
    },
    _setupSwitch: function _setupSwitch() {
      this.set('switchWidth', this.element.querySelector('.md-thumb-container').offsetWidth);
      var switchContainer = this.element.querySelector('.md-container');
      var switchHammer = new Hammer(switchContainer);
      this._switchContainerHammer = switchHammer;

      // Enable dragging the switch container
      switchHammer.get('pan').set({
        threshold: 1
      });
      switchHammer.on('panstart', _runloop.run.bind(this, this._dragStart)).on('panmove', _runloop.run.bind(this, this._drag)).on('panend', _runloop.run.bind(this, this._dragEnd));

      // Enable tapping gesture on the switch
      this._switchHammer = new Hammer(this.element);
      this._switchHammer.on('tap', _runloop.run.bind(this, this._dragEnd));
      this._onClickHandleNativeClick = _runloop.run.bind(this, this._handleNativeClick);
      this.element.querySelector('.md-container').addEventListener('click', this._onClickHandleNativeClick);
    },
    _handleNativeClick: function _handleNativeClick(ev) {
      var bubbles = this.bubbles;
      if (!bubbles) {
        ev.stopPropagation();
      }
      return bubbles;
    },
    _teardownSwitch: function _teardownSwitch() {
      if (this._switchContainerHammer) {
        this._switchContainerHammer.destroy();
        this._switchHammer.destroy();
      }
      this.element.querySelector('.md-container').removeEventListener('click', this._onClickHandleNativeClick);
      this._onClickHandleNativeClick = null;
    },
    _dragStart: function _dragStart() {
      this.set('dragAmount', +this.value);
      this.set('dragging', true);
    },
    _drag: function _drag(event) {
      if (!this.disabled) {
        // Set the amount the switch has been dragged
        this.set('dragAmount', +this.value + event.deltaX / this.switchWidth);
      }
    },
    _dragEnd: function _dragEnd() {
      if (!this.disabled) {
        var value = this.value;
        var dragAmount = this.dragAmount;
        if (!this.dragging || value && dragAmount < 0.5 || !value && dragAmount > 0.5) {
          (0, _invokeAction.invokeAction)(this, 'onChange', !value);
        }
        this.set('dragging', false);
        this.set('dragAmount', null);
      }
    },
    focusIn: function focusIn() {
      // Focusing in w/o being pressed should use the default behavior
      if (!this.pressed) {
        this._super.apply(this, arguments);
      }
    },
    keyPress: function keyPress(ev) {
      if (ev.which === this.get('constants.KEYCODE.SPACE') || ev.which === this.get('constants.KEYCODE.ENTER')) {
        ev.preventDefault();
        this._dragEnd();
      }
    },
    processProxy: function processProxy() {
      (0, _invokeAction.invokeAction)(this, 'onChange', !this.value);
    }
  });
  _exports.default = _default;
});