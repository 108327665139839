define("ember-svg-jar/inlined/DataHealth-Color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g><g><path d=\"M142.5 39.6c-4.9-19.1-21.1-32.5-39.3-32.5-12 0-22.9 5.8-31.2 16.4C63.7 12.9 52.8 7.1 40.8 7.1 22.6 7.1 6.4 20.5 1.5 39.6-1.1 49.9-1.6 66.2 10.8 86c11.5 18.3 31.3 35.2 59.1 50.3.7.4 1.4.5 2.1.5.7 0 1.5-.2 2.1-.5 27.7-15.1 47.6-32.1 59.1-50.3 12.4-19.8 11.9-36.1 9.3-46.4zM72 127.3C4.1 89.5 7.5 52.4 10.2 41.8 14 26.6 26.6 16 40.8 16c10.7 0 20.4 6.3 27.3 17.7.8 1.3 2.3 2.1 3.8 2.1 1.6 0 3-.8 3.8-2.1 7-11.4 16.7-17.7 27.5-17.7 14.2 0 26.8 10.6 30.7 25.8 2.6 10.6 6 47.7-61.9 85.5zm0 0\" fill=\"#8dc63f\"/></g><path d=\"M116.1 72.5H96l-7.5-27.6c-.5-1.9-2.3-3.3-4.3-3.3s-3.8 1.4-4.3 3.3L69.7 83.1 59.4 44.9c-.5-1.9-2.3-3.3-4.3-3.3H55c-2 0-3.7 1.3-4.3 3.2l-5.2 17.7h-20c-2.5 0-4.5 2-4.5 4.5s2 4.5 4.5 4.5H49c2 0 3.7-1.3 4.3-3.2l1.7-5.7 10.4 38.9c.5 1.9 2.3 3.3 4.3 3.3s3.8-1.4 4.3-3.3l10.3-38.2 4 14.9c.5 1.9 2.3 3.3 4.3 3.3h23.5c2.5 0 4.5-2 4.5-4.5-.1-2.5-2.1-4.5-4.5-4.5zm0 0\" fill=\"#fff\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});