define("ember-paper/templates/components/paper-toast", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TmiP9xQ2",
    "block": "[[[40,[[[1,\"  \"],[8,[39,3],null,[[\"@swipeToClose\",\"@onClose\",\"@top\",\"@left\",\"@capsule\",\"@class\"],[[99,4,[\"@swipeToClose\"]],[99,5,[\"@onClose\"]],[99,6,[\"@top\"]],[99,7,[\"@left\"]],[99,8,[\"@capsule\"]],[99,9,[\"@class\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,1,[[28,[37,11],null,[[\"text\"],[[50,\"paper-toast-text\",0,null,null]]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[33,2]],null],null]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"destinationEl\",\"paper-toast-inner\",\"swipeToClose\",\"onClose\",\"top\",\"left\",\"capsule\",\"class\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-toast.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});