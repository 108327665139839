define("ember-paper/templates/components/paper-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "f8bAPBnR",
    "block": "[[[44,[[28,[37,1],null,[[\"checkbox\",\"button\",\"switch\",\"radio\"],[[50,\"paper-checkbox\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[30,0],false,true]]],[50,\"paper-button\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\",\"skipProxy\"],[[30,0],false,true,true]]],[50,\"paper-switch\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[30,0],false,true]]],[50,\"paper-radio-proxiable\",0,null,[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[30,0],false,true]]]]]]],[[[41,[30,1],[[[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"md-button md-no-style\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@class\",\"@onClick\",\"@href\",\"@target\",\"@onMouseEnter\",\"@onMouseLeave\"],[\"md-no-style\",[99,6,[\"@onClick\"]],[99,7,[\"@href\"]],[99,8,[\"@target\"]],[28,[37,9],[[30,0],[30,0,[\"handleMouseEnter\"]]],null],[28,[37,9],[[30,0],[30,0,[\"handleMouseLeave\"]]],null]]],null],[1,\"\\n      \"],[10,0],[14,0,\"md-list-item-inner\"],[12],[1,\"\\n        \"],[18,2,[[30,1]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,11],null,[[\"@noink\",\"@dimBackground\"],[[30,0,[\"noink\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"md-no-style md-list-item-inner\"],[12],[1,\"\\n      \"],[18,2,[[30,1]]],[1,\"\\n      \"],[8,[39,11],null,[[\"@noink\",\"@dimBackground\"],[[30,0,[\"noink\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[]],null]],[1]]]],[\"controls\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"if\",\"hasPrimaryAction\",\"paper-button\",\"onClick\",\"href\",\"target\",\"action\",\"yield\",\"paper-ripple\"]]",
    "moduleName": "ember-paper/templates/components/paper-item.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});