define("@trovedata/sunstone-ui-commons/models/property", ["exports", "@ember/object/computed", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _computed, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    defaultValue: (0, _model.attr)('string'),
    insertTime: (0, _model.attr)('basic-date'),
    lastUpdateBy: (0, _model.attr)('string'),
    lastUpdateTime: (0, _model.attr)('basic-date'),
    dataSource: (0, _model.attr)('string'),
    recVersionNum: (0, _model.attr)('number'),
    environmentScopeIndicator: (0, _model.attr)('string', {
      defaultValue: 'U'
    }),
    orgId: (0, _model.attr)('number'),
    configPropertySet: (0, _model.belongsTo)('property-set', {
      inverse: null
    }),
    sinceVersionDetail: (0, _attributes.fragment)('sinceVersionDetail', {
      defaultValue: {
        sinceVersion: 2
      }
    }),
    sinceVersion: (0, _computed.alias)('sinceVersionDetail.sinceVersion'),
    activeIndicatorDetail: (0, _attributes.fragment)('activeIndicatorDetail', {
      defaultValue: {
        activeIndicator: true
      }
    }),
    activeIndicator: (0, _computed.alias)('activeIndicatorDetail.activeIndicator')
  });
  _exports.default = _default;
});