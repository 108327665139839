define("@trovedata/sunstone-ui-commons/components/trove-label/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lbtFz4nJ",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"dot-loader-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dot-loader\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"dot-1\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"dot-2\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"dot-3\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[33,4]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[52,[28,[37,5],[[33,4]],null],\"--\",[33,4]]],[1,\"\\n\"]],[]]],[41,[30,0,[\"tooltip\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@side\",\"@text\"],[[30,0,[\"side\"]],[30,0,[\"tooltip\"]]]],null],[1,\"\\n\"]],[]],null],[41,[33,7],[[[6,[39,8],null,[[\"iconButton\"],[true]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,9],[\"pencil-3\"],[[\"size\"],[14]]]],[1,\"\\n\"]],[]]]]]],[]],null],[1,\"\\n\"],[41,[33,10],[[[1,\"    \"],[1,[28,[35,11],null,[[\"value\",\"onChange\",\"onBlur\"],[[33,12],[28,[37,13],[[30,0],[28,[37,14],[[33,12]],null]],null],[28,[37,13],[[30,0],\"editSave\",[33,12],[33,7]],null]]]]],[1,\"\\n\"],[6,[39,8],null,[[\"accent\",\"iconButton\",\"onClick\",\"isPending\"],[true,true,[28,[37,13],[[30,0],\"editSave\",[33,12],[33,7]],null],[33,15,[\"isRunning\"]]]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,9],[\"check\"],[[\"size\"],[14]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[]]]],[\"&default\"],false,[\"if\",\"loading\",\"has-block\",\"yield\",\"label\",\"is-empty\",\"ember-tooltip\",\"onEditSave\",\"trove-button\",\"trove-icon\",\"showInput\",\"paper-input\",\"labelToEdit\",\"action\",\"mut\",\"editSaveTask\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/trove-label/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});