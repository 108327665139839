define("@trovedata/sunstone-ui-commons/services/filter-operators", ["exports", "@ember/service", "@ember/array", "@ember/object"], function (_exports, _service, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    catalogOperators: (0, _array.A)([{
      name: 'EQUAL',
      display: 'equal to',
      integer: 1
    }, {
      name: 'NEQ',
      display: 'not equal to',
      integer: 2
    }, {
      name: 'GT',
      display: 'greater than',
      integer: 3
    }, {
      name: 'GTE',
      display: 'greater than or equal to',
      integer: 4
    }, {
      name: 'LT',
      display: 'less than',
      integer: 5
    }, {
      name: 'LTE',
      display: 'less than or equal to',
      integer: 6
    }, {
      name: 'LIKE',
      display: 'similar to',
      integer: 8
    }, {
      name: 'IN',
      display: 'includes',
      integer: 9
    }]),
    operatorsByInteger: (0, _object.computed)('catalogOperators.[]', function () {
      return this.catalogOperators.reduce(function (prev, item) {
        prev["".concat((0, _object.get)(item, 'integer'))] = item;
        return prev;
      }, {});
    }),
    datasetOperatorsByName: (0, _object.computed)('catalogOperators.[]', function () {
      return this.catalogOperators.reduce(function (prev, item) {
        prev["".concat((0, _object.get)(item, 'name'))] = item;
        return prev;
      }, {});
    }),
    enumOperators: (0, _array.A)([{
      name: 'EQUAL',
      display: 'equal to'
    }, {
      name: 'NEQ',
      display: 'not equal to'
    }]),
    stringOperators: (0, _array.A)([{
      name: 'EQUAL',
      display: 'equal to'
    }, {
      name: 'NEQ',
      display: 'not equal to'
    }, {
      name: 'LIKE',
      display: 'similar to'
    }]),
    operators: (0, _array.A)([{
      name: 'EQUAL',
      display: 'equal to'
    }, {
      name: 'NEQ',
      display: 'not equal to'
    }, {
      name: 'LT',
      display: 'less than'
    }, {
      name: 'LTE',
      display: 'less than or equal to'
    }, {
      name: 'GT',
      display: 'greater than'
    }, {
      name: 'GTE',
      display: 'greater than or equal to'
    }])
  });
  _exports.default = _default;
});