define("@trovedata/sunstone-ui-commons/components/paper-select-options/component", ["exports", "ember-paper/components/paper-select/options/component", "@trovedata/sunstone-ui-commons/components/paper-select-options/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var component = _component.default.extend({
    layout: _template.default
  });
  var _default = component;
  _exports.default = _default;
});