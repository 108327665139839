define("@trovedata/sunstone-ui-commons/utils/jwt-decrypt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = jwtDecrypt;
  function jwtDecrypt(token) {
    var base64Url = token.split('.');
    var base64 = base64Url[1].replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
});