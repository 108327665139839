define("@trovedata/sunstone-ui-commons/index/route", ["exports", "@ember/array", "@ember/routing/route", "@ember/object", "@ember/service", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _array, _route, _object, _service, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  /* eslint-disable ember/no-mixins */
  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    config: (0, _service.inject)(),
    model: function model() {
      return (0, _array.A)(Object.keys((0, _object.get)(this, 'config.sunstone.apps') === undefined ? {} : (0, _object.get)(this, 'config.sunstone.apps')));
    },
    redirect: function redirect(model) {
      if ((0, _object.get)(model, 'length') === 1) {
        var app = (0, _object.get)(this, "config.sunstone.apps.".concat((0, _object.get)(model, 'firstObject')));
        this.transitionTo((0, _object.get)(app, 'id'));
      }
    }
  });
  _exports.default = _default;
});