define("@trovedata/sunstone-ui-commons/components/sunstone-map-cluster/component", ["exports", "@ember/array", "@ember/object", "@ember/component", "@trovedata/sunstone-ui-commons/components/sunstone-map-cluster/template"], function (_exports, _array, _object, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global google, MarkerClusterer */
  var _default = _component.default.extend({
    layout: _template.default,
    markers: (0, _array.A)(),
    _markers: (0, _array.A)(),
    _circles: (0, _array.A)(),
    markerCluster: null,
    color: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      if (this.map) {
        if (this.markerCluster) {
          this.markerCluster.setMap(null);
        }
        this._markers.forEach(function (marker) {
          marker.setMap(null);
        });
        (0, _object.set)(this, '_markers', []);
        var bounds = new google.maps.LatLngBounds();
        this.markers.forEach(function (marker) {
          if (marker.get('latitude') && marker.get('longitude')) {
            var latlng = new google.maps.LatLng(marker.get('latitude'), marker.get('longitude'));
            var position = new google.maps.Marker({
              'position': latlng
            });
            _this._markers.push(position);
            bounds.extend(latlng);
          }
        });
        this.map.fitBounds(bounds);
        (0, _object.set)(this, 'markerCluster', new MarkerClusterer(this.map, this._markers));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.markerCluster) {
        this.markerCluster.setMap(null);
      }
      this._markers.forEach(function (marker) {
        marker.setMap(null);
      });
    }
  });
  _exports.default = _default;
});