define("ember-svg-jar/inlined/places-home-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M17 21.999h-2c-1.103 0-2-.896-2-2v-3h-2v3c0 1.104-.897 2-2 2H7c-1.103 0-2-.896-2-2v-6H3a1 1 0 01-.707-1.706l9-9a1 1 0 011.414 0l9 9A.999.999 0 0121 13.999h-2v6c0 1.103-.897 2-2 2zm-7-7h4c.552 0 1 .449 1 1v4h2.001l-.001-7c0-.551.448-1 1-1h.586L12 5.414l-6.586 6.585H6c.552 0 1 .449 1 1v7h2v-4a1 1 0 011-1z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});