define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DoIlnMlg",
    "block": "[[[18,1,[[28,[37,1],null,[[\"actions\"],[[50,\"card-list/card-list-item/card-list-item-content/card-list-item-actions\",0,null,[[\"toggleActions\"],[[33,3]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"toggleActions\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});