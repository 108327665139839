define("@trovedata/sunstone-ui-commons/components/sunstone-results-table-body/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iNsXV0b/",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n  \"],[1,[34,3]],[1,\"\\n\"]],[]],[[[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[28,[37,6],null,[[\"table\",\"pageResults\"],[[50,\"sunstone-table\",0,null,[[\"tableClassNames\",\"selectedRow\",\"sortBy\",\"resizable\",\"columns\",\"rows\",\"rowsInViewport\",\"tableActions\",\"columnSort\",\"rowClick\",\"detailRow\"],[[28,[37,8],[[33,9]],null],[28,[37,8],[[33,10]],null],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[33,17],[33,18],[33,19]]]],[33,1]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,20],null,[[\"tableClassNames\",\"selectedRow\",\"sortBy\",\"resizable\",\"columns\",\"rows\",\"rowsInViewport\",\"tableActions\",\"columnSort\",\"rowClick\",\"detailRow\"],[[28,[37,8],[[33,9]],null],[28,[37,8],[[33,10]],null],[33,11],[33,12],[33,13],[33,14],[33,15],[33,16],[33,17],[33,18],[33,19]]]]],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"unless\",\"pageResults\",\"yield\",\"icon-message\",\"if\",\"has-block\",\"hash\",\"component\",\"mut\",\"tableClassNames\",\"selectedRow\",\"sortBy\",\"resizable\",\"columns\",\"rows\",\"rowsInViewport\",\"tableActions\",\"columnSort\",\"rowClick\",\"detailRow\",\"sunstone-table\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sunstone-results-table-body/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});