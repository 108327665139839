define("@trovedata/sunstone-ui-commons/models/trove-download-request", ["exports", "@ember/object", "@ember/object/computed", "@ember-data/model"], function (_exports, _object, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    filename: (0, _model.attr)('string'),
    percentComplete: (0, _model.attr)('number'),
    filePath: (0, _model.attr)('string'),
    status: (0, _model.attr)('string'),
    requestId: (0, _model.belongsTo)('trove-export-request'),
    fileIds: (0, _model.hasMany)('trove-export-file'),
    isProcessing: (0, _computed.equal)('status', 'PROCESSING'),
    isFinished: (0, _object.computed)('status', function () {
      return this.status === 'COMPLETE' || this.status === 'ERROR';
    })
  });
  _exports.default = _default;
});