define("@trovedata/sunstone-ui-commons/serializers/model-session-resource", ["exports", "@trovedata/sunstone-ui-commons/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    primaryKey: 'modelSessionId'
  });
  _exports.default = _default;
});