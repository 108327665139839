define("@trovedata/sunstone-ui-commons/transforms/timestamp", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/use-ember-data-rfc-395-imports */
  var _default = _emberData.default.DateTransform.extend({
    serialize: function serialize(date) {
      if (typeof date === 'string') {
        date = (0, _moment.default)(date);
      }
      if (_moment.default.isMoment(date) || date instanceof Date) {
        return (0, _moment.default)(date).format('YYYY-MM-DD HH:mm:00');
      } else {
        return null;
      }
    }
  });
  _exports.default = _default;
});