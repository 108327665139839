define("ember-svg-jar/inlined/cloud-downloading-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M15 15H7c-2.757 0-5-2.243-5-5s2.243-5 5-5c.533 0 1.061.086 1.563.254A6.958 6.958 0 0115 1c3.859 0 7 3.141 7 7 0 3.86-3.141 7-7 7zM7 7c-1.654 0-3 1.346-3 3s1.346 3 3 3h8c2.757 0 5-2.243 5-5s-2.243-5-5-5a4.971 4.971 0 00-4.865 3.867 1 1 0 01-1.517.613A2.985 2.985 0 007 7z\" fill=\"#757575\"/></g><path d=\"M20 17H4a1 1 0 00-1 1v4a1 1 0 001 1h16a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4H9v-2h10v2z\" fill=\"#757575\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});