define("@trovedata/sunstone-ui-commons/utils/map-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildGeoJson;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function buildGeoJson(type, coordinates, options) {
    var geoJson = {
      name: options === null || options === void 0 ? void 0 : options.name,
      properties: _objectSpread({
        icon: options === null || options === void 0 ? void 0 : options.icon,
        selectedColor: options === null || options === void 0 ? void 0 : options.selectedColor,
        fillColor: options === null || options === void 0 ? void 0 : options.fillColor,
        borderColor: options === null || options === void 0 ? void 0 : options.borderColor,
        name: options === null || options === void 0 ? void 0 : options.name,
        selectedWeight: options === null || options === void 0 ? void 0 : options.selectedWeight,
        weight: options === null || options === void 0 ? void 0 : options.weight,
        strokeWeight: options === null || options === void 0 ? void 0 : options.strokeWeight,
        componentInfoWindowProps: options === null || options === void 0 ? void 0 : options.componentInfoWindowProps,
        zIndex: options === null || options === void 0 ? void 0 : options.zIndex,
        bounds: options === null || options === void 0 ? void 0 : options.bounds
      }, options === null || options === void 0 ? void 0 : options.extraProps),
      geometry: {
        type: type,
        coordinates: coordinates
      },
      type: 'Feature'
    };
    return geoJson;
  }
});