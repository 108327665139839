define("ember-svg-jar/inlined/navigation-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z\"/><path fill=\"none\" d=\"M0 0h24v24H0V0z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});