define("ember-svg-jar/inlined/video-control-stop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M19 3H5c-1.103 0-2 .896-2 2v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V5c0-1.104-.897-2-2-2zM5 19V5h14l.001 14H5z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});