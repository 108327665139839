define("ember-svg-jar/inlined/electric-pole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Filled_Icons\"><g fill=\"#757575\"><path d=\"M21 8V6H3v2h1.63L11 13.46V22h2v-8.54L19.37 8H21zM7.703 8H11v2.826L7.703 8zM13 10.826V8h3.297L13 10.826z\"/><circle cx=\"19\" cy=\"4\" r=\"1\"/><circle cx=\"15\" cy=\"4\" r=\"1\"/><circle cx=\"9\" cy=\"4\" r=\"1\"/><circle cx=\"5\" cy=\"4\" r=\"1\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});