define("ember-paper/components/paper-speed-dial-actions-action", ["exports", "@ember/component", "@ember/object", "@ember/string"], function (_exports, _component, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function getElementIndex(node) {
    var index = 0;
    while (node = node.previousElementSibling) {
      index++;
    }
    return index;
  }
  var _default = _component.default.extend({
    classNames: ['md-fab-action-item'],
    attributeBindings: ['style'],
    style: (0, _object.computed)('elementDidRender', 'speedDial.{animation,open,direction}', function () {
      if (!this.elementDidRender) {
        return;
      }
      var animation = this.get('speedDial.animation');
      var open = this.get('speedDial.open');
      if (animation === 'fling') {
        if (!open) {
          return this.flingClosed();
        }
      } else if (animation === 'scale') {
        return this.scaleClosed();
      }
      return undefined;
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.set('elementDidRender', true);
    },
    scaleClosed: function scaleClosed() {
      var items = this.get('speedDial.element').querySelectorAll('.md-fab-action-item');
      var open = this.get('speedDial.open');
      var index = getElementIndex(this.element);
      var delay = 65;
      var offsetDelay = index * delay;
      var startZIndex = 0;
      var opacity = open ? 1 : 0;
      var transform = open ? 'scale(1)' : 'scale(0)';
      var transitionDelay = "".concat(open ? offsetDelay : items.length * delay - offsetDelay, "ms");

      // Make the items closest to the trigger have the highest z-index
      var zIndex = items.length - index + startZIndex;
      return (0, _string.htmlSafe)("opacity: ".concat(opacity, "; transform: ").concat(transform, "; transition-delay: ").concat(transitionDelay, "; z-index: ").concat(zIndex, ";"));
    },
    flingClosed: function flingClosed() {
      var triggerElement = this.get('speedDial.element').querySelector('md-fab-trigger');
      var direction = this.get('speedDial.direction');
      var index = getElementIndex(this.element);

      // Make sure to account for differences in the dimensions of the trigger verses the items
      // so that we can properly center everything; this helps hide the el's shadows behind
      // the trigger.
      var triggerItemHeightOffset = (triggerElement.clientHeight - this.element.clientHeight) / 2;
      var triggerItemWidthOffset = (triggerElement.clientWidth - this.element.clientWidth) / 2;
      var newPosition, axis;
      switch (direction) {
        case 'up':
          newPosition = this.element.scrollHeight * (index + 1) + triggerItemHeightOffset;
          axis = 'Y';
          break;
        case 'down':
          newPosition = -(this.element.scrollHeight * (index + 1) + triggerItemHeightOffset);
          axis = 'Y';
          break;
        case 'left':
          newPosition = this.element.scrollWidth * (index + 1) + triggerItemWidthOffset;
          axis = 'X';
          break;
        case 'right':
          newPosition = -(this.element.scrollWidth * (index + 1) + triggerItemWidthOffset);
          axis = 'X';
          break;
      }
      return (0, _string.htmlSafe)("transform: translate".concat(axis, "(").concat(newPosition, "px)"));
    }
  });
  _exports.default = _default;
});