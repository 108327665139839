define("ember-svg-jar/inlined/vote-star-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Filled_Icons\"><path fill=\"#757575\" d=\"M21.924 8.618A1 1 0 0021 8.001h-5.382l-2.724-5.447c-.339-.677-1.45-.677-1.789 0L8.382 8.001H3a1 1 0 00-.707 1.707l4.584 4.584-1.838 6.435a1 1 0 001.515 1.106L12 18.203l5.445 3.63a.998.998 0 001.516-1.106l-1.838-6.435 4.584-4.584c.286-.285.371-.717.217-1.09z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});