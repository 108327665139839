define("ember-paper/components/paper-grid-list", ["exports", "@ember/service", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "ember-paper/templates/components/paper-grid-list", "ember-composability-tools", "ember-paper/utils/grid-layout", "ember-paper/utils/invoke-action"], function (_exports, _service, _computed, _component, _object, _runloop, _paperGridList, _emberComposabilityTools, _gridLayout, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */

  var mediaRegex = /(^|\s)((?:print-)|(?:[a-z]{2}-){1,2})?(\d+)(?!\S)/g;
  var rowHeightRegex = /(^|\s)((?:print-)|(?:[a-z]{2}-){1,2})?(\d+(?:[a-z]{2,3}|%)?|\d+:\d+|fit)(?!\S)/g;
  var unitCSS = function unitCSS(units) {
    return "".concat(units.share, "% - (").concat(units.gutter, " * ").concat(units.gutterShare, ")");
  };
  var dimensionCSS = function dimensionCSS(dimensions) {
    return "calc((".concat(dimensions.unit, ") * ").concat(dimensions.span, " + (").concat(dimensions.span, " - 1) * ").concat(dimensions.gutter, ")");
  };
  var media = function media(mediaName) {
    return mediaName.charAt(0) !== '(' ? "(".concat(mediaName, ")") : mediaName;
  };
  var mediaListenerName = function mediaListenerName(name) {
    return "".concat(name.replace('-', ''), "Listener");
  };
  var applyStyles = function applyStyles(el, styles) {
    for (var key in styles) {
      el.style[key] = styles[key];
    }
  };

  /**
   * @class PaperGridList
   * @extends Ember.Component
   */
  var _default = _component.default.extend(_emberComposabilityTools.ParentMixin, {
    layout: _paperGridList.default,
    tagName: 'md-grid-list',
    constants: (0, _service.inject)(),
    tiles: (0, _computed.alias)('childComponents'),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._installMediaListener();
    },
    didUpdate: function didUpdate() {
      this._super.apply(this, arguments);

      // Debounces until the next run loop
      _runloop.run.debounce(this, this.updateGrid, 0);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._uninstallMediaListener();
    },
    // Sets up a listener for each media query
    _installMediaListener: function _installMediaListener() {
      var _this = this;
      var _loop = function _loop(mediaName) {
        var query = _this.get('constants.MEDIA')[mediaName] || media(mediaName);
        var mediaList = window.matchMedia(query);
        var listenerName = mediaListenerName(mediaName);

        // Sets mediaList to a property so removeListener can access it
        _this.set("".concat(listenerName, "List"), mediaList);

        // Creates a function based on mediaName so that removeListener can remove it.
        _this.set(listenerName, _runloop.run.bind(_this, function (result) {
          _this._mediaDidChange(mediaName, result.matches);
        }));

        // Trigger initial grid calculations
        _this._mediaDidChange(mediaName, mediaList.matches);
        mediaList.addListener(_this[listenerName]);
      };
      for (var mediaName in this.get('constants.MEDIA')) {
        _loop(mediaName);
      }
    },
    _uninstallMediaListener: function _uninstallMediaListener() {
      for (var mediaName in this.get('constants.MEDIA')) {
        var listenerName = mediaListenerName(mediaName);
        var mediaList = this.get("".concat(listenerName, "List"));
        mediaList.removeListener(this[listenerName]);
      }
    },
    _mediaDidChange: function _mediaDidChange(mediaName, matches) {
      this.set(mediaName, matches);

      // Debounces until the next run loop
      _runloop.run.debounce(this, this._updateCurrentMedia, 0);
    },
    _updateCurrentMedia: function _updateCurrentMedia() {
      var _this2 = this;
      var mediaPriorities = this.get('constants.MEDIA_PRIORITY');
      var currentMedia = mediaPriorities.filter(function (mediaName) {
        return _this2.get(mediaName);
      });
      this.set('currentMedia', currentMedia);
      this.updateGrid();
    },
    // Updates styles and triggers onUpdate callbacks
    updateGrid: function updateGrid() {
      applyStyles(this.element, this._gridStyle());
      this.tiles.forEach(function (tile) {
        return tile.updateTile();
      });
      (0, _invokeAction.invokeAction)(this, 'onUpdate');
    },
    _gridStyle: function _gridStyle() {
      this._setTileLayout();
      var style = {};
      var colCount = this.currentCols;
      var gutter = this.currentGutter;
      var rowHeight = this.currentRowHeight;
      var rowMode = this.currentRowMode;
      var rowCount = this.rowCount;
      switch (rowMode) {
        case 'fixed':
          {
            style.height = dimensionCSS({
              unit: rowHeight,
              span: rowCount,
              gutter: gutter
            });
            style.paddingBottom = '';
            break;
          }
        case 'ratio':
          {
            // rowHeight is width / height
            var hGutterShare = colCount === 1 ? 0 : (colCount - 1) / colCount;
            var hShare = 1 / colCount * 100;
            var vShare = hShare * (1 / rowHeight);
            var vUnit = unitCSS({
              share: vShare,
              gutterShare: hGutterShare,
              gutter: gutter
            });
            style.height = '';
            style.paddingBottom = dimensionCSS({
              unit: vUnit,
              span: rowCount,
              gutter: gutter
            });
            break;
          }
        case 'fit':
          {
            // rowHeight is container height
            style.height = '100%';
            break;
          }
      }
      return style;
    },
    // Calculates tile positions
    _setTileLayout: function _setTileLayout() {
      var tiles = this.orderedTiles();
      var layoutInfo = (0, _gridLayout.default)(this.currentCols, tiles);
      tiles.forEach(function (tile, i) {
        return tile.set('position', layoutInfo.positions[i]);
      });
      this.set('rowCount', layoutInfo.rowCount);
    },
    // Sorts tiles by their order in the dom
    orderedTiles: function orderedTiles() {
      // Convert NodeList to native javascript array, to be able to use indexOf.
      var domTiles = Array.prototype.slice.call(this.element.querySelectorAll('md-grid-tile'));
      return this.tiles.sort(function (a, b) {
        return domTiles.indexOf(a.get('element')) > domTiles.indexOf(b.get('element')) ? 1 : -1;
      });
    },
    // Parses attribute string and returns hash of media sizes
    _extractResponsiveSizes: function _extractResponsiveSizes(string) {
      var regex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : mediaRegex;
      var matches = {};
      var match;
      while (match = regex.exec(string)) {
        if (match[2]) {
          matches[match[2].slice(0, -1)] = match[3];
        } else {
          matches.base = match[3];
        }
      }
      return matches;
    },
    // Gets attribute for current media
    _getAttributeForMedia: function _getAttributeForMedia(sizes, currentMedia) {
      for (var i = 0; i < currentMedia.length; i++) {
        if (sizes[currentMedia[i]]) {
          return sizes[currentMedia[i]];
        }
      }
      return sizes.base;
    },
    colsMedia: (0, _object.computed)('cols', function () {
      var sizes = this._extractResponsiveSizes(this.cols);
      if (Object.keys(sizes).length === 0) {
        throw new Error('md-grid-list: No valid cols found');
      }
      return sizes;
    }),
    currentCols: (0, _object.computed)('colsMedia', 'currentMedia.[]', function () {
      return this._getAttributeForMedia(this.colsMedia, this.currentMedia) || 1;
    }),
    gutterMedia: (0, _object.computed)('gutter', function () {
      return this._extractResponsiveSizes(this.gutter, rowHeightRegex);
    }),
    currentGutter: (0, _object.computed)('gutterMedia', 'currentMedia.[]', function () {
      return this._applyDefaultUnit(this._getAttributeForMedia(this.gutterMedia, this.currentMedia) || 1);
    }),
    rowHeightMedia: (0, _object.computed)('rowHeight', function () {
      var rowHeights = this._extractResponsiveSizes(this.rowHeight, rowHeightRegex);
      if (Object.keys(rowHeights).length === 0) {
        throw new Error('md-grid-list: No valid rowHeight found');
      }
      return rowHeights;
    }),
    currentRowHeight: (0, _object.computed)('rowHeightMedia', 'currentMedia.[]', function () {
      var rowHeight = this._getAttributeForMedia(this.rowHeightMedia, this.currentMedia);
      // eslint-disable-next-line ember/no-side-effects
      this.set('currentRowMode', this._getRowMode(rowHeight));
      switch (this._getRowMode(rowHeight)) {
        case 'fixed':
          {
            return this._applyDefaultUnit(rowHeight);
          }
        case 'ratio':
          {
            var whRatio = rowHeight.split(':');
            return parseFloat(whRatio[0]) / parseFloat(whRatio[1]);
          }
        case 'fit':
          {
            return 0;
          }
      }
      return undefined;
    }),
    _getRowMode: function _getRowMode(rowHeight) {
      if (rowHeight === 'fit') {
        return 'fit';
      } else if (rowHeight.indexOf(':') !== -1) {
        return 'ratio';
      } else {
        return 'fixed';
      }
    },
    _applyDefaultUnit: function _applyDefaultUnit(val) {
      return /\D$/.test(val) ? val : "".concat(val, "px");
    }
  });
  _exports.default = _default;
});