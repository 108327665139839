define("@trovedata/sunstone-ui-commons/services/customer-data", ["exports", "@ember/array", "@ember/service"], function (_exports, _array, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    numberOfCustomers: 0,
    lastUpdated: null,
    consumerData: (0, _array.A)(),
    defaultSets: (0, _array.A)(),
    peviousUpdate: null,
    currentVersion: null,
    consumerChange: 0
  });
  _exports.default = _default;
});