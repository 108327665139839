define("@trovedata/sunstone-ui-commons/mixins/trove-confirm-controller", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    actions: {
      didConfirm: function didConfirm(_didConfirm) {
        (0, _object.set)(this, 'showTroveConfirm', null);
        if (_didConfirm) {
          this.prevTransition.retry();
        }
        (0, _object.set)(this, 'prevTransition', null);
      }
    }
  });
  _exports.default = _default;
});