define("ember-paper/templates/components/paper-tooltip-inner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yirfimQN",
    "block": "[[[11,\"md-tooltip\"],[16,0,[29,[\"md-tooltip md-panel \",[30,0,[\"positionClass\"]],\" \",[52,[30,1],\"md-hide\"],\" \",[30,2]]]],[17,3],[4,[38,1],[[30,0,[\"setupTooltip\"]],[30,4],[30,5]],null],[12],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@hide\",\"@class\",\"&attrs\",\"@position\",\"@anchorElement\",\"&default\"],false,[\"if\",\"did-insert\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-tooltip-inner.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});