define("@trovedata/sunstone-ui-commons/components/progress-bar/component", ["exports", "@ember/template", "@ember/component", "@ember/object", "@ember/utils", "ember", "@trovedata/sunstone-ui-commons/components/progress-bar/template"], function (_exports, _template, _component, _object, _utils, _ember, _template2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var escapeExpression = _ember.default.Handlebars.Utils.escapeExpression;
  var _default = _component.default.extend({
    layout: _template2.default,
    classNames: ['progress'],
    max: 1,
    min: 0,
    value: 0,
    label: 'Complete',
    contextClass: 'success',
    contextualClass: (0, _object.computed)('contextClass', function () {
      var context = this.contextClass;
      if (!(0, _utils.isEmpty)(context)) {
        return "progress-bar-".concat(context);
      }
      return null;
    }),
    percentage: (0, _object.computed)('max', 'value', function () {
      var max = parseInt(this.max, 10);
      var value = parseInt(this.value, 10);
      return value / max * 100;
    }),
    percentageStyle: (0, _object.computed)('percentage', function () {
      var percentage = escapeExpression(this.percentage);
      return (0, _template.htmlSafe)("width: ".concat(percentage, "%;"));
    })
  });
  _exports.default = _default;
});