define("ember-svg-jar/inlined/location-map-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24.001H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M12 18a1 1 0 01-.91-.586C10.407 15.913 7 8.309 7 6c0-2.757 2.243-5 5-5s5 2.243 5 5c0 2.309-3.406 9.913-4.09 11.414A.998.998 0 0112 18zm0-15c-1.654 0-3 1.346-3 3 0 1.183 1.593 5.261 3 8.527C13.408 11.26 15 7.182 15 6c0-1.654-1.346-3-3-3z\" fill=\"#757575\"/></g><g><circle cx=\"12\" cy=\"6\" r=\"1.5\" fill=\"#757575\"/></g><g><path d=\"M19.483 21H4.517a1.996 1.996 0 01-1.669-.898 1.991 1.991 0 01-.168-1.891l2.142-4.999A1.994 1.994 0 016.659 12h.824v2h-.824l-2.143 5h14.967l-2.143-5h-.857v-2h.857c.802 0 1.523.476 1.838 1.213l2.142 4.999a1.99 1.99 0 01-.168 1.888c-.372.565-.996.9-1.669.9z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});