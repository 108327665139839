define("ember-paper/components/paper-input", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "@ember/runloop", "@ember/debug", "ember-paper/templates/components/paper-input", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin", "ember-paper/utils/invoke-action"], function (_exports, _computed, _component, _object, _utils, _runloop, _debug, _paperInput, _focusableMixin, _colorMixin, _childMixin, _validationMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperInput
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ChildMixin
   * @uses ColorMixin
   * @uses ValidationMixin
   */
  var _default = _component.default.extend(_focusableMixin.default, _colorMixin.default, _childMixin.default, _validationMixin.default, {
    layout: _paperInput.default,
    tagName: 'md-input-container',
    classNames: ['md-default-theme'],
    classNameBindings: ['hasValue:md-input-has-value', 'isInvalidAndTouched:md-input-invalid', 'hasLeftIcon:md-icon-left', 'hasRightIcon:md-icon-right', 'focused:md-input-focused', 'block:md-block', 'placeholder:md-input-has-placeholder'],
    type: 'text',
    autofocus: false,
    tabindex: null,
    hideAllMessages: false,
    isTouched: false,
    iconComponent: 'paper-icon',
    // override validation mixin `isInvalid` to account for the native input validity
    isInvalid: (0, _computed.or)('hasErrorMessages', 'isNativeInvalid'),
    hasValue: (0, _object.computed)('value', 'isNativeInvalid', function () {
      var value = this.value;
      var isNativeInvalid = this.isNativeInvalid;
      return !(0, _utils.isEmpty)(value) || isNativeInvalid;
    }),
    shouldAddPlaceholder: (0, _object.computed)('label', 'focused', function () {
      // if has label, only add placeholder when focused
      return (0, _utils.isEmpty)(this.label) || this.focused;
    }),
    inputElementId: (0, _object.computed)('elementId', {
      get: function get() {
        return "input-".concat(this.elementId);
      },
      // elementId can be set from outside and it will override the computed value.
      // Please check the deprecations for further details
      // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
      set: function set(key, value) {
        // To make sure the context updates properly, We are manually set value using @ember/object#set as recommended.
        return (0, _object.set)(this, "elementId", value);
      }
    }),
    renderCharCount: (0, _object.computed)('value', function () {
      var currentLength = this.value ? this.value.length : 0;
      return "".concat(currentLength, "/").concat(this.maxlength);
    }),
    hasLeftIcon: (0, _computed.bool)('icon'),
    hasRightIcon: (0, _computed.bool)('iconRight'),
    isInvalidAndTouched: (0, _computed.and)('isInvalid', 'isTouched'),
    validationProperty: 'value',
    // property that validations should be run on
    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      (false && !(this.onChange !== undefined) && (0, _debug.assert)('{{paper-input}} requires an `onChange` action or null for no action.', this.onChange !== undefined));
      var value = this.value,
        errors = this.errors;
      var _prevValue = this._prevValue,
        _prevErrors = this._prevErrors;
      if (value !== _prevValue || errors !== _prevErrors) {
        this.notifyValidityChange();
      }
      this._prevValue = value;
      this._prevErrors = errors;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.textarea) {
        this._growTextareaOnResize = _runloop.run.bind(this, this.growTextarea);
        window.addEventListener('resize', this._growTextareaOnResize);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      // setValue below ensures that the input value is the same as this.value
      this.setValue(this.value);
      this.growTextarea();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.textarea) {
        window.removeEventListener('resize', this._growTextareaOnResize);
        this._growTextareaOnResize = null;
      }
    },
    growTextarea: function growTextarea() {
      if (this.textarea) {
        var inputElement = this.element.querySelector('input, textarea');
        inputElement.classList.add('md-no-flex');
        inputElement.setAttribute('rows', 1);
        var minRows = this.get('passThru.rows');
        var height = this.getHeight(inputElement);
        if (minRows) {
          if (!this.lineHeight) {
            inputElement.style.minHeight = 0;
            this.lineHeight = inputElement.clientHeight;
            inputElement.style.minHeight = null;
          }
          if (this.lineHeight) {
            height = Math.max(height, this.lineHeight * minRows);
          }
          var proposedHeight = Math.round(height / this.lineHeight);
          var maxRows = this.get('passThru.maxRows') || Number.MAX_VALUE;
          var rowsToSet = Math.min(proposedHeight, maxRows);
          inputElement.style.height = "".concat(this.lineHeight * rowsToSet, "px");
          inputElement.setAttribute('rows', rowsToSet);
          if (proposedHeight >= maxRows) {
            inputElement.classList.add('md-textarea-scrollable');
          } else {
            inputElement.classList.remove('md-textarea-scrollable');
          }
        } else {
          inputElement.style.height = 'auto';
          inputElement.scrollTop = 0;
          var _height = this.getHeight(inputElement);
          if (_height) {
            inputElement.style.height = "".concat(_height, "px");
          }
        }
        inputElement.classList.remove('md-no-flex');
      }
    },
    getHeight: function getHeight(inputElement) {
      var offsetHeight = inputElement.offsetHeight;
      var line = inputElement.scrollHeight - offsetHeight;
      return offsetHeight + (line > 0 ? line : 0);
    },
    setValue: function setValue(value) {
      // normalize falsy values to empty string
      value = (0, _utils.isEmpty)(value) ? '' : value;
      if (this.element.querySelector('input, textarea').value !== value) {
        this.element.querySelector('input, textarea').value = value;
      }
    },
    actions: {
      handleInput: function handleInput(e) {
        var _this = this;
        (0, _invokeAction.invokeAction)(this, 'onChange', e.target.value);
        // setValue below ensures that the input value is the same as this.value
        _runloop.run.next(function () {
          if (_this.isDestroyed) {
            return;
          }
          _this.setValue(_this.value);
        });
        this.growTextarea();
        var inputElement = this.element.querySelector('input');
        var isNativeInvalid = inputElement && inputElement.validity && inputElement.validity.badInput;
        if (this.type === 'date' && e.target.value === '') {
          // Chrome doesn't fire the onInput event when clearing the second and third date components.
          // This means that we won't see another event when badInput becomes false if the user is clearing
          // the date field.  The reported value is empty, though, so we can already mark it as valid.
          isNativeInvalid = false;
        }
        this.set('isNativeInvalid', isNativeInvalid);
        this.notifyValidityChange();
      },
      handleBlur: function handleBlur(e) {
        (0, _invokeAction.invokeAction)(this, 'onBlur', e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }
    }
  });
  _exports.default = _default;
});