define("@trovedata/sunstone-ui-commons/adapters/report-entry", ["exports", "@ember/string", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _string, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    urlForQuery: function urlForQuery(query, modelName) {
      var baseUrl = this._buildURL(modelName);
      var endpoint;
      if (query.endpoint) {
        endpoint = query.endpoint;
        query = query.params;
      } else {
        var queryKeys = Object.keys(query);
        if (queryKeys.length === 1 && queryKeys[0] === 'projection') {
          return baseUrl;
        } else {
          var inlineKeyPosition = queryKeys.indexOf('projection');
          if (inlineKeyPosition >= 0) {
            queryKeys.splice(inlineKeyPosition, 1);
          }
          if ((0, _string.capitalize)((0, _string.camelize)(queryKeys[0])) === 'ModelReportMapId') {
            endpoint = "getBy".concat((0, _string.capitalize)((0, _string.camelize)(queryKeys[0])));
          } else {
            endpoint = "findBy".concat((0, _string.capitalize)((0, _string.camelize)(queryKeys[0])));
          }
        }
      }
      return "".concat(baseUrl, "/search/").concat(endpoint);
    }
  });
  _exports.default = _default;
});