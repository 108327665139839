define("@trovedata/sunstone-ui-commons/models/sunstone-report-parameter", ["exports", "@ember/object/computed", "@ember-data/model"], function (_exports, _computed, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    displayName: (0, _model.attr)('string'),
    dataType: (0, _model.attr)('string', {
      defaultValue: 'INTEGER'
    }),
    required: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    subreport: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    type: (0, _computed.alias)('dataType')
  });
  _exports.default = _default;
});