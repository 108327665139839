define("ember-svg-jar/inlined/cog-play", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M10 15V9l5 3z\"/><path d=\"M22 14v-4h-2.264a7.933 7.933 0 00-1.122-2.493l1.518-1.518-2.121-2.121-1.518 1.518A7.993 7.993 0 0014 4.263V2h-4v2.263a8.014 8.014 0 00-2.493 1.122L5.989 3.868 3.868 5.989l1.518 1.518A7.913 7.913 0 004.264 10H2v4h2.264a7.903 7.903 0 001.122 2.493l-1.518 1.518 2.121 2.122 1.518-1.518A7.99 7.99 0 0010 19.737V22h4v-2.263a7.968 7.968 0 002.493-1.122l1.518 1.518 2.121-2.122-1.518-1.518c.511-.749.89-1.59 1.122-2.493H22zm-10 4c-3.309 0-6-2.69-6-6 0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});