define("ember-svg-jar/inlined/location-gps-on-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M.001.002h24v24h-24z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><circle cx=\"12\" cy=\"12\" r=\"3\"/><path d=\"M22 11h-2.069A8.008 8.008 0 0013 4.069V2h-2v2.069A8.01 8.01 0 004.069 11H2v2h2.069A8.007 8.007 0 0011 19.931V22h2v-2.069A8.008 8.008 0 0019.931 13H22v-2zm-10 7c-3.309 0-6-2.692-6-6s2.691-6 6-6 6 2.692 6 6-2.691 6-6 6z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});