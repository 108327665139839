define("@trovedata/sunstone-ui-commons/components/progress-bar/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EcpIfUed",
    "block": "[[[10,0],[15,0,[29,[\"progress-bar \",[36,0]]]],[14,\"role\",\"progressbar\"],[15,\"aria-valuenow\",[29,[[36,1]]]],[15,\"aria-valuemin\",[29,[[36,2]]]],[15,\"aria-valuemax\",[29,[[36,3]]]],[15,5,[36,4]],[12],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"    \"],[18,1,[[33,8],[33,1]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"sr-only\"],[12],[1,[34,8]],[1,\"% \"],[1,[34,9]],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"&default\"],false,[\"contextualClass\",\"value\",\"min\",\"max\",\"percentageStyle\",\"if\",\"has-block\",\"yield\",\"percentage\",\"label\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/progress-bar/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});