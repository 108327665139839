define("@trovedata/sunstone-ui-commons/components/paper-progress-linear/component", ["exports", "ember-paper/components/paper-progress-linear", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin"], function (_exports, _paperProgressLinear, _troveColorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var component = _paperProgressLinear.default.extend(_troveColorMixin.default, {});
  var _default = component;
  _exports.default = _default;
});