define("ember-paper/templates/components/paper-grid-tile", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9Rg3jfI9",
    "block": "[[[10,\"figure\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"footer\"],[[50,\"paper-grid-tile-footer\",0,null,null]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-grid-tile.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});