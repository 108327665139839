define("@trovedata/sunstone-ui-commons/transforms/yn-boolean", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === 'N') {
        serialized = false;
      } else if (serialized === 'Y') {
        serialized = true;
      }
      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        deserialized = 'Y';
      } else {
        deserialized = 'N';
      }
      return deserialized;
    }
  });
  _exports.default = _default;
});