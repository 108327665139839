define("ember-svg-jar/inlined/TROVEdata-Color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill:#8dc63f}</style><g><g><g><g><g><path d=\"M122.1 78.7v-5.8h-20.4V22.8h20.4V17H98.7c-1.6 0-2.9 1.3-2.9 2.9v53H80.4c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9h15.4v53c0 1.6 1.3 2.9 2.9 2.9H122v-5.8h-20.3V78.7h20.4z\" fill=\"#fff\"/></g></g></g><g><path class=\"st1\" d=\"M39.6 42.8c11.8 0 21.4-9.6 21.4-21.4C61 9.6 51.4 0 39.6 0S18.2 9.6 18.2 21.4c0 11.8 9.6 21.4 21.4 21.4zm0-35.6c7.8 0 14.2 6.4 14.2 14.2 0 7.8-6.4 14.2-14.2 14.2s-14.2-6.4-14.2-14.2c.1-7.8 6.4-14.2 14.2-14.2zM56.4 43.9h-32C14.8 43.9 7 51.7 7 61.3v29.3c0 5.3 4.9 10 10.5 10 1.1 0 2.2-.2 3.3-.5v32c0 6.1 4.6 11.2 10.4 11.8.4 0 .7.1 1.1.1 2.9 0 5.7-1.1 7.8-3l.3-.3.3.3c2.4 2.2 5.6 3.3 8.9 3 5.8-.6 10.4-5.7 10.4-11.8v-32c1.1.3 2.2.5 3.3.5 5.6 0 10.5-4.7 10.5-10V61.3c0-9.6-7.8-17.4-17.4-17.4zm6.9 49.6c-1.7 0-3.3-1.4-3.3-2.5 0-2-1.6-3.6-3.6-3.6S52.8 89 52.8 91v41.2c0 2.4-1.7 4.4-3.8 4.6-1.3.1-2.4-.3-3.3-1.1-.9-.8-1.4-2-1.4-3.2 0-2-1.6-3.6-3.6-3.6h-.4c-2 0-3.6 1.6-3.6 3.6 0 1.2-.5 2.4-1.4 3.2-.9.8-2.1 1.2-3.3 1.1-2.2-.2-3.9-2.2-3.9-4.6V91c0-2-1.6-3.6-3.6-3.6S20.8 89 20.8 91c0 1.1-1.6 2.5-3.3 2.5-1.9 0-3.3-1.7-3.3-2.8V61.3c0-5.6 4.6-10.2 10.2-10.2h32c5.6 0 10.2 4.6 10.2 10.2v29.3c0 1.2-1.4 2.9-3.3 2.9z\"/></g></g><circle class=\"st1\" cx=\"125.8\" cy=\"75.8\" r=\"8.8\"/><circle class=\"st1\" cx=\"125.8\" cy=\"131.7\" r=\"8.8\"/><circle class=\"st1\" cx=\"125.8\" cy=\"20\" r=\"8.8\"/></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 144 144",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});