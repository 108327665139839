define("ember-svg-jar/inlined/navigation-up-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .001h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path fill=\"#757575\" d=\"M19 19.416l-7-7-7 7-1.414-1.415L12 9.587l8.414 8.414z\"/></g><g><path fill=\"#757575\" d=\"M19 14.416l-7-7-7 7-1.414-1.415L12 4.587l8.414 8.414z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});