define("@trovedata/sunstone-ui-commons/models/consumer", ["exports", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    alternateName: (0, _model.attr)('string'),
    salutation: (0, _model.attr)('string'),
    firstName: (0, _model.attr)('string'),
    middleName: (0, _model.attr)('string'),
    utilityDataCenterDetail: (0, _attributes.fragment)('utility-data-center-detail'),
    lastName: (0, _model.attr)('string'),
    companyName: (0, _model.attr)('string'),
    premise: (0, _model.belongsTo)('premise')
  });
  _exports.default = _default;
});