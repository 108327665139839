define("@trovedata/sunstone-ui-commons/components/ember-accordion/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "czFBmQDn",
    "block": "[[[18,1,[[28,[37,1],null,[[\"trigger\",\"body\"],[[50,\"ember-accordion-trigger\",0,null,[[\"isOpen\",\"toggle\"],[[33,3],[28,[37,4],[[30,0],\"toggleOpen\"],null]]]],[50,\"ember-accordion-body\",0,null,[[\"isOpen\"],[[33,3]]]]]]],[33,3]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"isOpen\",\"action\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/ember-accordion/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});