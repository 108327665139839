define("ember-paper-flatpickr/templates/components/paper-flatpickr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Jj8Tyhhg",
    "block": "[[[11,0],[24,0,\"layout layout-align-center-center\"],[24,5,\"position: relative;\"],[17,1],[12],[1,\"\\n  \\n\"],[18,3,[[28,[37,1],null,[[\"input\"],[[50,\"paper-flatpickr-input\",0,null,[[\"registerInput\",\"unregisterInput\",\"class\"],[[28,[37,3],[[30,0],\"registerInput\"],null],[28,[37,3],[[30,0],\"unregisterInput\"],null],\"layout layout-align-center-center flex\"]]]]]]]],[1,\"\\n\\n\"],[41,[28,[37,5],[[30,0,[\"input\",\"value\"]],[30,2],[28,[37,6],[[30,0,[\"input\",\"disabled\"]]],null],[30,0,[\"input\",\"disableMobile\"]]],null],[[[1,\"  \"],[10,\"button\"],[15,\"onmouseup\",[28,[37,3],[[30,0],\"clear\"],null]],[14,5,\"\\n      display: block;\\n      position: absolute;\\n      right: 0;\\n      width: 30px;\\n      height: 30px;\\n      line-height: 20px;\\n      text-align: center;\\n      border: none;\\n      border-radius: 50%;\\n      background: 0 0;\\n      padding: 0;\\n      outline:0;\\n    \"],[12],[1,\"\\n    \"],[1,[28,[35,7],[\"close\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@allowClear\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\",\"if\",\"and\",\"not\",\"paper-icon\"]]",
    "moduleName": "ember-paper-flatpickr/templates/components/paper-flatpickr.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});