define("ember-svg-jar/inlined/business-graph-bar-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M22 21.999v-13a1 1 0 00-1-1h-4a1 1 0 00-1 1v13h-1v-7a1 1 0 00-1-1h-4a1 1 0 00-1 1v7H8v-10a1 1 0 00-1-1H3a1 1 0 00-1 1v10H0v2h24v-2h-2zm-18 0v-9h2v9H4zm7 0v-6h2v6h-2zm7 0v-12h2v12h-2z\"/><path d=\"M5 7c.365 0 .704-.105.999-.277l4.002 2.286A1.998 1.998 0 0014 8.999c0-.178-.031-.346-.074-.51l4.563-4.563c.163.043.333.074.511.074a2 2 0 10-2-2c0 .178.031.348.074.511l-4.563 4.563A2.002 2.002 0 0012 7c-.365 0-.704.106-.999.277L6.999 4.991A1.999 1.999 0 003 5a2 2 0 002 2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});