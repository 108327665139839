define("ember-svg-jar/inlined/content-view-module-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .003h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M9 11.001H4c-1.104 0-2-.897-2-2v-5c0-1.102.896-2 2-2h5c1.104 0 2 .898 2 2v5c0 1.103-.896 2-2 2zm-5-7v5h4.997l.003-5H4z\" fill=\"#757575\"/></g><g><path d=\"M20 11.001h-5c-1.104 0-2-.897-2-2v-5c0-1.102.896-2 2-2h5c1.104 0 2 .898 2 2v5c0 1.103-.896 2-2 2zm-5-7v5h4.997l.003-5h-5z\" fill=\"#757575\"/></g><g><path d=\"M9 22.001H4c-1.104 0-2-.897-2-2v-5c0-1.102.896-2 2-2h5c1.104 0 2 .898 2 2v5c0 1.103-.896 2-2 2zm-5-7v5h4.997l.003-5H4z\" fill=\"#757575\"/></g><g><path d=\"M20 22.001h-5c-1.104 0-2-.897-2-2v-5c0-1.102.896-2 2-2h5c1.104 0 2 .898 2 2v5c0 1.103-.896 2-2 2zm-5-7v5h4.997l.003-5h-5z\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});