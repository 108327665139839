define("ember-paper/validators/maxlength", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.maxlength = maxlength;
  /**
   * @module ember-paper
   */

  function maxlength(value, maxlength) {
    return (0, _utils.isEmpty)(maxlength) || (0, _utils.isNone)(value) || "".concat(value).length <= parseInt(maxlength, 10);
  }
  var _default = {
    param: 'maxlength',
    message: 'Must not exceed %@ characters.',
    validate: maxlength
  };
  _exports.default = _default;
});