define("ember-cli-uuid/mixins/adapters/uuid", ["exports", "@ember/object/mixin", "ember-cli-uuid"], function (_exports, _mixin, _emberCliUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.generateIdForRecord = generateIdForRecord;
  function generateIdForRecord() {
    return (0, _emberCliUuid.uuid)();
  }
  var _default = _mixin.default.create({
    generateIdForRecord: generateIdForRecord
  });
  _exports.default = _default;
});