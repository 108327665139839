define("ember-paper/components/paper-autocomplete/no-matches-message/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AUB/CyCz",
    "block": "[[[10,\"ul\"],[14,0,\"ember-power-select-options md-autocomplete-suggestions\"],[14,\"role\",\"listbox\"],[12],[1,\"\\n  \"],[11,\"li\"],[24,0,\"ember-power-select-option ember-power-select-option--no-matches-message md-autocomplete-suggestion\"],[24,\"role\",\"option\"],[4,[38,0],[\"click\",[30,1]],null],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@onCreate\",\"@noMatchesMessage\"],false,[\"on\"]]",
    "moduleName": "ember-paper/components/paper-autocomplete/no-matches-message/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});