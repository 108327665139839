define("ember-svg-jar/inlined/data-download-9", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 .005h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M18 18.001v2H6v-2H4v2c0 1.102.896 2 2 2h12c1.104 0 2-.898 2-2v-2h-2zM11.293 15.708a.997.997 0 001.414 0l5-5A1 1 0 0017 9.001h-2v-6a1 1 0 00-1-1h-4a1 1 0 00-1 1v6H7a1 1 0 00-.707 1.707l5 5zM10 11.001a1 1 0 001-1v-6h2v6a1 1 0 001 1h.586L12 13.587l-2.586-2.586H10z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});