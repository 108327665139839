define("ember-svg-jar/inlined/business-graph-plot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M19 15a2 2 0 10-2-2c0 .011.003.021.004.032l-2.042 1.225A1.974 1.974 0 0014 14c-.465 0-.888.166-1.228.432l-1.802-.721A1.993 1.993 0 009 12a1.996 1.996 0 00-1.971 1.711L5 14.522V9.566l3.039-1.823c.286.159.61.257.961.257.178 0 .348-.031.512-.074l1.563 1.563A1.966 1.966 0 0011 10a2 2 0 104 0c0-.177-.03-.347-.074-.512l3.563-3.562c.163.044.333.074.511.074a2 2 0 10-2-2c0 .178.031.347.074.512l-3.563 3.563C13.348 8.031 13.178 8 13 8s-.347.031-.512.075l-1.563-1.563A1.92 1.92 0 0011 6a2 2 0 00-4 0c0 .011.003.021.003.032L5 7.234V3H3v17a1 1 0 001 1h17v-2H5v-2.323l2.772-1.109c.34.267.763.432 1.228.432.466 0 .889-.165 1.228-.432l1.802.721c.14.967.964 1.711 1.97 1.711a2 2 0 002-2c0-.011-.003-.021-.003-.031l2.041-1.226c.287.159.611.257.962.257z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});