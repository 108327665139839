define("ember-svg-jar/inlined/expand-horizontal-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path fill=\"#757575\" d=\"M17.707 7.293l-1.414 1.414L18.586 11H5.414l2.293-2.293-1.414-1.414L1.586 12l4.707 4.707 1.414-1.414L5.414 13h13.172l-2.293 2.293 1.414 1.414L22.414 12z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});