define("@trovedata/sunstone-ui-commons/highcharts-themes/light-theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    colors: ['#8bc34a', '#fff'],
    chart: {
      backgroundColor: 'rgba(0,0,0,0)',
      style: {
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
      },
      resetZoomButton: {
        theme: {
          fill: 'rgba(255,255,255)',
          r: 10,
          states: {
            hover: {
              fill: 'rgba(220,220,220)'
            }
          }
        }
      },
      spacingBottom: 35
    },
    title: {
      style: {
        color: '#424242',
        fontSize: '20px'
      },
      align: 'center'
    },
    subtitle: {
      style: {
        color: '#fdd835'
      },
      floating: true,
      align: 'center',
      verticalAlign: 'bottom',
      y: 20
    },
    xAxis: {
      gridLineColor: 'rgba(0, 0, 0, .5)',
      labels: {
        style: {
          color: '#424242'
        }
      },
      lineColor: 'rgba(0, 0, 0, .5)',
      minorGridLineColor: 'rgba(0, 0, 0, .5)',
      tickColor: 'rgba(0, 0, 0, .5)',
      title: {
        style: {
          color: '#424242'
        }
      }
    },
    yAxis: {
      gridLineColor: 'rgba(0, 0, 0, .5)',
      gridLineDashStyle: 'dot',
      labels: {
        style: {
          color: '#424242'
        }
      },
      lineColor: 'rgba(0, 0, 0, .5)',
      minorGridLineColor: 'rgba(0, 0, 0, .5)',
      title: {
        style: {
          color: '#424242'
        }
      }
    },
    tooltip: {
      backgroundColor: 'rgba(0, 0, 0, .7)',
      style: {
        color: 'rgba(255, 255, 255, 0.85)'
      },
      borderRadius: 18,
      borderWidth: 2,
      shadow: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          color: '#000',
          style: {
            fontSize: '14px',
            fontWeight: 'inherit',
            fontFamily: "'Roboto Condensed', Helvetica, Arial, sans-serif"
          }
        },
        marker: {
          lineColor: '#333'
        }
      },
      column: {
        borderColor: 'rgba(255, 255, 255, 0)'
      }
    },
    legend: {
      itemStyle: {
        color: '#424242'
      },
      itemHoverStyle: {
        color: '#424242'
      },
      itemHiddenStyle: {
        color: '#606063'
      }
    },
    credits: {
      style: {
        color: '#666'
      }
    },
    labels: {
      style: {
        color: '#707073'
      }
    },
    drilldown: {
      activeAxisLabelStyle: {
        color: '#F0F0F3'
      },
      activeDataLabelStyle: {
        color: '#F0F0F3'
      }
    },
    navigation: {
      buttonOptions: {
        symbolStroke: '#DDDDDD',
        theme: {
          fill: '#505053'
        }
      }
    },
    background2: '#505053',
    dataLabelsColor: '#B0B0B3',
    textColor: '#C0C0C0',
    contrastTextColor: '#F0F0F3',
    maskColor: 'rgba(255,255,255,0.3)'
  };
  _exports.default = _default;
});