define("@trovedata/sunstone-ui-commons/components/paper-content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iL9Tfs80",
    "block": "[[[11,\"md-content\"],[16,0,[29,[\"md-default-theme \",[52,[30,1],\"md-padding\"],\" \",[52,[30,2],\"md-alternative\"],\" \",[52,[30,3],\"md-has-shadow\"]]]],[16,\"md-scroll-y\",[30,4]],[16,5,[30,0,[\"styles\"]]],[17,5],[12],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@padding\",\"@alternative\",\"@shadow\",\"@scroll-y\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-content/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});