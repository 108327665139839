define("@trovedata/sunstone-ui-commons/models/sunstone-report-command", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    name: (0, _model.attr)('string'),
    description: (0, _model.attr)('string'),
    commandValue: (0, _model.attr)('string'),
    step: (0, _model.attr)('number')
  });
  _exports.default = _default;
});