define("ember-svg-jar/inlined/st-notification-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g filter=\"url(#filter0_d_262_7)\" fill=\"#F8AD42\"><path d=\"M58.17 10.83C52.25 4.91 44.374 1.65 36 1.65c-8.375 0-16.246 3.26-22.17 9.18-5.923 5.92-9.18 13.795-9.18 22.167 0 8.372 3.26 16.246 9.18 22.17 5.92 5.923 13.795 9.18 22.17 9.18 8.375 0 16.246-3.26 22.17-9.18 5.923-5.921 9.18-13.795 9.18-22.17 0-8.376-3.26-16.246-9.18-22.166zM36 61.05C20.533 61.05 7.95 48.467 7.95 33S20.533 4.95 36 4.95 64.05 17.533 64.05 33 51.467 61.05 36 61.05z\"/><path d=\"M36 57.75c-.91 0-1.65-.74-1.65-1.65v-3.3a1.65 1.65 0 013.3 0v3.3c0 .91-.74 1.65-1.65 1.65zM36 47.85c-.91 0-1.65-.74-1.65-1.65v-33a1.65 1.65 0 013.3 0v33c0 .91-.74 1.65-1.65 1.65z\"/></g><defs><filter id=\"filter0_d_262_7\" x=\"-1\" y=\"0\" width=\"74\" height=\"74\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/><feOffset dy=\"4\"/><feGaussianBlur stdDeviation=\"2\"/><feComposite in2=\"hardAlpha\" operator=\"out\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_262_7\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_262_7\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "72",
      "height": "73",
      "viewBox": "0 0 72 73",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});