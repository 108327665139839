define("ember-paper/templates/components/paper-radio-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eDPwdHw2",
    "block": "[[[18,1,[[28,[37,1],null,[[\"label\",\"radio\"],[[50,[33,3],0,null,[[\"setAriaLabelledby\"],[[28,[37,4],[[30,0],[28,[37,5],[[33,6]],null]],null]]]],[50,[33,7],0,null,[[\"toggle\",\"disabled\",\"groupValue\",\"onChange\",\"parentComponent\",\"shouldRegister\"],[[33,8],[33,9],[33,10],[28,[37,4],[[30,0],\"onChange\"],null],[30,0],true]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"labelComponent\",\"action\",\"mut\",\"ariaLabelledby\",\"radioComponent\",\"toggle\",\"disabled\",\"groupValue\"]]",
    "moduleName": "ember-paper/templates/components/paper-radio-group.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});