define("@trovedata/sunstone-ui-commons/adapters/segment-set", ["exports", "@trovedata/sunstone-ui-commons/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName) {
      this._super.apply(this, arguments);
      var url = this._buildURL(modelName);
      return "".concat(url, "?async=true");
    }
  });
  _exports.default = _default;
});