define("@trovedata/sunstone-ui-commons/components/trove-button/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "014L2WYo",
    "block": "[[[41,[28,[37,1],[[30,0,[\"isPending\"]],[30,1]],null],[[[1,\"  \"],[10,0],[14,0,\"dot-loader\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dot-1\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"dot-2\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"dot-3\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[34,4]],[1,\"\\n\"]],[]]]],[]]],[41,[33,5],[[[1,\"  \"],[8,[39,6],null,[[\"@position\"],[[99,7,[\"@position\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[34,5]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@loading\",\"&default\"],false,[\"if\",\"or\",\"has-block\",\"yield\",\"text\",\"tooltip\",\"paper-tooltip\",\"side\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/trove-button/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});