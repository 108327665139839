define("@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/card-list-item-actions/component", ["exports", "@ember/component", "@trovedata/sunstone-ui-commons/components/card-list/card-list-item/card-list-item-content/card-list-item-actions/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: 'ul',
    classNames: ['card-actions']
  });
  _exports.default = _default;
});