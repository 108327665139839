define("@trovedata/sunstone-ui-commons/components/ember-accordion/component", ["exports", "@ember/component", "@ember/object", "@trovedata/sunstone-ui-commons/components/ember-accordion/template"], function (_exports, _component, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _template.default,
    tagName: '',
    isOpen: true,
    actions: {
      toggleOpen: function toggleOpen() {
        var action = this.action;
        var toggledValue = !this.isOpen;
        if (typeof action === 'function') {
          action(toggledValue);
        } else {
          (0, _object.set)(this, 'isOpen', toggledValue);
        }
      }
    }
  });
  _exports.default = _default;
});