define("@trovedata/sunstone-ui-commons/models/trove-export-request", ["exports", "@ember/object", "@ember/array", "@ember-data/model", "ember-data-model-fragments/attributes"], function (_exports, _object, _array, _model, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    packages: (0, _model.hasMany)('trove-package'),
    title: (0, _model.attr)('string'),
    expiresOn: (0, _model.attr)('basic-date'),
    fileType: (0, _model.attr)('string'),
    bytesPerFile: (0, _model.attr)('number'),
    status: (0, _model.attr)('string', {
      defaultValue: 'PENDING'
    }),
    statusMessage: (0, _model.attr)('string'),
    percentComplete: (0, _model.attr)('number'),
    filters: (0, _attributes.fragmentArray)('boundry-filters'),
    files: (0, _model.hasMany)('trove-export-file'),
    init: function init() {
      this._super.apply(this, arguments);
      (0, _object.set)(this, 'downloadRequests', (0, _array.A)([]));
    },
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    filesSorting: ['status:desc', 'filename'],
    sortedFiles: _object.computed.sort('files', 'filesSorting'),
    isDownloading: (0, _object.computed)('downloadRequests.@each.status', function () {
      return this.downloadRequests.any(function (downloadReq) {
        return downloadReq.status === 'PROCESSING' || downloadReq.status === 'PENDING';
      });
    }),
    isProcessing: (0, _object.computed)('status', function () {
      return this.status === 'PROCESSING' || this.status === 'PENDING' || this.status === 'PREPARING';
    }),
    isFinished: (0, _object.computed)('status', function () {
      return this.status === 'COMPLETE' || this.status === 'ERROR';
    })
  });
  _exports.default = _default;
});