define("@trovedata/sunstone-ui-commons/components/paper-range-slider/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wKZSSJUv",
    "block": "[[[10,0],[14,0,\"md-slider-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-slider-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"md-track-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"md-track\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-track md-track-fill\"],[15,5,[36,0]],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-track-ticks\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"md-thumb-container\"],[15,5,[36,1]],[12],[1,\"\\n      \"],[10,0],[14,0,\"md-thumb\"],[14,5,\"touch-action: none\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-thumb\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-ring md-min\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-sign\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"md-thumb-text\"],[12],[1,[34,2]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-disabled-thumb\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"md-thumb-container\"],[15,5,[36,3]],[12],[1,\"\\n      \"],[10,0],[14,0,\"md-thumb\"],[14,5,\"touch-action: none\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-thumb\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-focus-ring md-max\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-sign\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"md-thumb-text\"],[12],[1,[34,4]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"md-disabled-thumb\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"activeTrackStyle\",\"thumbContainerStyleMin\",\"minValue\",\"thumbContainerStyleMax\",\"maxValue\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/paper-range-slider/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});