define("ember-svg-jar/inlined/settings-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M19 8.999c1.654 0 3-1.346 3-3s-1.346-3-3-3a2.993 2.993 0 00-2.815 2H2v2h14.185a2.993 2.993 0 002.815 2zm0-4a1 1 0 110 2 1 1 0 010-2zM9 8.999a2.993 2.993 0 00-2.815 2H2v2h4.185c.414 1.162 1.514 2 2.815 2s2.401-.838 2.815-2H22v-2H11.815a2.993 2.993 0 00-2.815-2zm0 4a1 1 0 110-2 1 1 0 010 2zM19 14.999a2.993 2.993 0 00-2.815 2H2v2h14.185a2.994 2.994 0 002.815 2c1.654 0 3-1.346 3-3s-1.346-3-3-3zm0 4a1 1 0 110-2 1 1 0 010 2z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});