define("ember-svg-jar/inlined/server-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M20 2H4a2 2 0 00-2 2v16c0 1.103.896 2 2 2h16c1.104 0 2-.897 2-2V4a2 2 0 00-2-2zm0 2l-.001 4H4V4h16zm-.001 6l-.001 4H4v-4h15.999zM4 20v-4h15.998l-.001 4H4z\"/><path d=\"M5 5h5v2H5zM5 11h5v2H5zM5 17h5v2H5z\"/><circle cx=\"15\" cy=\"6\" r=\"1\"/><circle cx=\"18\" cy=\"6\" r=\"1\"/><circle cx=\"15\" cy=\"12\" r=\"1\"/><circle cx=\"18\" cy=\"12\" r=\"1\"/><circle cx=\"15\" cy=\"18\" r=\"1\"/><circle cx=\"18\" cy=\"18\" r=\"1\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});