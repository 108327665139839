define("ember-paper-flatpickr/components/paper-flatpickr-input", ["exports", "ember-paper/components/paper-input", "ember-flatpickr/mixins/flatpickr", "ember-diff-attrs", "ember-invoke-action", "@ember/debug", "@ember/polyfills", "@ember/runloop", "@ember/application"], function (_exports, _paperInput, _flatpickr, _emberDiffAttrs, _emberInvokeAction, _debug, _polyfills, _runloop, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _paperInput.default.extend(_flatpickr.default, {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var field = this.element.querySelector("input");
      (0, _emberInvokeAction.invokeAction)(this, "registerInput", this);
      this.set("field", field);
      this.setupFlatpickr();
    },
    focusOut: function focusOut() {
      this.set('focused', this.flatpickrRef.isOpen);
    },
    setupFlatpickr: function setupFlatpickr() {
      // Require that users pass a date
      (false && !(this.get("date") !== undefined) && (0, _debug.assert)("{{ember-flatpickr}} requires a `date` to be passed as the value for flatpickr.", this.get("date") !== undefined)); // Require that users pass an onChange
      (false && !(this.get("onChange") !== undefined) && (0, _debug.assert)("{{ember-flatpickr}} requires an `onChange` action or null for no action.", this.get("onChange") !== undefined)); // Pass all values and setup flatpickr
      _runloop.run.scheduleOnce("afterRender", this, function () {
        var fastboot = (0, _application.getOwner)(this).lookup("service:fastboot");
        if (fastboot && fastboot.isFastBoot) {
          return;
        }
        var options = this.getProperties(Object.keys(this.attrs));

        // Add defaultDate, change and close handlers
        (0, _polyfills.assign)(options, {
          inline: this.inline || options.inline,
          defaultDate: this.get("value"),
          onChange: this._onChange.bind(this),
          onClose: this._onClose.bind(this),
          onOpen: this._onOpen.bind(this),
          onReady: this._onReady.bind(this)
        });
        var flatpickrRef = flatpickr(this.field, options);
        if (this.get("appendDataInput")) {
          this.field.setAttribute("data-input", "");
        }
        this._setDisabled(this.get("disabled"));
        this.set("flatpickrRef", flatpickrRef);
      });
    },
    didReceiveAttrs: (0, _emberDiffAttrs.default)("altFormat", "value", "disabled", "locale", "maxDate", "minDate", function (changedAttrs) {
      var _this = this;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      this._super.apply(this, args);
      this._attributeHasChanged(changedAttrs, "altFormat", function (newAltFormat) {
        _this.field._flatpickr.set("altFormat", newAltFormat);
      });
      this._attributeHasChanged(changedAttrs, "value", function (newValue) {
        if (typeof newValue !== "undefined") {
          _this.field._flatpickr.setDate(newValue);
        }
      });
      this._attributeHasChanged(changedAttrs, "disabled", function (newDisabled) {
        if (typeof newDisabled !== "undefined") {
          _this._setDisabled(newDisabled);
        }
      });
      this._attributeHasChanged(changedAttrs, "locale", function () {
        _this.field._flatpickr.destroy();
        _this.setupComponent();
      });
      this._attributeHasChanged(changedAttrs, "maxDate", function (newMaxDate) {
        _this.field._flatpickr.set("maxDate", newMaxDate);
      });
      this._attributeHasChanged(changedAttrs, "minDate", function (newMinDate) {
        _this.field._flatpickr.set("minDate", newMinDate);
      });
    }),
    _attributeHasChanged: function _attributeHasChanged(changedAttrs, attr, callback) {
      if (changedAttrs && changedAttrs[attr]) {
        var _changedAttrs$attr = _slicedToArray(changedAttrs[attr], 2),
          oldAttr = _changedAttrs$attr[0],
          newAttr = _changedAttrs$attr[1];
        if (oldAttr !== newAttr) {
          callback(newAttr);
        }
      }
    },
    _onChange: function _onChange(selectedDates, dateStr, instance) {
      if (this.onChange instanceof Function) {
        (0, _emberInvokeAction.invokeAction)(this, "onChange", selectedDates, dateStr, instance);
      }
    },
    _onOpen: function _onOpen(selectedDates, dateStr, instance) {
      (0, _emberInvokeAction.invokeAction)(this, "onOpen", selectedDates, dateStr, instance);
      this.set("isTouched", true);
      this.notifyValidityChange();
    },
    _onClose: function _onClose(selectedDates, dateStr, instance) {
      if (this.mode === "range" && selectedDates.length < 2) {
        (0, _emberInvokeAction.invokeAction)(this, "onChange", null);
      }
      this.set('focused', this.flatpickrRef.isOpen);
      (0, _emberInvokeAction.invokeAction)(this, "onClose", selectedDates, dateStr, instance);
      this.set("isTouched", true);
      this.notifyValidityChange();
    },
    actions: {
      clear: function clear(e) {
        this.set("isTouched", true);
        this.notifyValidityChange();
        this.field._flatpickr.clear();
      },
      handleInput: function handleInput(e) {
        var _this2 = this;
        /*
              let flatpickr = this.field._flatpickr;
        
              invokeAction(
                this,
                "onChange",
                flatpickr.selectedDates,
                flatpickr.altInput.value || flatpickr.input.value,
                flatpickr
              );
         */
        // setValue below ensures that the input value is the same as this.value
        _runloop.run.next(function () {
          if (_this2.isDestroyed) {
            return;
          }
          _this2.setValue(_this2.get("value"));
        });
        this.growTextarea();
        var inputElement = this.element.querySelector("input");
        this.set("isNativeInvalid", inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      (0, _emberInvokeAction.invokeAction)(this, "unregisterInput");
    }
  });
  _exports.default = _default;
});