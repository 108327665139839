define("@trovedata/sunstone-ui-commons/utils/serialize-query-params0", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = serializeQueryParams;
  function serializeQueryParams(params) {
    return Object.keys(params).reduce(function (query, key) {
      var connector = query === '' ? '' : '&';
      return "".concat(query).concat(connector).concat(key, "=").concat(params[key]);
    }, '');
  }
});