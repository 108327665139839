define("@trovedata/sunstone-ui-commons/components/trove-icon/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FFkrez6l",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[28,[35,2],[[33,1]],[[\"height\",\"width\"],[[33,3],[33,3]]]]],[1,\"\\n\"]],[]],null],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"iconClass\",\"svg-jar\",\"svgSize\",\"yield\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/trove-icon/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});