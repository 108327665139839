define("ember-paper/components/paper-form", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember-paper/templates/components/paper-form", "ember-paper/mixins/parent-mixin", "ember-paper/utils/invoke-action"], function (_exports, _computed, _component, _object, _paperForm, _parentMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperForm
   * @extends Ember.Component
   * @uses ParentMixin
   */
  var _default = _component.default.extend(_parentMixin.default, {
    layout: _paperForm.default,
    tagName: 'form',
    inputComponent: 'paper-input',
    submitButtonComponent: 'paper-button',
    selectComponent: 'paper-select',
    autocompleteComponent: 'paper-autocomplete',
    isValid: (0, _computed.not)('isInvalid'),
    isInvalid: (0, _object.computed)('childComponents.@each.isInvalid', function () {
      return this.childComponents.isAny('isInvalid');
    }),
    isTouched: (0, _object.computed)('childComponents.@each.isTouched', function () {
      return this.childComponents.isAny('isTouched');
    }),
    isInvalidAndTouched: (0, _computed.and)('isInvalid', 'isTouched'),
    submit: function submit() {
      this.send('onSubmit');
      return false;
    },
    actions: {
      onValidityChange: function onValidityChange() {
        if (this.lastIsValid !== this.isValid || this.lastIsTouched !== this.isTouched) {
          (0, _invokeAction.invokeAction)(this, 'onValidityChange', this.isValid, this.isTouched, this.isInvalidAndTouched);
          this.set('lastIsValid', this.isValid);
          this.set('lastIsTouched', this.isTouched);
        }
      },
      onSubmit: function onSubmit() {
        if (this.isInvalid) {
          this.childComponents.setEach('isTouched', true);
          (0, _invokeAction.invokeAction)(this, 'onInvalid');
        } else {
          (0, _invokeAction.invokeAction)(this, 'onSubmit');
          this.childComponents.setEach('isTouched', false);
        }
      }
    }
  });
  _exports.default = _default;
});