define("ember-svg-jar/inlined/user-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><path d=\"M20.372 19.059a3.483 3.483 0 001.129-2.559c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.013.439 1.918 1.129 2.559-1.478.534-2.629 1.562-2.629 2.941h2c0-.598 1.413-1.357 3-1.357s3 .76 3 1.357h2c0-1.38-1.151-2.407-2.629-2.941zM18.001 15c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5-1.5-.673-1.5-1.5.673-1.5 1.5-1.5zM8.372 19.059A3.483 3.483 0 009.501 16.5c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.013.439 1.918 1.129 2.559-1.478.534-2.629 1.561-2.629 2.941h2c0-.598 1.413-1.357 3-1.357s3 .76 3 1.357h2c0-1.38-1.151-2.407-2.629-2.941zM6.001 15c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5-1.5-.673-1.5-1.5.673-1.5 1.5-1.5zM15.001 11h2c0-1.38-1.151-2.407-2.629-2.941A3.483 3.483 0 0015.501 5.5c0-1.93-1.57-3.5-3.5-3.5s-3.5 1.57-3.5 3.5c0 1.013.439 1.918 1.129 2.559C8.152 8.593 7.001 9.62 7.001 11h2c0-.598 1.413-1.357 3-1.357s3 .759 3 1.357zm-3-7c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5-1.5-.673-1.5-1.5.673-1.5 1.5-1.5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});