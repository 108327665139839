define("ember-paper/validators/minlength", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.minlength = minlength;
  /**
   * @module ember-paper
   */

  function minlength(value, minlength) {
    return (0, _utils.isEmpty)(minlength) || (0, _utils.isNone)(value) || "".concat(value).length >= parseInt(minlength, 10);
  }
  var _default = {
    param: 'minlength',
    message: 'Must have at least %@ characters.',
    validate: minlength
  };
  _exports.default = _default;
});