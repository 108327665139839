define("@trovedata/sunstone-ui-commons/serializers/application", ["exports", "@ember/string", "@trovedata/sunstone-ui-commons/serializers/hal-serializer"], function (_exports, _string, _halSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable no-prototype-builtins */

  var COMPLIANCE_MAP = {
    lastUpdateBy: true,
    insertBy: true,
    orgId: true
  };
  var _default = _halSerializer.default.extend({
    normalizeResponse: function normalizeResponse(store, typeClass, payload, id, requestType) {
      if (payload.content) {
        var data = payload.content;
        data._links = payload._links;
        payload = data;
      }
      return this._super(store, typeClass, payload, id, requestType);
    },
    extractRelationships: function extractRelationships(modelClass, payload) {
      var _this = this;
      modelClass.eachRelationship(function (key) {
        var relationshipKey = _this.keyForRelationship(key);
        if (payload.hasOwnProperty(relationshipKey)) {
          payload._embedded = payload._embedded || {};
          payload._embedded[relationshipKey] = payload[relationshipKey];
        }
      });
      return this._super.apply(this, arguments);
    },
    extractMeta: function extractMeta(store, typeClass, payload) {
      if (payload && payload.hasOwnProperty('page')) {
        var meta = payload.page;
        delete payload.page;
        return meta;
      }
    },
    keyForAttribute: function keyForAttribute(attr) {
      return (0, _string.camelize)(attr);
    },
    keyForRelationship: function keyForRelationship(attr) {
      return (0, _string.camelize)(attr);
    },
    serialize: function serialize(snapshot, options) {
      var _this2 = this;
      var json = {};
      if (options && options.includeId) {
        var id = snapshot.id;
        if (id) {
          json[this.primaryKey] = id;
        }
      }
      snapshot.eachAttribute(function (name, attribute) {
        _this2.serializeAttribute(snapshot, json, name, attribute);
      });
      snapshot.eachRelationship(function (name, relationship) {
        if (!relationship.options.async && relationship.options.async !== undefined) {
          var relationshipKind = relationship.kind;
          var relationshipMethod = "serialize".concat((0, _string.capitalize)(relationshipKind));
          _this2[relationshipMethod](snapshot, json, relationship);
        } else {
          var adapter = _this2.store.adapterFor(relationship.type);
          if (relationship.kind === 'belongsTo') {
            var belongsTo = snapshot.belongsTo(name);
            if (belongsTo) {
              json[name] = COMPLIANCE_MAP[relationship.name] || relationship.options && relationship.options.id ? belongsTo.record.id : adapter.buildURL(relationship.type.modelName || relationship.type, belongsTo.record.id);
            }
          }
          if (relationship.kind === 'hasMany') {
            var _relationship$options;
            var hasMany = snapshot.hasMany(name);
            if (hasMany && ((_relationship$options = relationship.options) === null || _relationship$options === void 0 ? void 0 : _relationship$options.serialize) !== false) {
              var existingHasManyRelationships = hasMany.filter(function (hasManyRelationship) {
                return !hasManyRelationship.record.get('isNew');
              });
              json[name] = existingHasManyRelationships.map(function (hasManyRelationship) {
                return adapter.buildURL(hasManyRelationship.type.modelName || hasManyRelationship.type, hasManyRelationship.record.id);
              });
            }
          }
        }
      });
      return json;
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (this._canSerialize(key)) {
        var payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key) {
          payloadKey = this.keyForAttribute(key, 'serialize');
        }
        this._super.apply(this, arguments);
        json[payloadKey] = json.attributes[payloadKey];
        delete json.attributes;
      }
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key;
      if (this._shouldSerializeHasMany(snapshot, key, relationship)) {
        this._serializeRelationship.apply(this, arguments);
      }
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;
      if (this._canSerialize(key)) {
        this._serializeRelationship.apply(this, arguments);
      }
    },
    _serializeRelationship: function _serializeRelationship(snapshot, json, relationship) {
      var key = relationship.key,
        kind = relationship.kind;
      var relation = snapshot[kind](key);
      var data = null;
      if (relation !== undefined) {
        var payloadKey = this._getMappedKey(key, snapshot.type);
        if (payloadKey === key && this.keyForRelationship) {
          payloadKey = this.keyForRelationship(key, kind, 'serialize');
        }
        if (kind === 'hasMany') {
          data = relation.map(function (item) {
            return item.serialize({
              includeId: true
            });
          });
        } else {
          if (relation) {
            if (this._hasSerializeRecordsOption(key)) {
              data = relation.serialize({
                includeId: true
              });
            } else {
              data = relation.id;
            }
          }
        }
        json[payloadKey] = data;
      }
    },
    _hasEmbeddedAlwaysOption: function _hasEmbeddedAlwaysOption(key) {
      var option = this.get("attrs.".concat(key));
      return option && option.embedded === 'always';
    },
    _hasSerializeRecordsOption: function _hasSerializeRecordsOption(key) {
      var alwaysEmbed = this._hasEmbeddedAlwaysOption(key);
      var option = this.get("attrs.".concat(key));
      return alwaysEmbed || option && option.serialize === 'records';
    }
  });
  _exports.default = _default;
});