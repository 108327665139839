define("@trovedata/sunstone-ui-commons/models/data-pill", ["exports", "@ember/object/computed", "rsvp", "@ember/array", "@ember/object", "@ember-data/model", "ember-data-model-fragments/fragment", "@trovedata/sunstone-ui-commons/utils/verify-enum-value", "ember-data-model-fragments/attributes"], function (_exports, _computed, _rsvp, _array, _object, _model, _fragment, _verifyEnumValue, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-get */
  var _default = _fragment.default.extend({
    dataFieldId: (0, _model.attr)('string'),
    indexerId: (0, _model.attr)('number', {
      defaultValue: 1
    }),
    name: (0, _model.attr)('string'),
    displayName: (0, _model.attr)('string'),
    dataType: (0, _model.attr)('string'),
    defaultThirdPartyField: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    visible: (0, _model.attr)('boolean', {
      defaultValue: true
    }),
    filters: (0, _attributes.fragmentArray)('data-field-filter'),
    rollbackPill: null,
    reloadFilters: null,
    score: (0, _model.attr)('boolean', {
      defaultValue: false
    }),
    statistics: (0, _array.A)(),
    init: function init() {
      this._super.apply(this, arguments);
      (0, _object.set)(this, 'statistics', (0, _array.A)([]));
    },
    isDefault: (0, _computed.bool)('defaultThirdPartyField'),
    isEnum: (0, _object.computed)('statistics.[]', function () {
      return this.statistics.isAny('enumerationId');
    }),
    enumValueType: (0, _object.computed)('dataType', 'enumValues.[]', function () {
      var _this = this;
      return this.enumValues.then(function (enumValues) {
        var valType = (0, _array.A)(enumValues.map(function (val) {
          return (0, _verifyEnumValue.default)(val, _this.dataType);
        })).uniq();
        return valType.length === 1 ? valType[0] : null;
      });
    }),
    // The enum statistic is assumed to be the first for now
    enumValues: (0, _object.computed)('isEnum', 'statistics', 'store', function () {
      if (this.isEnum) {
        var enumStat = (0, _array.A)(this.statistics.filterBy('enumerationId'));
        return this.store.findRecord('enum', enumStat.get('firstObject.enumerationId')).then(function (enumType) {
          return (0, _object.get)(enumType, 'enumValues').then(function (values) {
            return values;
          });
        });
      } else {
        return new _rsvp.Promise(function (resolve) {
          resolve((0, _array.A)([]));
        });
      }
    }),
    updateFilterPositions: function updateFilterPositions() {
      var _this2 = this;
      var promise = new _rsvp.Promise(function (resolve) {
        _this2.filters.forEach(function (filter, index) {
          (0, _object.set)(filter, 'position', index + 1);
        });
        resolve();
      });
      return promise;
    },
    addFilter: function addFilter() {
      var filter = {
        usedEnumValue: (0, _object.get)(this, 'enumValueType._result'),
        type: this.dataType
      };
      this.filters.addFragment(filter);
      return this.updateFilterPositions();
    },
    deserialize: function deserialize() {
      var _this3 = this;
      (0, _object.set)(this, 'reloadFilters', this.filters.copy());
      var filters = this.filters;
      return this.enumValueType.then(function (enumValueType) {
        if (_this3.isEnum && enumValueType) {
          return _this3.enumValues.then(function (enumValues) {
            filters.map(function (filter) {
              var maxValues = (0, _object.get)(filter, 'maxValues').map(function (value) {
                return enumValueType === 'numValue' ? enumValues.findBy("".concat(enumValueType), JSON.parse(value)) : enumValues.findBy("".concat(enumValueType), value);
              });
              var minValues = (0, _object.get)(filter, 'minValues').map(function (value) {
                return enumValueType === 'numValue' ? enumValues.findBy("".concat(enumValueType), JSON.parse(value)) : enumValues.findBy("".concat(enumValueType), value);
              });
              (0, _object.set)(filter, 'maxValues', maxValues);
              (0, _object.set)(filter, 'minValues', minValues);
              (0, _object.set)(filter, 'usedEnumValue', enumValueType);
            });
            (0, _object.set)(_this3, 'rollbackPill', JSON.stringify(_this3));
            return _this3.updateFilterPositions();
          });
        } else {
          (0, _object.set)(_this3, 'rollbackPill', JSON.stringify(_this3));
          return _this3.updateFilterPositions();
        }
      });
    },
    regenerateFilter: function regenerateFilter(filters) {
      var _this4 = this;
      return this.enumValueType.then(function (enumValueType) {
        if (_this4.isEnum && enumValueType) {
          return _this4.enumValues.then(function (enumValues) {
            filters.map(function (filter) {
              var maxValues = (0, _object.get)(filter, 'maxValues').map(function (value) {
                return enumValueType === 'numValue' ? enumValues.findBy("".concat(enumValueType), JSON.parse(value)) : enumValues.findBy("".concat(enumValueType), value);
              });
              var minValues = (0, _object.get)(filter, 'minValues').map(function (value) {
                return enumValueType === 'numValue' ? enumValues.findBy("".concat(enumValueType), JSON.parse(value)) : enumValues.findBy("".concat(enumValueType), value);
              });
              (0, _object.set)(filter, 'maxValues', maxValues);
              (0, _object.set)(filter, 'minValues', minValues);
            });
            return _this4.updateFilterPositions();
          });
        } else {
          filters.map(function (filter) {
            var regeneratedfilter = {
              type: _this4.dataType,
              operatorName: filter.operatorName,
              maxValues: (0, _array.A)(filter.maxValues),
              minValues: (0, _array.A)(filter.minValues)
            };
            _this4.filters.addFragment(regeneratedfilter);
          });
          return _this4.updateFilterPositions();
        }
      });
    },
    removeFilter: function removeFilter(position) {
      this.filters.removeAt(position - 1);
      return this.updateFilterPositions();
    },
    serializeFilters: function serializeFilters() {
      var _this5 = this;
      var promise = new _rsvp.Promise(function (resolve) {
        var filters = _this5.filters.map(function (filter) {
          return filter.serialize();
        });
        resolve(filters);
      });
      return promise;
    }
  });
  _exports.default = _default;
});