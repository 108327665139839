define("ember-svg-jar/inlined/plug-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g fill=\"#757575\"><circle cx=\"12\" cy=\"12.5\" r=\"1.5\"/><path d=\"M18 5h-2V2h-2v3h-4V2H8v3H6c-1.103 0-2 .896-2 2v3c0 4.072 3.06 7.436 7 7.931V21h2v-3.069c3.94-.495 7-3.858 7-7.931V7c0-1.104-.897-2-2-2zm0 2v1H6V7h12zm-6 9c-3.309 0-6-2.691-6-6h12c0 3.309-2.691 6-6 6z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});