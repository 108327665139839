define("@trovedata/sunstone-ui-commons/utils/byte-converter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = byteConverter;
  function byteConverter(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return [0, sizes[0]];
    } else if (!bytes) {
      return [0, ''];
    } else {
      // @ts-ignore
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      if (i == 0) {
        return [bytes, sizes[i]];
      } else {
        return [parseFloat((bytes / Math.pow(1024, i)).toFixed(1)), sizes[i]];
      }
    }
  }
});