define("ember-paper/components/paper-speed-dial", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-paper/templates/components/paper-speed-dial", "ember-paper/utils/invoke-action"], function (_exports, _component, _object, _runloop, _paperSpeedDial, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    layout: _paperSpeedDial.default,
    tagName: 'md-fab-speed-dial',
    classNameBindings: ['directionClass', 'open:md-is-open', 'animationClass', 'shouldHideActions:md-animations-waiting', 'hoverFull:md-hover-full'],
    open: false,
    animation: 'fling',
    animationClass: (0, _object.computed)('animation', function () {
      return "md-".concat(this.animation);
    }),
    direction: 'down',
    directionClass: (0, _object.computed)('direction', function () {
      return "md-".concat(this.direction);
    }),
    shouldHideActions: (0, _object.computed)('animation', 'elementDidRender', function () {
      return this.animation === 'fling' && !this.elementDidRender;
    }),
    _mouseEnterHandler: undefined,
    _mouseLeaveHandler: undefined,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._mouseEnterHandler = this.handleMouseEnter.bind(this);
      this._mouseLeaveHandler = this.handleMouseLeave.bind(this);
      this.element.addEventListener('mouseenter', this._mouseEnterHandler);
      this.element.addEventListener('mouseleave', this._mouseLeaveHandler);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.element.removeEventListener('mouseenter', this._mouseEnterHandler);
      this.element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      this._mouseEnterHandler = undefined;
      this._mouseLeaveHandler = undefined;
    },
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      _runloop.run.next(function () {
        if (!_this.isDestroyed && !_this.isDestroying) {
          _this.set('elementDidRender', true);
        }
      });
    },
    handleMouseEnter: function handleMouseEnter() {
      (0, _invokeAction.invokeAction)(this, 'onMouseEnter');
    },
    handleMouseLeave: function handleMouseLeave() {
      (0, _invokeAction.invokeAction)(this, 'onMouseLeave');
    },
    toggle: function toggle() {
      this.changeOpenValue(!this.open);
    },
    close: function close() {
      this.changeOpenValue(false);
    },
    changeOpenValue: function changeOpenValue(value) {
      // support non DDAU scenario
      if (this.onToggle) {
        (0, _invokeAction.invokeAction)(this, 'onToggle', value);
      } else {
        this.set('open', value);
      }
    }
  });
  _exports.default = _default;
});