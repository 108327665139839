define("ember-paper/templates/components/paper-backdrop", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "J+4Fl5+R",
    "block": "[[[11,\"md-backdrop\"],[16,0,[29,[\"md-default-theme ng-enter \",[52,[30,1],\"md-opaque\"],\" \",[52,[30,2],\"md-locked-open\"]]]],[16,5,[52,[30,3],\"position:fixed;\"]],[17,4],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"sendClickAction\"]],[30,5]],null]],null],[4,[38,1],[\"touchEnd\",[28,[37,2],[[30,0,[\"sendClickAction\"]],[30,5]],null]],null],[4,[38,3],null,[[\"enterClass\",\"enterActiveClass\",\"enterToClass\",\"leaveClass\",\"leaveActiveClass\",\"leaveToClass\"],[\"ng-enter\",\"ng-enter-active\",\"ng-dummyclass\",\"ng-dummyclass\",\"ng-leave\",\"ng-leave-active\"]]],[12],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@opaque\",\"@isLockedOpen\",\"@fixed\",\"&attrs\",\"@onClick\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"css-transition\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-backdrop.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});