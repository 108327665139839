define("ember-paper/components/paper-menu/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HtMKR2Yr",
    "block": "[[[8,[39,0],[[17,1]],[[\"@disabled\",\"@triggerComponent\",\"@contentComponent\",\"@calculatePosition\",\"@onClose\",\"@onOpen\"],[[30,2],\"paper-menu/trigger\",\"paper-menu/content\",[30,0,[\"calculatePosition\"]],[30,0,[\"close\"]],[30,0,[\"open\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,4,[[28,[37,2],null,[[\"trigger\",\"content\"],[[30,3,[\"Trigger\"]],[30,3,[\"Content\"]]]]]]],[1,\"\\n\"]],[3]]]]]],[\"&attrs\",\"@disabled\",\"dd\",\"&default\"],false,[\"basic-dropdown\",\"yield\",\"hash\"]]",
    "moduleName": "ember-paper/components/paper-menu/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});