define("@trovedata/sunstone-ui-commons/components/trove-spinner/component", ["exports", "@ember/object", "@ember/component", "@trovedata/sunstone-ui-commons/components/trove-spinner/template", "@trovedata/sunstone-ui-commons/mixins/trove-color-mixin"], function (_exports, _object, _component, _template, _troveColorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_troveColorMixin.default, {
    layout: _template.default,
    tagName: 'svg',
    classNames: ['progress-spinner'],
    attributeBindings: ['width', 'height', 'xmlns', 'viewBox', 'preserveAspectRatio'],
    diameter: 50,
    width: (0, _object.computed)('diameter', function () {
      return "".concat(this.diameter, "px");
    }),
    strokeWidth: (0, _object.computed)('diameter', function () {
      var diameter = parseInt(this.diameter);
      return .00222222 * Math.pow(diameter, 2) - .355556 * diameter + 22.2222;
    }),
    height: (0, _object.computed)('diameter', function () {
      return "".concat(this.diameter, "px");
    }),
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 100 100',
    preserveAspectRatio: 'xMinYMin meet',
    percent: null,
    centerOffset: (0, _object.computed)('displayText', function () {
      var displayText = parseInt(this.displayText);
      return displayText < 10 ? '44' : displayText === 100 ? '30' : '38';
    }),
    displayText: (0, _object.computed)('percent', function () {
      var percent = this.percent;
      if (!isNaN(parseFloat(percent)) && isFinite(percent)) {
        return Math.round(percent * 100);
      } else {
        return percent;
      }
    })
  });
  _exports.default = _default;
});