define("ember-svg-jar/inlined/interface-alert-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><g><g><path d=\"M12 22a.997.997 0 01-.707-.293l-9-9a.999.999 0 010-1.414l9-9a.999.999 0 011.414 0l9 9a.999.999 0 010 1.414l-9 9A.993.993 0 0112 22zM4.414 12L12 19.586 19.586 12 12 4.415 4.414 12z\" fill=\"#757575\"/></g><g><path fill=\"#757575\" d=\"M11 8h2v6h-2z\"/></g><g><circle cx=\"12\" cy=\"16\" r=\"1.25\" fill=\"#757575\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});