define("ember-svg-jar/inlined/cursor-arrow-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g id=\"Frame_-_24px\"><path fill=\"none\" d=\"M0 0h24v24H0z\"/></g><g id=\"Line_Icons\"><path d=\"M19.707 18.293L14.414 13l3.293-3.293a.998.998 0 00-.39-1.655l-12-4a1.002 1.002 0 00-1.266 1.265l4 12A1.005 1.005 0 009 18c.262 0 .518-.104.707-.293L13 14.415l5.293 5.292 1.414-1.414zM9.438 15.149L6.581 6.582l8.567 2.855-5.71 5.712z\" fill=\"#757575\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});