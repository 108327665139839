define("@trovedata/sunstone-ui-commons/components/sort-indicator/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "WBbMvPnl",
    "block": "[[[41,[33,1],[[[6,[39,2],null,[[\"iconButton\"],[true]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,3],[\"navigation-up\"],[[\"class\",\"size\"],[[52,[28,[37,4],[[33,5],\"asc\"],null],\"selected\"],12]]]],[1,\"\\n    \"],[1,[28,[35,3],[\"navigation-down\"],[[\"class\",\"size\"],[[52,[28,[37,4],[[33,5],\"desc\"],null],\"selected\"],12]]]],[1,\"\\n\"]],[]]]]]],[]],null]],[],false,[\"if\",\"sort\",\"trove-button\",\"trove-icon\",\"eq\",\"sortDirection\"]]",
    "moduleName": "@trovedata/sunstone-ui-commons/components/sort-indicator/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});